import { combineReducers } from 'redux'
import { reducerCredentials } from 'src/redux/credentials/reducers'
import { reducerUsers } from 'src/redux/users/reducers'
import { reducerRoles } from './roles/reducers'
import { reducerProducts } from './products/reducers'
import { reducerGenerateQR } from './generate-qr/reducers'
import { reducerDistros } from './distros/reducers'
import { reducerSettingQR } from './setting-qr/reducers'
import { reducerSubAccount } from './sub-account/reducers'
import { reducerProductMaster } from './products-master/reducers'
import { reducerProfiles } from './profiles/reducers'
import { reducerScanQR } from './scan-qr/reducers'
import { reducerTopUpAdmin } from './topup-admin/reducers'
import { reducerTopUp } from './topup/reducers'
import { reducerLogActivity } from './log-activity/reducers'
import { reducerTraffics } from './traffics/reducers'
import { reducerFacebookPixel } from './facebook-pixel/reducers'
import { reducerProductPixel } from './facebook-pixel/product/reducers'
import { reducerCoupons } from './coupons/reducers'
import { reducerCouponClaimers } from './coupon-claimers/reducers'
import { reducerAffiliates } from './transaction-affiliates/reducers'
import { reducerAdminAffiliates } from './affiliate-admin/reducers'
import { reducerGoogleTagManager } from './google-tag-manager/reducers'
import { reducerProductGtm } from './google-tag-manager/product/reducers'
import { reducerPrintRequest } from './print-admin/reducers'

export default (history) =>
  combineReducers({
    reducerProductMaster,
    reducerProfiles,
    reducerCredentials,
    reducerUsers,
    reducerSubAccount,
    reducerDistros,
    reducerRoles,
    reducerProducts,
    reducerGenerateQR,
    reducerSettingQR,
    reducerScanQR,
    reducerTopUpAdmin,
    reducerTopUp,
    reducerLogActivity,
    reducerTraffics,
    reducerFacebookPixel,
    reducerProductPixel,
    reducerCoupons,
    reducerCouponClaimers,
    reducerAffiliates,
    reducerAdminAffiliates,
    reducerGoogleTagManager,
    reducerProductGtm,
    reducerPrintRequest
  })
