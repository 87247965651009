import React from 'react'
import _data from 'src/data'

const About = () => {
  return (
    <>
      <div className='overflow-hidden'>
        <section id='about' className='about-us ptb-100 gray-light-bg'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                {/* <span className='badge badge-primary badge-pill'>OPIM</span> */}
                <h2 className='mt-4'>{_data.aboutUs.title.normal}</h2>
                <p className='mb-4 lh-190'>{_data.aboutUs.description}</p>
                <ul className='list-unstyled'>
                  {(_data?.aboutUs.items || []).map(item => {
                    return (
                      <li className='py-2' key={item.title}>
                        <div className='d-flex align-items-center'>
                          <div>
                            <div className='badge badge-circle badge-primary mr-3'>
                              <span className='ti-check' />
                            </div>
                          </div>
                          <div>
                            <p className='mb-0'>
                              {item.title}
                            </p>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className='col-md-6'>
                <div className='about-content-right text-right'>
                  <img src='img/delivery-app.svg' width='500' alt='about us' className='img-fluid' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default About
