/* eslint-disable no-undef */
import React from 'react'
import ReactDOM from 'react-dom'

import 'src/assets/styles/global.scss'
import 'antd/dist/antd.css'

import reducers from 'src/redux/reducers'
import sagas from 'src/redux/sagas'
import 'src/assets/styles/bootstrap/bootstrap.css'
import { Routers } from 'src/routers'

// import logger from 'redux-logger'
import createSagaMiddleware from '@redux-saga/core'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === 'development' && true) {
  // middlewares.push(logger)
}

/**
 * Add all the state in local storage
 * @param getState
 * @returns {function(*): function(*=)}
 */

// const localStorageMiddleware = ({ getState }) => { // <--- FOCUS HERE
//   return (next) => (action) => {
//     const result = next(action)
//     localStorage.setItem('applicationState', JSON.stringify(
//       getState()
//     ))
//     return result
//   }
// }

// const reHydrateStore = () => {
//   if (localStorage.getItem('applicationState') !== null) {
//     return JSON.parse(localStorage.getItem('applicationState'))
//   }
// }

const store = createStore(
  reducers(),
  // reHydrateStore(),
  compose(
    applyMiddleware(
      ...middlewares
      // localStorageMiddleware
    ))
)
sagaMiddleware.run(sagas)

require('dotenv').config()

ReactDOM.render(
  <Provider store={store}>
    <Routers />
  </Provider>,
  document.getElementById('root')
)

export { store }
