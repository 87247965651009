/* eslint-disable no-unused-vars */
import { notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/print-admin/actions'
import { servicePrint } from 'src/services/api/printRequests'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'print/set_state',
    payload
  })
}

export function * getPrintRequest ({ payload }) {
  yield load(true)
  const { token, reset, params } = payload
  const response = yield call(servicePrint.get, token, params)

  if (response.status === 200) {
    yield setState({
      loading: false,
      dataPrint: response.data.data.items,
      meta: response.data.data.meta
    })
  } else yield load(false)
}

export function * detailPrintRequest ({ payload }) {
  yield load(true)
  const { token, printRequestId } = payload

  const response = yield call(servicePrint.getOne, token, { printRequestId })
  if (response.status === 200) {
    // console.log(response);
    yield setState({
      loading: false,
      detailPrint: response.data.data
    })
  } else yield load(false)
}

export function * deletePrintRequest ({ payload }) {
  yield load(true)
  const { token, printRequestId } = payload

  const response = yield call(servicePrint.delete, token, { printRequestId })

  if (response.status === 202) {
    yield put({
      type: 'print/get_print',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Request Success!',
      description: 'Print Request remove for permanent'
    })
  } else yield load(false)
}

export function * updatePrintRequest ({ payload }) {
  yield load(true)

  const { token, printRequestId, data } = payload
  const response = yield call(servicePrint.update, token, { printRequestId, data })
  if (response.status === 202) {
    yield load(false)
    yield put({
      type: 'print/get_one_print',
      payload: { token, printRequestId }
    })
    notification.success({
      message: 'Success!',
      description: 'Please check your table list for print request'
    })
  } else {
    yield load(false)
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get, getPrintRequest),
    takeEvery(actions.delete, deletePrintRequest),
    takeEvery(actions.update, updatePrintRequest),
    takeEvery(actions.get_one, detailPrintRequest)
  ])
}
