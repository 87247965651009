import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Modal, Row } from 'antd'
import {
  TagOutlined,
  UserOutlined,
  AimOutlined,
  UserSwitchOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { AButton } from 'src/components/atoms/Button'

const _UpdateDistro = ({ token, dispatch, loading, visible, onCLick, onCancel, itemsDistro }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    setData(itemsDistro)
  }, [itemsDistro])

  const onHandleUpdate = () => {
    dispatch({
      type: 'distros/update_distros',
      payload: {
        token,
        distroId: itemsDistro?.id,
        data
      }
    })
  }

  return (
    <Modal
      title='Update Agent'
      visible={visible}
      onCLick={onCLick}
      onCancel={onCancel}
      footer={false}
    >
      <Form layout='vertical'>
        <FormItem
          value={data?.name}
          prefix={<UserOutlined />}
          label='Name'
          placeholder='type your agen name'
          onChange={({ target }) => setData({ ...data, name: target.value })}
        />
        <FormItem
          prefix={<TagOutlined />}
          value={data?.code}
          onChange={({ target }) => setData({ ...data, code: target.value })}
          label='Code Agent'
          placeholder='add your code agent'
        />
        <FormItem
          value={data?.owner}
          onChange={({ target }) => setData({ ...data, owner: target.value })}
          prefix={<UserSwitchOutlined />}
          label='Agent Owner'
          placeholder='add your agent owner'
        />
        <FormItem
          prefix={<AimOutlined />}
          label='Address'
          onChange={({ target }) => setData({ ...data, address: target.value })}
          value={data?.address}
          placeholder='add your addresess'
        />
        <Row justify='end'>
          <AButton
            loading={loading}
            icon={<PlusCircleOutlined />}
            onClick={onHandleUpdate}
            title='Update'
            type='primary'
          />
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerDistros }) => {
  return { ...reducerDistros }
}

export const UpdateDistro = connect(mapStateToProps)(_UpdateDistro)

_UpdateDistro.propTypes = {
  onCLick: PropTypes.func,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  itemsDistro: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool
}
