/* eslint-disable react/react-in-jsx-scope */
import {
  QrcodeOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  SafetyCertificateOutlined,
  ExceptionOutlined,
  ShopOutlined,
  SolutionOutlined,
  AppstoreAddOutlined,
  DollarOutlined,
  BarChartOutlined,
  // FacebookOutlined,
  TagOutlined,
  NotificationOutlined,
  // GoogleOutlined,
  PrinterOutlined,
  HomeOutlined
} from '@ant-design/icons'

export const menus = [
  {
    title: 'Users',
    redirect: '/dashboard',
    icon: <UsergroupAddOutlined />,
    child: [null]
  },
  {
    title: 'Roles',
    redirect: '/dashboard/roles',
    icon: <SolutionOutlined />,
    child: [null]
  },
  {
    title: 'List Products',
    redirect: '/dashboard/product/list',
    icon: <ShopOutlined />,
    child: [null]
  },
  {
    title: 'Confirm Products',
    redirect: '/dashboard/product/confirmation',
    icon: <SafetyCertificateOutlined />,
    child: [null]
  },
  {
    title: 'Confirm TopUp',
    redirect: '/dashboard/topup/confirmation',
    icon: <DollarOutlined />,
    child: [null]
  },
  {
    title: 'Affiliate',
    icon: <NotificationOutlined />,
    redirect: '/dashboard/affiliate',
    child: [null]
  },
  {
    title: 'Print by OPIM',
    icon: <PrinterOutlined />,
    redirect: '/dashboard/print-by-opim',
    child: [null]
  }
]

export const menuMaster = [
  {
    title: 'Dashboard',
    icon: <HomeOutlined />,
    redirect: '/dashboard',
    child: [null]
  },
  {
    title: 'Distro',
    icon: <UsergroupAddOutlined />,
    redirect: '/dashboard/distro',
    child: [null]
  },
  {
    title: 'Sub Accounts',
    icon: <UserSwitchOutlined />,
    redirect: '/dashboard/sub-account',
    child: [null]
  },
  {
    title: 'Products',
    icon: <ShopOutlined />,
    redirect: '/dashboard/product',
    child: [null]
  },
  {
    title: 'Log Activity',
    icon: <ExceptionOutlined />,
    redirect: '/dashboard/product/log',
    child: [null]
  },
  {
    title: 'Settings QR',
    icon: <AppstoreAddOutlined />,
    redirect: '/dashboard/setting-qr',
    child: [null]
  },
  {
    title: 'Generates QR',
    icon: <QrcodeOutlined />,
    redirect: '/dashboard/generate-qr',
    child: [null]
  },
  {
    title: 'Business Analytics',
    icon: <BarChartOutlined />,
    redirect: '/dashboard/analytics',
    child: [null]
  },
  // {
  //   title: 'Facebook Pixel',
  //   icon: <FacebookOutlined />,
  //   redirect: '/dashboard/facebook-pixel',
  //   child: [null]
  // },
  // {
  //   title: 'Google Tag Manager',
  //   icon: <GoogleOutlined />,
  //   redirect: '/dashboard/google-tag-manager',
  //   child: [null]
  // },
  {
    title: 'Top Up',
    icon: <DollarOutlined />,
    redirect: '/dashboard/topup',
    child: [null]
  },
  {
    title: 'Coupon',
    icon: <TagOutlined />,
    redirect: '/dashboard/coupon',
    child: [null]
  }
]

export const menuFreeMaster = [
  {
    title: 'Dashboard',
    icon: <HomeOutlined />,
    redirect: '/dashboard',
    child: [null]
  },
  {
    title: 'Products',
    icon: <ShopOutlined />,
    redirect: '/dashboard/product',
    child: [null]
  },
  {
    title: 'Log Activity',
    icon: <ExceptionOutlined />,
    redirect: '/dashboard/product/log',
    child: [null]
  },
  {
    title: 'Settings QR',
    icon: <AppstoreAddOutlined />,
    redirect: '/dashboard/setting-qr',
    child: [null]
  },
  {
    title: 'Generates QR',
    icon: <QrcodeOutlined />,
    redirect: '/dashboard/generate-qr',
    child: [null]
  },
  {
    title: 'Business Analytics',
    icon: <BarChartOutlined />,
    redirect: '/dashboard/analytics',
    child: [null]
  },
  // {
  //   title: 'Facebook Pixel',
  //   icon: <FacebookOutlined />,
  //   redirect: '/dashboard/facebook-pixel',
  //   child: [null]
  // },
  {
    title: 'Top Up',
    icon: <DollarOutlined />,
    redirect: '/dashboard/topup',
    child: [null]
  },
  {
    title: 'Coupon',
    icon: <TagOutlined />,
    redirect: '/dashboard/coupon',
    child: [null]
  }
]

export const menuSilverMaster = [
  {
    title: 'Dashboard',
    icon: <HomeOutlined />,
    redirect: '/dashboard',
    child: [null]
  },
  {
    title: 'Distro',
    icon: <UsergroupAddOutlined />,
    redirect: '/dashboard/distro',
    child: [null]
  },
  {
    title: 'Products',
    icon: <ShopOutlined />,
    redirect: '/dashboard/product',
    child: [null]
  },
  {
    title: 'Log Activity',
    icon: <ExceptionOutlined />,
    redirect: '/dashboard/product/log',
    child: [null]
  },
  {
    title: 'Settings QR',
    icon: <AppstoreAddOutlined />,
    redirect: '/dashboard/setting-qr',
    child: [null]
  },
  {
    title: 'Generates QR',
    icon: <QrcodeOutlined />,
    redirect: '/dashboard/generate-qr',
    child: [null]
  },
  {
    title: 'Business Analytics',
    icon: <BarChartOutlined />,
    redirect: '/dashboard/analytics',
    child: [null]
  },
  // {
  //   title: 'Facebook Pixel',
  //   icon: <FacebookOutlined />,
  //   redirect: '/dashboard/facebook-pixel',
  //   child: [null]
  // },
  {
    title: 'Top Up',
    icon: <DollarOutlined />,
    redirect: '/dashboard/topup',
    child: [null]
  },
  {
    title: 'Coupon',
    icon: <TagOutlined />,
    redirect: '/dashboard/coupon',
    child: [null]
  }
]

export const menuSubAccount = (subaccount) => {
  const menus = [
    {
      title: 'Dashboard',
      icon: <HomeOutlined />,
      redirect: '/dashboard'
    },
    {
      title: 'Product',
      icon: <UsergroupAddOutlined />,
      redirect: '/dashboard/product'
    },
    {
      title: 'Setting QR',
      icon: <UserSwitchOutlined />,
      redirect: '/dashboard/setting-qr'
    },
    {
      title: 'Generate QR',
      icon: <ShopOutlined />,
      redirect: '/dashboard/generate-qr'
    }
  ]
  const conditionAccessAll = menus
  const conditionAccessProd = [menus[0], menus[1]]
  const conditionAccessSett = [menus[0], menus[2]]
  const conditionAccessGenerate = [menus[0], menus[3]]
  const conditionAccessProdSett = [menus[0], menus[2]]
  const conditionAccessProdGen = [menus[0], menus[1], menus[3]]
  const conditionAccessGenSett = [menus[0], menus[2], menus[3]]

  const { accessGenerateQr, accessSettingGenerateQr, accessProduct } =
    subaccount
  // access for all menu
  if (accessProduct && accessGenerateQr && accessSettingGenerateQr) {
    return conditionAccessAll
  }
  // nothing access
  if (!accessProduct && !accessGenerateQr && !accessSettingGenerateQr) {
    return null
  }
  // access for product & generate-qr
  if (accessProduct && accessGenerateQr && !accessSettingGenerateQr) {
    return conditionAccessProdGen
  }
  // access for product & setting-qr
  if (accessProduct && !accessGenerateQr && accessSettingGenerateQr) {
    return conditionAccessProdSett
  }
  // access for generate & setting-qr
  if (!accessProduct && accessGenerateQr && accessSettingGenerateQr) {
    return conditionAccessGenSett
  }
  // access for only product
  if (accessProduct && !accessGenerateQr && !accessSettingGenerateQr) {
    return conditionAccessProd
  }
  // access for only access setting
  if (!accessProduct && !accessGenerateQr && accessSettingGenerateQr) {
    return conditionAccessSett
  }
  // access for only access generate
  if (!accessProduct && accessGenerateQr && !accessSettingGenerateQr) {
    return conditionAccessGenerate
  }
}
