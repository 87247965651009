import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceProductMaster = {
  get: (token, params) => helper.get('api/v1/products', token, params),
  create: (token, { params }) => helper.post('api/v1/products', params, token),
  upload: (token, { data, productId }) => helper.post('api/v1/products/' + productId + '/files', data, token),
  log: (token, params) => helper.get('api/v1/log-activities', token),
  delete: (token, { productId }) =>
    axios.delete(apiBaseUrl + `api/v1/products/${productId}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response),
  patch: (token, productId, data) => helper.patch('api/v1/products/' + productId, data, token),
  one: (token, productId) => helper.get('api/v1/products/' + productId, token),
  uploadImage: (token, productId, { formData }) => helper.post('api/v1/products/' + productId + '/files', formData, token),
  removeImage: (token, productId, { id }) => helper.delete('api/v1/products/' + productId + '/files/' + id, token)
}
