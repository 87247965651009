import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import Layout, { Content } from 'antd/lib/layout/layout'
import { Button, Card, Col, List, Row, Space, Table, Tabs, Tag, Tooltip, Image, Typography } from 'antd'
import { WhatsAppOutlined } from '@ant-design/icons'
import moment from 'moment'
import LogoBCA from 'src/assets/images/logo-bca.png'

export const _TopUp = ({ token, dispatch, data, loading, history, success }) => {
  const { Text } = Typography

  useEffect(() => {
    dispatch({
      type: 'topup/get_transaction',
      payload: {
        token
      }
    })
  }, [success])

  useEffect(() => {
    dispatch({
      type: 'topup/get_prices',
      payload: {
        token
      }
    })
  }, [])

  const { TabPane } = Tabs

  const column = [
    {
      title: 'Credit',
      dataIndex: 'credit'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      responsive: ['md'],
      render: (text) => 'Rp ' + new Intl.NumberFormat().format(text)
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('DD/MM/YYYY hh:mm:ss')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return text.toLowerCase() === 'accepted'
          ? <Tag color='green'>{text}</Tag>
          : text.toLowerCase() === 'pending'
            ? <Tag color='orange'>{text}</Tag>
            : <Tag color='red'>{text}</Tag>
      },
      align: 'center'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 300,
      align: 'center',
      render: (txt, obj) => {
        return obj.status.toLowerCase() === 'pending' && (
          <Space>
            <Tooltip placement='topLeft' title='Confirm Payment'>
              {/* <Button size='large' htmlType='submit' className='button-detail' onClick={() => onHandleBuy(obj)}>
                <WhatsAppOutlined />
              </Button> */}
              <Button size='large' htmlType='submit' className='button-detail'>
                <a
                  target='_blank'
                  href={`https://api.whatsapp.com/send?phone=6287705115217&text=*Konfirmasi%20Transfer*%0a%0aNama%20:%20${obj?.fullName}%0aUsername%20:%20${obj?.username}%0aEmail%20:%20${obj?.email}%0aNominal%20:%20Rp${Intl.NumberFormat('id').format(obj?.price)}%0aTanggal%20:%20${moment(obj?.createdAt).format('DD-MM-YYYY, hh:mm')}%20WIB%0aAtas%20Nama%20:%0aBukti%20Transfer%20:%20`}
                  rel='noreferrer'
                >
                  <WhatsAppOutlined />
                </a>
              </Button>
            </Tooltip>
          </Space>
        )
      }
    }
  ]

  const fitur = (name) => {
    return name === 'silver'
      ? ['Agent System', 'Free Watermark', 'Setting QR', 'Product / Log', 'Generate QR']
      : name === 'gold'
        ? ['Agent System', '2 Sub Account', 'Free Watermark', 'Setting QR', 'Product / Log', 'Generate QR']
        : ['Agent System', '4 Sub Account', 'Free Watermark', 'Setting QR', 'Product / Log', 'Generate QR']
  }

  const onHandleBuy = (item) => {
    dispatch({
      type: 'topup/topup',
      payload: {
        token,
        role: item + '_master'
      }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'topup/get_transaction',
      payload: { token, params: { page: page } }
    })
  }
  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-roles'>
        <Content className='container'>
          <Row gutter={[16, 16]}>
            <Col lg={24} md={24} sm={24}>
              <Card>
                <Tabs type='card'>
                  {
                    data
                      ? (
                          data?.map((item, id) => {
                            const name = item.name.split('_')
                            const naming = name[0] === 'silver' ? 'Basic' : name[0] === 'gold' ? 'Standart' : 'Corporate'
                            const data = item.name !== 'free_master' && (
                              <TabPane tab={naming} key={name[0]}>
                                <List size='small'>
                                  <List.Item style={{ color: 'gray', fontSize: 12 }}>{item.credit} Credit</List.Item>
                                  <List.Item style={{ color: 'gray', fontSize: 12 }}>RP {new Intl.NumberFormat().format(item.price)}</List.Item>
                                  {
                                    fitur(name[0]).map((val, index) => {
                                      return <List.Item style={{ color: 'gray', fontSize: 12 }} key={index}>{val}</List.Item>
                                    })
                                  }
                                  <List.Item style={{ color: 'gray' }}>
                                    <Button loading={loading} type='primary' onClick={() => onHandleBuy(name[0])} htmlType='submit'>Buy</Button>
                                  </List.Item>
                                </List>
                              </TabPane>
                            )
                            return data
                          })
                        )
                      : null
                  }
                </Tabs>
              </Card>
            </Col>
            <Col lg={24} md={24} sm={24}>
              <Card>
                <Row justify='start'>
                  <Col lg={10} md={10} sm={10}>
                    <b>Silahkan transfer ke</b>
                    <Card>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Image src={LogoBCA} preview={false} width={100} />
                        </Col>
                        <Col span={12} className='text-right'>
                          <Text
                            className='amount'
                            copyable={{ tooltips: false }}
                            style={{
                              fontSize: '18px',
                              display: 'flex',
                              justifyContent: 'end',
                              fontWeight: '800'
                            }}
                          >
                            8610729119
                          </Text>
                          <br />
                          IKO ARYA WISNU PEMBAKA
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={24} md={24} sm={24}>
              <Table
                title={() => 'History Top Up'}
                loading={loading}
                columns={column}
                dataSource={history?.items}
                pagination={history?.meta && { defaultPageSize: history?.meta.itemsPerPage, total: history?.meta.totalItems, pageSize: history?.meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
                rowKey='id'
                rowSelection={{
                  type: 'checkbox'
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerTopUp }) => {
  return { ...reducerTopUp }
}

export const TopUp = connect(mapStateToProps)(_TopUp)

_TopUp.propTypes = {
  token: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  loading: PropTypes.bool,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  success: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
