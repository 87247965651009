export const rules = {
  email: () => {
    return { type: 'email' }
  },
  required: () => {
    return { required: true, message: 'field is required!' }
  },
  date: () => {
    return { type: new Date() }
  },
  any: () => {
    return { type: 'any' }
  },
  min: (num) => {
    return { min: num, message: 'more than 3 character' }
  },
  max: (num) => {
    return { max: num, message: 'less than 100 character' }
  }
}
