import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Row,
  Col
  // message
} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents
} from 'react-leaflet'

export const _Maps = ({ loading, visible, onCancel, fullAddress, maps, setMap }) => {
  const markerRef = React.useRef(null)
  const center = {
    lat: maps.latitude ?? -7.932869927992499,
    lng: maps?.longititude ?? 110.43418769657211
  }

  const onSubmit = () => {
    const marker = markerRef.current
    if (marker !== null) {
      // console.log(marker.getLatLng())
      const coor = marker.getLatLng()
      setMap({ visible: false, latitude: coor.lat, longititude: coor.lng })
    }
    // setMap({ visible: false, latitude: data.lat, longititude: data.lng })
  }

  const onClose = () => {
    onCancel()
  }

  function LocationMarker () {
    const [position, setPosition] = React.useState(center)

    const map = useMapEvents({
      click () {
        // if (!draggable) {
        //   map.locate()
        // }
        map.locate()
      },
      locationfound (e) {
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      }
    })
    const eventHandlers = React.useMemo(
      () => ({
        dragend () {
          const marker = markerRef.current
          if (marker !== null) {
            // console.log(marker.getLatLng())
            setPosition(marker.getLatLng())
          }
        }
      }),
      []
    )

    return (
      <Marker
        draggable
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup className='popup-marker'>
          You are here
        </Popup>
      </Marker>
    )
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      onCancel={onClose}
      width={800}
      closable={false}
      centered
    >
      <div className='d-flex mb-2' style={{ alignItems: 'center' }}>
        <ArrowLeftOutlined style={{ fontSize: 16, cursor: 'pointer' }} onClick={onClose} />
        <div className='ml-3'>
          <h2 className='address-title'>Chose Location Point</h2>
          <p style={{ fontSize: 12 }}>{fullAddress}</p>
        </div>
      </div>

      <MapContainer center={[maps?.latitude ?? center.lat, maps?.longititude ?? center.lng]} zoom={13}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <LocationMarker />
      </MapContainer>

      <Row justify='center' className='pt-2 mb-2' style={{ paddingRight: 18, paddingLeft: 18 }} gutter={16}>
        <Col span={12}>
          <Button
            type='primary'
            ghost
            loading={loading}
            size='large'
            block
            onClick={onClose}
          >
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type='primary'
            loading={loading}
            onClick={onSubmit}
            size='large'
            block
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const mapStateToProps = ({ reducerProfiles }) => {
  return { ...reducerProfiles }
}

export const Maps = connect(mapStateToProps)(_Maps)

_Maps.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  fullAddress: PropTypes.string,
  maps: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  setMap: PropTypes.func
}
