/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useRef, forwardRef, useCallback } from 'react'
import { Form, Card, Col, Image, Skeleton, Descriptions, Layout, Row, Button, Tag, Select } from 'antd'
import PropTypes from 'prop-types'
import { PrinterOutlined, StopOutlined, CheckOutlined, ClockCircleOutlined, FileDoneOutlined, EditOutlined, RightOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { DefaultLayout } from 'src/layouts/default'
import { connect } from 'react-redux'
import { Gap } from 'src/components/atoms/Gap'

import printJS from 'print-js'
import { useReactToPrint } from 'react-to-print'
// import PrintGenerate from './PrintGenerate'
import LayoutA4QR10x10 from 'src/pages/generates-qr/layout-print/a4/QR10x10'
import LayoutA4QR20x20 from 'src/pages/generates-qr/layout-print/a4/QR20x20'
import LayoutA4QR10x10Text from 'src/pages/generates-qr/layout-print/a4/QR10x10-text'
import LayoutSample from 'src/pages/generates-qr/layout-print/a4/sample'

import LayoutA41of1QR20x20 from 'src/pages/generates-qr/layout-print/a4/1of1-20x20'
import LayoutA41of1QR30x30 from 'src/pages/generates-qr/layout-print/a4/1of1-30x30'
import LayoutA41of1QR40x40 from 'src/pages/generates-qr/layout-print/a4/1of1-40x40'

import LayoutA41of1QR20x20Text from 'src/pages/generates-qr/layout-print/a4/1of1-20x20-text'
import LayoutA41of1QR40x40Text from 'src/pages/generates-qr/layout-print/a4/1of1-40x40-text'

import Layout40x20WhitoutText from 'src/pages/generates-qr/layout-print/a3/QRContainer40x20WhitoutText'

import LayoutA3QRCont40x20 from 'src/pages/generates-qr/layout-print/a3/QRContainer40x20'
import LayoutA3QR20x20 from 'src/pages/generates-qr/layout-print/a3/QR20x20'
import LayoutA3QR10x10 from 'src/pages/generates-qr/layout-print/a3/QR10x10'
import LayoutA310x10Text from 'src/pages/generates-qr/layout-print/a3/QR10x10-text'

import LayoutA0QR10x10Text from 'src/pages/generates-qr/layout-print/a0/QRContainer10x10Text'

// PERCETAKAN
import LayoutPercetakanA410x0 from 'src/pages/generates-qr/layout-print/percetakan/a4/QR10x10'

// import styles from './styles/a3-layout-40x20.module.css'
import { FormItem } from 'src/components/molecules/FormItem'
import { PreviewMap } from './PreviewMap'
import './style/index.css'

export const _GeneratePrintCetakan = forwardRef(({ detailGenerate }, ref) => {
  const cssPageMedia = (size = '210mm 297mm') => {
    let style
    const [pageWitdh, pageHeight] = size.split(' ')

    if (!document.getElementById('page-size')) {
      style = document.createElement('style')
      // style.setAttribute('id', 'page-size')
      document.head.append(style)
    } else {
      style = document.getElementById('page-size')
    }
    style.innerHTML = `\n
      @page {
        size: ${size};
      }\n
      @media print {\n
        html, body {\n
          width: ${pageWitdh};
          height: ${pageHeight};
        }\n
        @page {\n
          margin: 5.5mm 7.5mm;
        }\n
        
      }\n
      `
  }

  useEffect(() => {
    detailGenerate && getDetailGenerate(detailGenerate)
  }, [detailGenerate])

  const getDetailGenerate = (val) => {
    setLayoutPrint(val.generateQrcode?.size)
  }

  const setLayoutPrint = (size) => {
    const settingSize = size

    switch (settingSize) {
      case 'SMALL':
        cssPageMedia()
        break

      case 'A4_10x10_TEXT':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_30x30':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        cssPageMedia()
        break

      case 'A3_10x10':
        cssPageMedia('297mm 420mm')
        break

      case 'A3_10x10_TEXT':
        cssPageMedia('297mm 420mm')
        break

      case 'MEDIUM':
        cssPageMedia('297mm 420mm')
        break

      case 'LARGE':
        cssPageMedia('297mm 420mm')
        break

      case 'A0_10x10_TEXT':
        cssPageMedia('9933px 14043px')
        break

      default:
        cssPageMedia()
        break
    }
  }

  const PagePrintCetakan = () => {
    const settingSize = detailGenerate?.size
    let printPage = ''

    switch (settingSize) {
      case 'SMALL':
        printPage = <LayoutA4QR10x10 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A4_10x10_TEXT':
        printPage = <LayoutPercetakanA410x0 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20':
        printPage = <LayoutA41of1QR20x20 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_30x30':
        printPage = <LayoutA41of1QR30x30 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40':
        printPage = <LayoutA41of1QR40x40 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        printPage = <LayoutA41of1QR20x20Text ref={ref} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        printPage = <LayoutA41of1QR40x40Text ref={ref} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break

      case 'A3_10x10_TEXT':
        if (detailGenerate?.textInQrcode === '') {
          printPage = <LayoutA3QR10x10 ref={ref} data={detailGenerate?.qrcodes} />
        } else {
          printPage = <LayoutA310x10Text ref={ref} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        }
        break

      case 'MEDIUM':
        if (detailGenerate?.textInQrcode === '') {
          printPage = <Layout40x20WhitoutText ref={ref} data={detailGenerate?.qrcodes} />
        } else {
          printPage = <LayoutA3QRCont40x20 ref={ref} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        }
        break

      case 'a4_1of1_page_qr_20x20':
        printPage = <LayoutA41of1QR20x20 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A3_10x10':
        printPage = <LayoutA3QR10x10 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'LARGE':
        printPage = <LayoutA3QR20x20 ref={ref} data={detailGenerate?.qrcodes} />
        break

      case 'A0_10x10_TEXT':
        printPage = <LayoutA0QR10x10Text ref={ref} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break

      default:
        printPage = <LayoutSample ref={ref} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break
    }
    return printPage
  }

  // useEffect(() => {
  //   if (ref) {
  //     console.log('WORK')
  //     useReactToPrint({
  //       content: reactToPrintContent,
  //       suppressErrors: true,
  //       onBeforeGetContent: handlePrintingonBeforeGetContent,
  //       onBeforePrint: handleBeforePrint
  //       // pageStyle: printPageStyle
  //     })
  //   }
  // }, [ref])

  return (
    <PagePrintCetakan />
  )
})

export const GeneratePrintCetakan = _GeneratePrintCetakan

_GeneratePrintCetakan.propTypes = {
  detailGenerate: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])

}
