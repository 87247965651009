/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  // Form,
  // DatePicker,
  Layout,
  Row,
  Col,
  Typography,
  Tooltip,
  Empty,
  Button,
  Dropdown,
  Menu
} from 'antd'
import {
  QuestionCircleOutlined,
  CheckCircleFilled,
  MinusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownloadOutlined
} from '@ant-design/icons'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { ChartLine } from 'src/components/atoms/ChartLine'
import { Header } from 'src/components/molecules/Header'
import { Content } from 'antd/lib/layout/layout'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import styles from 'src/pages/traffics/styles/traffic.module.css'
import './styles/style.css'

export const _Traffic = ({ dataTraffics, dataDownload, token, dispatch }) => {
  const downloadCsvRef = useRef()
  const [dateTo, setDateTo] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'))
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'))
  const [datasets, setDatasets] = useState([])
  const [metrik, setMetrik] = useState([])
  const [filename, setFilename] = useState('')
  const [filter, setFilter] = useState('Kemarin')
  const [fiterLabel, setFiterLabel] = useState({
    pendapatan: false,
    pengunjung: false,
    konversi: false
  })
  const tooltipMatrik = [
    'Total nilai pembayaran pembeli selama periode yang dipilih, adalah jumlah pembayaran aktual akhir setelah menghitung semua diskon yang diterapkan, kredit toko, biaya pengiriman, dan biaya tambahan.',
    'Jumlah pelanggan yang melihat halaman toko Anda, termasuk "Halaman Muka", Semua Halaman Produk, dan halaman lain di toko Anda, pada rentang tanggal yang dipilih.',
    'Jumlah pembeli dibagi jumlah pengunjung dalam periode tertentu'
  ]

  useEffect(() => {
    dispatch({
      type: 'traffics/get_traffics',
      payload: {
        token,
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo
        }
      }
    })
  }, [dateFrom, dateTo])

  useEffect(() => {
    const arr = []
    if (filter === 'Kemarin') {
      arr.push('vs Sehari Sebelumnya')
      arr.push('Dari Minggu ke Minggu')
    } else if (filter === '7 Hari Terakhir') {
      arr.push('vs 7 Hari Sebelumnya')
    } else if (filter === '30 Hari Terakhir') {
      arr.push('vs 30 Hari Sebelumnya')
    } else if (filter === 'Pilihan') {
      if (dateTo === dateFrom) {
        arr.push('vs Sehari Sebelumnya')
        arr.push('Dari Minggu ke Minggu')
      } else {
        arr.push('vs Periode Sebelumnya')
      }
    } else if (filter === 'Hari') {
      arr.push('vs Sehari Sebelumnya')
      arr.push('Dari Minggu ke Minggu')
    } else if (filter === 'Minggu') {
      arr.push('vs Minggu Sebelumnya')
      arr.push('Dari Tahun ke Tahun')
    } else {
      const current = moment().format('YYYY-MM')
      const currentFilter = moment(dateFrom).format('YYYY-MM')
      if (current !== currentFilter) {
        arr.push('vs Bulan Sebelumnya')
        arr.push('Dari Tahun ke Tahun')
      } else {
        arr.push('vs Bulan lalu di periode yang sama')
        arr.push('Dari Tahun lalu di periode yang sama')
      }
    }
    setMetrik(arr)
  }, [filter, dateFrom, dateTo, dataTraffics])

  useEffect(() => {
    if (dataDownload) {
      downloadCsvRef.current?.link?.click()
    }
  }, [dataDownload])

  const onSelectDateF = (val) => {
    setDateFrom(val)
  }

  const onSelectDateT = (val) => {
    setDateTo(val)
  }

  const onFilterType = (type) => {
    setFilter(type)
  }

  const selectFilter = (type) => {
    const datasetArr = [...datasets]
    const reversed = dataTraffics?.charts.sort((a, b) => b.no - a.no)

    if (type === 'pendapatan') {
      const data = {
        label: 'Pendapatan',
        data: reversed.map(v => v.totalIncomes),
        backgroundColor: '#0A94F4',
        borderColor: '#0A94F4',
        lineTension: 0.4,
        yAxisID: 'y'
      }
      const index = datasetArr.findIndex(val => val.label === 'Pendapatan')
      if (index !== -1) {
        datasetArr.splice(index, 1)
      } else {
        datasetArr.push(data)
      }
      setFiterLabel((prevState) => ({
        ...prevState,
        pendapatan: !fiterLabel?.pendapatan
      }))
    } else if (type === 'pengunjung') {
      const data = {
        label: 'Pengunjung',
        data: reversed.map(v => v.totalVisitors),
        backgroundColor: '#2DCEAE',
        borderColor: '#2DCEAE',
        lineTension: 0.4,
        yAxisID: 'y1'
      }
      const index = datasetArr.findIndex(val => val.label === 'Pengunjung')
      if (index !== -1) {
        datasetArr.splice(index, 1)
      } else {
        datasetArr.push(data)
      }
      setFiterLabel((prevState) => ({
        ...prevState,
        pengunjung: !fiterLabel?.pengunjung
      }))
    } else {
      const data = {
        label: 'Konversi',
        data: reversed.map(v => v.totalSales && v.totalVisitor !== 0 ? (v.totalSales / v.totalVisitors * 100) : 0),
        backgroundColor: '#C9DD07',
        borderColor: '#C9DD07',
        lineTension: 0.4,
        yAxisID: 'y2'
      }
      const index = datasetArr.findIndex(val => val.label === 'Konversi')
      if (index !== -1) {
        datasetArr.splice(index, 1)
      } else {
        datasetArr.push(data)
      }
      setFiterLabel((prevState) => ({
        ...prevState,
        konversi: !fiterLabel?.konversi
      }))
    }

    setDatasets(datasetArr)
  }

  const conversion = (value, compare) => {
    return value > compare ? styles.up : styles.down
  }

  const conversionIcon = (value, compare) => {
    return value > compare ? <ArrowUpOutlined className='ml-1' /> : <ArrowDownOutlined className='ml-1' />
  }

  const downloadCsv = (type, name) => {
    const filename = `Report_${name}_${moment().format('DD-MM-YYYY')}.csv`
    dispatch({
      type: 'traffics/download_traffics',
      payload: {
        token,
        params: {
          dateFrom,
          dateTo,
          type
        }
      }
    })
    setFilename(filename)
  }

  const downloadMenu = (
    <Menu>
      <Menu.Item onClick={() => downloadCsv('analytics', 'Business_Analytic')}>
        <Row>
          <Col span={24} className='py-2 px-2 download-item'>
            Business Analytic
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item onClick={() => downloadCsv('visitors', 'Location_Visitors')}>
        <Row>
          <Col span={24} className='py-2 px-2 download-item'>
            Location Visitors
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  )

  return (
    <DefaultLayout>
      {dataDownload &&
        <CSVLink
          data={dataDownload}
          filename={filename}
          ref={downloadCsvRef}
          target='_blank'
          style={{ display: 'none' }}
        />}

      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Header
            simple
            search={false}
            traffic
            onFilterType={onFilterType}
            onSelectDateF={onSelectDateF}
            onSelectDateT={onSelectDateT}
            dateTo={dateTo}
            dateFrom={dateFrom}
            trafficFilter={filter}
          />
          <Gap height='1rem' />
          <Row
            gutter={12} style={{
              padding: '16px 24px',
              marginLeft: 0,
              marginRight: 0,
              backgroundColor: '#fff',
              borderRadius: '7px'
            }}
          >
            <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Title level={5}>
                Kunci Metrik
              </Typography.Title>
              <Dropdown overlay={downloadMenu}>
                <Button type='text' style={{ display: 'flex', alignItems: 'center', color: '#1890FF', fontSize: 14 }}>
                  <DownloadOutlined style={{ fontWeight: 'bold', fontSize: 16 }} />
                  <Gap width='.5rem' />
                  Export Data
                </Button>
              </Dropdown>
            </Col>
            <Col span={8}>
              <div
                className={styles.matrik} onClick={() => selectFilter('pendapatan')}
              >
                <div className={styles.matrikWrapp}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>Pendapatan
                    <Tooltip placement='top' title={tooltipMatrik[0]} color='#fff'>
                      <QuestionCircleOutlined className={styles.tooltipIcon} />
                    </Tooltip>
                  </div>
                  {fiterLabel.pendapatan && <CheckCircleFilled className={styles.checkedIcon} />}
                </div>
                <Typography.Title level={3}>
                  {`Rp${Intl.NumberFormat('id').format(dataTraffics?.current?.totalIncomes || 0)}`}
                </Typography.Title>
                {metrik.map((val, index) => (
                  <div key={index} className={styles.subtitle}>
                    {val}
                    <span className={
                      dataTraffics?.current?.totalIncomes === dataTraffics?.before?.totalIncomes
                        ? styles.percent
                        : conversion(dataTraffics?.before?.totalIncomes, dataTraffics?.current?.totalIncomes)}
                    >{`${dataTraffics?.before?.totalIncomes.toFixed(2)}%`}
                      {
                      dataTraffics?.current?.totalIncomes === dataTraffics?.before?.totalIncomes
                        ? <MinusOutlined />
                        : conversionIcon(dataTraffics?.before?.totalIncomes, dataTraffics?.current?.totalIncomes)}
                    </span>
                  </div>
                ))}
                {/* <div className={styles.subtitle}>
                  Dari Minggu ke Minggu
                  <span className={styles.percent} style={{ color: '#389e0d' }}>0.00% <ArrowUpOutlined /></span>
                </div> */}

              </div>
            </Col>
            <Col span={8}>
              <div
                className={styles.matrik} onClick={() => selectFilter('pengunjung')}
              >
                <div className={styles.matrikWrapp}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>Pengunjung
                    <Tooltip placement='top' title={tooltipMatrik[0]} color='#fff'>
                      <QuestionCircleOutlined className={styles.tooltipIcon} />
                    </Tooltip>
                  </div>
                  {fiterLabel.pengunjung && <CheckCircleFilled className={styles.checkedIcon} />}
                </div>
                <Typography.Title level={3}>
                  {dataTraffics?.current?.totalVisitors || 0}
                </Typography.Title>
                {metrik.map((val, index) => (
                  <div key={index} className={styles.subtitle}>
                    {val}
                    <span className={
                      dataTraffics?.current?.totalVisitors === dataTraffics?.before?.totalVisitors
                        ? styles.percent
                        : conversion(dataTraffics?.before?.totalVisitors, dataTraffics?.current?.totalVisitors)}
                    >{`${dataTraffics?.before?.totalVisitors.toFixed(2)}%`}
                      {
                      dataTraffics?.current?.totalVisitors === dataTraffics?.before?.totalVisitors
                        ? <MinusOutlined />
                        : conversionIcon(dataTraffics?.before?.totalVisitors, dataTraffics?.current?.totalVisitors)}
                    </span>
                  </div>
                ))}
                {/* <div className={styles.subtitle}>
                  vs Sehari Sebelumnya
                  <span className={styles.percent} style={{ color: '#f5222d' }}>0.00% <ArrowDownOutlined /></span>
                </div> */}
              </div>
            </Col>
            <Col span={8}>
              <div
                className={styles.matrik} onClick={() => selectFilter('konversi')}
              >
                <div className={styles.matrikWrapp}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>Tingkat Konversi
                    <Tooltip placement='top' title={tooltipMatrik[0]} color='#fff'>
                      <QuestionCircleOutlined className={styles.tooltipIcon} />
                    </Tooltip>
                  </div>
                  {fiterLabel.konversi && <CheckCircleFilled className={styles.checkedIcon} />}
                </div>
                <Typography.Title level={3}>
                  {`${dataTraffics?.current?.conversion.toFixed(2) || 0.00}%`}
                </Typography.Title>
                {metrik.map((val, index) => (
                  <div key={index} className={styles.subtitle}>
                    {val}
                    <span className={
                      dataTraffics?.current?.conversion === dataTraffics?.before?.conversion
                        ? styles.percent
                        : conversion(dataTraffics?.before?.conversion, dataTraffics?.current?.conversion)}
                    >
                      {`${dataTraffics?.before?.conversion.toFixed(2)}%`}
                      {
                      dataTraffics?.current?.conversion === dataTraffics?.before?.conversion
                        ? <MinusOutlined />
                        : conversionIcon(dataTraffics?.before?.conversion, dataTraffics?.current?.conversion)}
                    </span>
                  </div>
                ))}
              </div>
            </Col>
            <Col span={24}>
              <Gap height='2rem' />
              {fiterLabel?.pendapatan ||
              fiterLabel?.pengunjung ||
              fiterLabel?.konversi
                ? <ChartLine datasets={datasets} dateFrom={dateFrom} dateTo={dateTo} charts={dataTraffics?.charts} />
                : null}

              {!fiterLabel?.pendapatan &&
              !fiterLabel?.pengunjung &&
              !fiterLabel?.konversi &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Col>
            <Col span={24}>
              <Gap height='2rem' />
            </Col>
          </Row>
          <Gap height='2rem' />
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerTraffics }) => {
  return { ...reducerTraffics }
}

export const Traffic = connect(mapStateToProps)(_Traffic)

_Traffic.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  dataTraffics: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataDownload: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
