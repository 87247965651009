/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Image, Card, Descriptions, Layout, Row, Col, Table, Input } from 'antd'
import { useLocation } from 'react-router-dom'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { AButton } from 'src/components/atoms/Button'
import { connect } from 'react-redux'
import moment from 'moment'

export const _PreviewCoupon = ({
  loading,
  meta,
  data,
  token,
  dispatch
}) => {
  const { state } = useLocation()
  // const [value, setValue] = useState('')
  // console.log('data', data)

  useEffect(() => {
    dispatch({
      type: 'coupon_claimers/get_coupon_claimers',
      payload: {
        token,
        params: { couponId: state?.id }
      }
    })
  }, [])

  const onHandleSearch = (e) => {
    const { target } = e
    dispatch({
      type: 'coupon_claimers/get_coupon_claimers',
      payload: { token, params: { name: target.value, couponId: state?.id } }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'coupon_claimers/get_coupon_claimers',
      payload: { token, params: { page: page, couponId: state?.id } }
    })
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      align: 'center',
      width: 20,
      render: (text, obj, index) => <a>{index + 1}</a>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      align: 'center'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center'
    },
    {
      title: 'Entered At',
      dataIndex: 'createdAt',
      align: 'center',
      render: (date) => (
        <>{moment(date).format('DD-MM-YYYY, HH:mm')} WIB</>
      )
    },
    {
      title: 'Used',
      dataIndex: 'isUsed',
      align: 'center',
      render: (txt) => (
        <>{txt ? 'Dipakai' : 'Belum Dipakai'}</>
      )
    }
  ]

  return (
    <DefaultLayout>
      <Layout.Content className='container-detail-product'>
        <Card>
          <Row>
            <Col span={16}>
              <Descriptions title='Detail Coupon :'>
                <Descriptions.Item label='Coupon'>{state.coupon}</Descriptions.Item>
                <Descriptions.Item label='Periode'>{moment(state.startDate).format('L')} - {moment(state.endDate).format('L')}</Descriptions.Item>
                <Descriptions.Item />
                <Descriptions.Item label='Discount (%)'>{state.discountCalculated.percentage}%</Descriptions.Item>
                <Descriptions.Item label='Discount (Rp)'>Rp {Intl.NumberFormat('id').format(state.discountCalculated.priceDiscounted)}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={8}>
              <Input
                allowClear
                onChange={onHandleSearch}
              //   onChange={onChange}
                placeholder='Search by name'
              />
            </Col>
          </Row>
          <Gap height='1rem' />
          <Table
            rowKey='id'
            columns={columns}
            dataSource={data ?? []}
            rowSelection={{
              type: 'checkbox'
            }}
            pagination={
              meta && {
                defaultPageSize: meta.itemsPerPage,
                total: meta.totalItems,
                pageSize: meta.itemsPerPage,
                onChange: (page, pageSize) => onHandlePaginate(page, pageSize)
              }
            }
          />
          {/* <Descriptions title='Detail Campaign'>
            wkwk
          </Descriptions> */}
          {/* <Row>
            <Col md={12}>
              <p>Certificates :</p>
              {
              state.certificates.map((item, index) =>
                <Image
                  key={index}
                  src={item.url}
                  style={{ marginRight: '1rem', maxHeight: 150 }}
                />
              )
            }
            </Col>
            <Col md={12}>
              <p>Images :</p>
              {
              state.images.map((item, index) =>
                <Image
                  key={index}
                  src={item.url}
                  style={{ marginRight: '1rem', maxHeight: 150 }}
                />
              )
            }
            </Col>
          </Row>
          <Col>
            <p>Licenses :</p>
            {
            state.licenses.map((item, index) =>
              <p key={index} style={{ marginLeft: '2rem' }}>
                {item.type.toUpperCase()}: {item.no}
              </p>
            )
          }
          </Col>
          <Col>
            <p>Short Description :</p>
            <p style={{ marginLeft: '2rem' }}>{state.shortDescription}</p>
          </Col>
          <Col>
            <p>Short Description :</p>
            <p style={{ marginLeft: '2rem' }}>{state.description}</p>
          </Col> */}
        </Card>
      </Layout.Content>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerCouponClaimers }) => {
  return { ...reducerCouponClaimers }
}

export const PreviewCoupon = connect(mapStateToProps)(_PreviewCoupon)

_PreviewCoupon.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
