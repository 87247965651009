import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Layout, Table, Select, DatePicker, Card } from 'antd'
// import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { useLocation } from 'react-router-dom'
import { Content } from 'antd/lib/layout/layout'
import { connect } from 'react-redux'
import moment from 'moment'
import './style.css'

export const _PreviewAffiliate = ({ dataInfo, dataAffiliates, meta, loading, token, dispatch }) => {
  const { state } = useLocation()
  const { Text } = Typography

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: (text) => <p>{text ?? '-'}</p>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => <p>{email ?? '-'}</p>
    },
    {
      title: 'Aksi',
      dataIndex: 'action',
      render: (action) =>
        <p>
          {action === 'NEW_REFERRAL_REGISTERED' && 'Mendaftar'}
          {action === 'REFERRAL_TOPUP_BONUS' && 'Top Up'}
          {action === 'WITHDRAW' && 'Penarikan'}
        </p>
    },
    {
      title: 'Tanggal, Jam',
      dataIndex: 'createdAt',
      render: (date) =>
        <p>
          {moment(date).format('DD-MM-YYYY, HH:mm')} WIB
        </p>
    },
    {
      title: 'Catatan',
      dataIndex: 'description',
      render: (description) => <p>{description ?? '-'}</p>
    },
    {
      title: 'Nominal',
      align: 'right',
      dataIndex: 'nominal',
      render: (item, obj) => {
        return (
          <Text className={obj.action === 'WITHDRAW' ? 'withdraw' : 'income'}>
            Rp{Intl.NumberFormat('id').format(Math.abs(item))}
          </Text>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'affiliates/get_affiliates',
      payload: { token }
    })
    dispatch({
      type: 'affiliates/get_affiliates_info',
      payload: { token }
    })
  }, [])

  // useEffect(() => {
  //   if (dataAffiliates) {
  //     const income = dataAffiliates.filter(d => d.action !== 'WITHDRAW')
  //     const nominalArr = income.map(d => d.nominal)
  //     const total = nominalArr.reduce((a, b) => a + b, 0)
  //     setTotal(total)

  //     const saldo = dataAffiliates.map(d => d.nominal)
  //     const totalSaldo = saldo.reduce((a, b) => a + b, 0)
  //     setSaldo(totalSaldo)
  //   }
  // }, [dataAffiliates])

  //   const onHandleSearch = () => {
  //     dispatch({
  //       type: 'distros/search_distros',
  //       payload: { token, params: { name: value } }
  //     })
  //   }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'affiliates/get_affiliates',
      payload: { token, params: { page: page } }
    })
  }

  const onHandleDate = (event) => {
    if (event) {
      const date = moment(event).format('YYYY-MM')
      const endDate = moment(date).endOf('month')

      dispatch({
        type: 'affiliates/get_affiliates',
        payload: {
          token,
          params: {
            dateFrom: moment(date).format('YYYY-MM-DD'),
            dateTo: moment(endDate).format('YYYY-MM-DD')
          }
        }
      })
    } else {
      dispatch({
        type: 'affiliates/get_affiliates',
        payload: {
          token
        }
      })
    }
  }

  const onFilterSelect = (val) => {
    dispatch({
      type: 'affiliates/get_affiliates',
      payload: { token, params: { action: val } }
    })
  }

  const onClearFilter = (page) => {
    dispatch({
      type: 'affiliates/get_affiliates',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Card>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            >
              <div>
                <p className='text-uppercase mb-0'>
                  Saldo
                </p>
                <Text
                  className='h4 amount'
                >
                  {`Rp${Intl.NumberFormat('id').format(dataInfo?.balance || 0)}`}
                </Text>
                <p
                  className='mt-3 mb-0'
                  style={{
                    fontSize: 12
                  }}
                >
                  *Silahkan menghubungi {' '}
                  <a target='_blank' href={`https://api.whatsapp.com/send?phone=6287705115217&text=*Formulir%20Penarikan*%0a%0aNama%20:%20${state?.fullName}%0aUsername%20:%20${state?.username}%0aEmail%20:%20${state?.email}%0aTanggal%20:%20${moment().format('DD-MM-YYYY, hh:mm')}%20WIB%0aNominal%20:%20`} rel='noreferrer'>kami</a>
                  {' '} untuk melakukan penarikan
                </p>
                <Text
                  style={{
                    fontSize: 10
                  }}
                >
                  Minimal penarikan <strong>Rp500.000</strong>
                </Text>
              </div>
              <div className='text-end'>
                <p className='text-uppercase mb-0'>
                  Total Pendapatan
                </p>
                <Text
                  className='h4 amount'
                >
                  {`Rp${Intl.NumberFormat('id').format(dataInfo?.totalIncome || 0)}`}
                </Text>
              </div>
            </div>
            <div
              className='mt-4'
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <DatePicker
                onChange={onHandleDate}
                picker='month'
                style={{ width: 200 }}
                disabledDate={(current) => {
                  return moment() <= current
                }}
              />

              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Filter aksi'
                optionFilterProp='children'
                onSelect={onFilterSelect}
                onClear={onClearFilter}
                allowClear
              >
                <Select.Option value='REFERRAL_TOPUP_BONUS'>
                  Top Up
                </Select.Option>
                <Select.Option value='NEW_REFERRAL_REGISTERED'>
                  Registrasi
                </Select.Option>
                <Select.Option value='WITHDRAW'>
                  Penarikan
                </Select.Option>
              </Select>
            </div>
          </Card>
          <Gap height='1rem' />
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={dataAffiliates ?? []}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerAffiliates }) => {
  return { ...reducerAffiliates }
}

export const PreviewAffiliate = connect(mapStateToProps)(_PreviewAffiliate)

_PreviewAffiliate.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  dataAffiliates: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataInfo: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
