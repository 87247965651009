import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Button, Row, Tabs } from 'antd'
import { FormItem } from 'src/components/molecules/FormItem'
import { Gap } from 'src/components/atoms/Gap'
import { connect } from 'react-redux'
import { rules } from 'src/utils/rules'
import Select from 'rc-select'
import {

  GoogleOutlined,
  FacebookFilled
} from '@ant-design/icons'

const FORM_INITIAL = {
  gtmID: '',
  pixelID: '',
  landing: '',
  button: '',
  submitEvent: '',
  events: []
}

const _SettingProduct = ({ dispatch, loading, token, close, productId, visible, onCancel, productPixel, gtmProduct }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(FORM_INITIAL)
  const [selectedEvent, setEvent] = useState([])
  const events = [
    'AddPaymentInfo',
    'AddtoCart',
    'AddtoWishlist',
    'Purchase',
    'Lead',
    'CompleteRegistration',
    'Search',
    'ViewContent',
    'InitiateCheckout'
  ]

  useEffect(() => {
    if (productId) {
      dispatch({
        type: 'product_pixel/get_product_pixel',
        payload: {
          token,
          productId
        }
      })
    }
  }, [productId])

  useEffect(() => {
    if (productId) {
      dispatch({
        type: 'gtm_product/get',
        payload: {
          token,
          productId
        }
      })
    }
  }, [productId])

  useEffect(() => {
    // const arr = []
    if (gtmProduct) {
      setFormData((prevState) => ({
        ...prevState,
        gtmID: gtmProduct.googleTagManager
      }))
      form.setFieldsValue({
        gtmID: gtmProduct.googleTagManager
      })
    }
  }, [gtmProduct])

  useEffect(() => {
    // const arr = []
    if (productPixel) {
      setFormData((prevState) => ({
        ...prevState,
        pixelID: productPixel.pixelId,
        events: productPixel.events
      }))
      productPixel?.events?.map((event) => {
        if (event.position === 'landing-page-scan') {
          setFormData((prevState) => ({
            ...prevState,
            landing: event.eventName
          }))
          form.setFieldsValue({
            'landing-page-scan': event.eventName
          })
        } else if (event.position === 'submit') {
          setFormData((prevState) => ({
            ...prevState,
            submitEvent: event.eventName
          }))
          form.setFieldsValue({
            submit: event.eventName
          })
        } else {
          setFormData((prevState) => ({
            ...prevState,
            button: event.eventName
          }))
          form.setFieldsValue({
            'button-popup-coupon': event.eventName
          })
        }
        return event.eventName
      })
      form.setFieldsValue({
        pixelID: productPixel.pixelId
      })
    }
  }, [productPixel])

  const handleChange = (e) => {
    const { target } = e
    const { id, value } = target

    setFormData((prevState) => ({ ...prevState, [id]: value }))
  }

  const handleSelect = (value, position) => {
    const selectedEvents = [...selectedEvent]
    const currentEvents = [...formData.events]
    const index = currentEvents.findIndex((f) => f.position === position)
    const indexSelected = selectedEvents.findIndex((f) => f === value)

    if (index !== -1) {
      currentEvents.splice(index, 1)
      setFormData((prevState) => ({
        ...prevState,
        events: [
          ...currentEvents,
          {
            position,
            eventName: value
          }
        ]
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        events: [
          ...formData.events,
          {
            position,
            eventName: value
          }
        ]
      }))
    }

    if (indexSelected !== -1) {
      selectedEvents.splice(index, 1)
      selectedEvents.push(value)
      setEvent(selectedEvents)
    } else {
      selectedEvents.push(value)
      setEvent(selectedEvents)
    }
  }

  const onHandleSubmitPixel = () => {
    const data = {
      pixelId: formData.pixelID,
      events: formData.events
    }

    dispatch({
      type: 'product_pixel/create_product_pixel',
      payload: {
        token,
        data,
        productId
      }
    })

    form.resetFields()
    onCancel()
    setFormData(FORM_INITIAL)
    // form.validateFields().then(data => {
    //   const forms = {
    //     ...data,
    //     file: filePdf
    //   }
    //   dispatch({
    //     type: 'product_master/upload_product_master',
    //     payload: { token, data: forms, productId }
    //   })
    // })
  }

  const onHandleSubmitGtm = () => {
    const data = {
      googleTagManager: formData.gtmID
    }

    dispatch({
      type: 'gtm_product/create',
      payload: {
        token,
        data,
        productId
      }
    })

    form.resetFields()
    onCancel()
    setFormData(FORM_INITIAL)
  }

  return (
    <Modal
      title='Setting GTM & Pixel'
      onCancel={() => {
        onCancel()
        setFormData(FORM_INITIAL)
      }}
      footer={false}
      visible={visible}
    >
      <Tabs defaultActiveKey='1'>
        <Tabs.TabPane
          tab={
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <GoogleOutlined />
              Setting GTM
            </span>
            }
          key='1'
          style={{ textAlign: 'left' }}
        >
          <Form
            layout='vertical'
            form={form}
            // onFinish={onHandleSubmitGtm}
            autoComplete='off'
          >
            <FormItem
              col={24}
              name='gtmID'
              placeholder='GTM ID'
              label='GTM ID'
              onChange={handleChange}
              rules={[rules.required]}
            />

            <Gap height='2rem' />
            <Row justify='end'>
              <Button type='primary' htmlType='button' loading={loading} onClick={onHandleSubmitGtm}>
                Submit
              </Button>
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FacebookFilled />
              Setting Pixel
            </span>
            }
          key='2'
          style={{ textAlign: 'left' }}
        >
          <Form
            layout='vertical'
            form={form}
          // onFinish={onHandleSubmitPixel}
            autoComplete='off'
          >
            <FormItem
              col={24}
              name='pixelID'
              placeholder='Pixel ID'
              label='Pixel ID'
              onChange={handleChange}
              rules={[rules.required]}
            />
            <p style={{
              marginBottom: '0.5em',
              lineHeight: 1.5
            }}
            >Event Pixel
            </p>
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                placeholder={`Landing Page Scan ${
                      formData.landing !== '' ? '- ' + formData.landing : ''
                    }`}
                disabled
              />
              <FormItem
                rules={[rules.required]}
                col={12}
                select
                placeholder='Select Event'
                name='landing-page-scan'
                onChange={(e) => handleSelect(e, 'landing-page-scan')}
                value={formData.landing}
              >
                {events.map(
                  (event, index) =>
                    !selectedEvent.includes(event) && (
                      <Select.Option key={index} value={event}>
                        {event}
                      </Select.Option>
                    )
                )}
              </FormItem>
            </Row>
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                placeholder={`Button Popup Coupon ${
                      formData.button !== '' ? '- ' + formData.button : ''
                    }`}
                disabled
              />
              <FormItem
                rules={[rules.required]}
                col={12}
                select
                placeholder='Select Event'
                name='button-popup-coupon'
                onChange={(e) => handleSelect(e, 'button-popup-coupon')}
              >
                {events.map(
                  (event, index) =>
                    !selectedEvent.includes(event) && (
                      <Select.Option key={index} value={event}>
                        {event}
                      </Select.Option>
                    )
                )}
              </FormItem>
            </Row>
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                placeholder={`Submit Coupon ${
                      formData.submitEvent !== '' ? '- ' + formData.submitEvent : ''
                    }`}
                disabled
              />
              <FormItem
                rules={[rules.required]}
                col={12}
                select
                placeholder='Select Event'
                name='submit'
                onChange={(e) => handleSelect(e, 'submit')}
                value={formData.submitEvent}
              >
                {events.map(
                  (event, index) =>
                    !selectedEvent.includes(event) && (
                      <Select.Option key={index} value={event}>
                        {event}
                      </Select.Option>
                    )
                )}
              </FormItem>
            </Row>
            <Gap height='2rem' />
            <Row justify='end'>
              <Button type='primary' htmlType='button' loading={loading} onClick={onHandleSubmitPixel}>
                Submit
              </Button>
            </Row>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

const mapStateToProps = ({ reducerProductGtm, reducerProductPixel }) => {
  return { ...reducerProductGtm, ...reducerProductPixel }
}

export const SettingProduct = connect(mapStateToProps)(_SettingProduct)

_SettingProduct.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.bool,
  token: PropTypes.string,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  productId: PropTypes.string,
  productPixel: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  gtmProduct: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
