import React from 'react'
import PropTypes from 'prop-types'
import { Form, Layout } from 'antd'

import { Gap } from 'src/components/atoms/Gap'

export const Forms = ({
  titleBar,
  children,
  form,
  layout,
  onFinish,
  initialValues
}) => {
  return (
    <Layout className='container-create-user'>
      {titleBar && <p className='big-title'>{titleBar}</p>}
      {titleBar && <Gap height='3rem' />}
      <Form
        form={form}
        layout={layout || 'vertical'}
        onFinish={onFinish}
        initialValues={initialValues || ''}
      >
        {children}
      </Form>
    </Layout>
  )
}

Forms.propTypes = {
  titleBar: PropTypes.string,
  form: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  layout: PropTypes.string,
  onFinish: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  initialValues: PropTypes.object
}
