/* eslint-disable react/prop-types */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import _data from 'src/data'

const Pricing = (props) => {
  // console.log(props)
  const navigation = useNavigate()

  const onHandlePurchaseNow = (items) => {
    navigation(items.path, { state: items.type })
  }

  return (
    <>
      <div className='overflow-hidden'>
        <section
          id='pricing'
          className={'package-section ptb-100 ' + (props?.bgColor && props?.bgColor === 'gray' ? 'gray-light-bg' : 'background-shape-right')}
        >
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-8'>
                <div className='section-heading text-center mb-5'>
                  {props?.showTitle && props?.showTitle === true && (
                    <span className='badge badge-primary badge-pill'>
                      Our Pricing Package
                    </span>
                  )}
                  <h2>
                    Pilihan harga dan paket sesuai kebutuhan Anda
                  </h2>
                  {/* <p className='lead'>
                    Monotonectally grow strategic process improvements
                    vis-a-vis integrated resources.
                  </p> */}
                </div>
              </div>
            </div>
            <div className='row justify-content-center' style={{ gap: '16px' }}>
              {(_data.price.packages || []).map((_package, index) => {
                return (
                  <div className='col-lg-3 col-md card single-pricing-pack' key={index}>
                    <div
                      className={
                          'text-center' +
                          (_package.isFeatured ? 'popular-price' : '')
                        }
                    >
                      <div className='pt-4'>
                        <h5>{_package.license}</h5>
                      </div>
                      <div className='pricing-img mt-4'>
                        <img
                          src='img/basic.svg'
                          alt='pricing img'
                          className='img-fluid'
                        />
                      </div>
                      <div className='card-header py-4 border-0 pricing-header'>
                        <div className='h2 text-center mb-0'>
                          <span className='price font-weight-bolder'>
                            {/* {
                              props.pricing?.filter((item) => item.name === _package.type).map(it => ' ' + new Intl.NumberFormat().format(it.price))
                            } */}
                            {_package.type === 'special_master'
                              ? 'Special Price'
                              : 'Rp' + props.pricing?.filter((item) => item.name === _package.type).map(it => ' ' + new Intl.NumberFormat().format(_package.price))}
                          </span>
                        </div>
                      </div>
                      <div className='card-body'>
                        <ul className='list-unstyled text-sm mb-4 pricing-feature-list'>
                          {(_package.features || []).map(feature => {
                            return <li key={feature}>{feature}</li>
                          })}
                        </ul>
                        {_package.type === 'special_master'
                          ? (<button onClick={() => onHandlePurchaseNow(_package)} className='outline-btn' rel='noreferrer'>Contact now</button>)
                          : (<button onClick={() => onHandlePurchaseNow(_package)} className='outline-btn' rel='noreferrer'>Purchase now</button>)}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <h2 className='text-center mt-5 my-3'>Pertanyaan yang sering ditanyakan</h2>
            {!(props?.hideFaq && props?.hideFaq === true) && props?.theme !== 'four' && (
              <div className='row mt-5'>
                <div className='col-lg-6'>
                  <div id='accordion-1' className='accordion accordion-faq'>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-1-1'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-1-1'
                        aria-expanded='false'
                        aria-controls='collapse-1-1'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-receipt mr-3' /> Apa itu OPIM?
                        </h6>
                      </div>
                      <div
                        id='collapse-1-1'
                        className='collapse'
                        aria-labelledby='heading-1-1'
                        data-parent='#accordion-1'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            OPIM adalah sebuah platform yang dapat memproduksi jutaan kode QR unik tanpa ada satupun yang sama. OPIM menawarkan sebuah solusi untuk melindungi produk Anda dari pemalsuan dilengkapi dengan fitur analisis yang lengkap.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-1-2'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-1-2'
                        aria-expanded='false'
                        aria-controls='collapse-1-2'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-gallery mr-3' /> Akun OPIM
                        </h6>
                      </div>
                      <div
                        id='collapse-1-2'
                        className='collapse'
                        aria-labelledby='heading-1-2'
                        data-parent='#accordion-1'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            ...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-1-3'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-1-3'
                        aria-expanded='false'
                        aria-controls='collapse-1-3'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-wallet mr-3' /> Bagaimana Status Hukumnya di Indonesia?
                        </h6>
                      </div>
                      <div
                        id='collapse-1-3'
                        className='collapse'
                        aria-labelledby='heading-1-3'
                        data-parent='#accordion-1'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            ...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-1-3'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-1-3'
                        aria-expanded='false'
                        aria-controls='collapse-1-3'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-wallet mr-3' /> Cara Menggunakan OPIM ?
                        </h6>
                      </div>
                      <div
                        id='collapse-1-3'
                        className='collapse'
                        aria-labelledby='heading-1-3'
                        data-parent='#accordion-1'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div id='accordion-2' className='accordion accordion-faq'>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-2-1'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-2-1'
                        aria-expanded='false'
                        aria-controls='collapse-2-1'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-receipt mr-3' /> Apakah OPIM Aman Digunakan?
                        </h6>
                      </div>
                      <div
                        id='collapse-2-1'
                        className='collapse'
                        aria-labelledby='heading-2-1'
                        data-parent='#accordion-2'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            ...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-2-2'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-2-2'
                        aria-expanded='false'
                        aria-controls='collapse-2-2'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-lock mr-3' /> Bagaimana Cara Mendaftar di OPIM ?
                        </h6>
                      </div>
                      <div
                        id='collapse-2-2'
                        className='collapse'
                        aria-labelledby='heading-2-2'
                        data-parent='#accordion-2'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            ....
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-2-3'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-2-3'
                        aria-expanded='false'
                        aria-controls='collapse-2-3'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-widget mr-3' /> Bagaimana Top Up Credit di OPIM ?
                        </h6>
                      </div>
                      <div
                        id='collapse-2-3'
                        className='collapse'
                        aria-labelledby='heading-2-3'
                        data-parent='#accordion-2'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            Efficiently supply B2B networks vis-a-vis
                            best-of-breed schemas. Dramatically parallel task
                            reliable technology with cross functional core
                            competencies. Phosfluorescently.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-2-3'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-2-3'
                        aria-expanded='false'
                        aria-controls='collapse-2-3'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-widget mr-3' /> Pembayaran Apa Saja Yang Tersedia di OPIM ?
                        </h6>
                      </div>
                      <div
                        id='collapse-2-3'
                        className='collapse'
                        aria-labelledby='heading-2-3'
                        data-parent='#accordion-2'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            - Lewat Mobile Banking <br />
                            - Lewat Digital Banking <br />
                            - Lewat Internet Banking <br />
                            - Lewat ATM <br />
                            - Lewat SMS Banking <br />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card'>
                      <div
                        className='card-header py-4'
                        id='heading-2-2'
                        data-toggle='collapse'
                        role='button'
                        data-target='#collapse-2-2'
                        aria-expanded='false'
                        aria-controls='collapse-2-2'
                      >
                        <h6 className='mb-0'>
                          <span className='ti-lock mr-3' /> Benefit Apa Saja Yang OPIM Berikan ?
                        </h6>
                      </div>
                      <div
                        id='collapse-2-2'
                        className='collapse'
                        aria-labelledby='heading-2-2'
                        data-parent='#accordion-2'
                      >
                        <div className='card-body'>
                          <p className='text-secondary'>
                            ....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!(props?.hideFaq && props?.hideFaq === true) && props?.theme === 'four' && (
              <div className='row pt-5'>
                <div className='col-md-6'>
                  <div className='single-faq'>
                    <h5>How can I pay for this?</h5>
                    <p>
                      Intrinsicly implement high standards in strategic theme
                      areas via inexpensive solutions. Assertively
                      conceptualize prospective bandwidth whereas client-based
                      imperatives.
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='single-faq'>
                    <h5>Is it possible to pay yearly?</h5>
                    <p>
                      Assertively iterate user friendly innovation without
                      open-source markets. Monotonectally extend resource
                      sucking manufactured products without high-payoff
                      paradigms. Objectively customize ubiquitous information
                      before economically sound relationships.{' '}
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='single-faq'>
                    <h5>Do you offer discounts on multiple items?</h5>
                    <p>
                      Dramatically target focused testing procedures after
                      holistic ideas. Collaboratively maximize high-payoff ROI
                      and value-added products. Distinctively deliver
                      cooperative collaboration and idea-sharing whereas
                      customized
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='single-faq'>
                    <h5>Is VAT included in plan prices?</h5>
                    <p>
                      Distinctively simplify high-quality initiatives for
                      highly efficient applications. Monotonectally repurpose
                      integrated customer service after magnetic e-services.{' '}
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='single-faq'>
                    <h5>Will I pay more for some features?</h5>
                    <p>
                      Enthusiastically pontificate resource-leveling supply
                      chains whereas scalable markets. Authoritatively
                      streamline resource maximizing methods of empowerment
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='single-faq'>
                    <h5>
                      Why are there no limits on the number of messages?
                    </h5>
                    <p>
                      Assertively target turnkey ideas for market-driven
                      portals. Appropriately e-enable world-class intellectual
                      capital whereas 2.0 mindshare.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  )
}

export default Pricing
