import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/log-activity/actions'
import { serviceLogActivity } from 'src/services/api/logActivity'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'log_activity/set_state',
    payload
  })
}

export function * getLog ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceLogActivity.get, token, params)

  if (response.status === 200) {
    yield setState({
      type: 'log_activity/get_activity',
      log: response.data.data,
      loading: false
    })
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_log, getLog)
  ])
}
