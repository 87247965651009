import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Content } from 'antd/lib/layout/layout'
import { Popconfirm, Button, Layout, Space, Table } from 'antd'
import {
  DeleteOutlined,
  PlusCircleOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { CreateRoles } from 'src/pages/roles/CreateRole'
import { UpdateRoles } from './UpdateRole'

const _Roles = ({ dispatch, token, items, loading }) => {
  const text = 'Are you sure to delete this task?'
  const [visibleUpadate, setVisibleUpdate] = useState(false)
  const [data, setData] = useState()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Credit',
      dataIndex: 'credit'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => <p style={{ margin: 0 }}>Rp. {text}</p>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 300,
      align: 'center',
      render: (txt, obj) => {
        return (
          <Space>
            <Button size='small' disabled>
              <EyeOutlined />
            </Button>
            <Button
              size='small'
              onClick={() => onHandleVisibleUpdate(obj)}
              className='button-update'
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              placement='topLeft'
              title={text}
              okText='Yes'
              onConfirm={() => onHandleDeleteRole(obj.name)}
              cancelText='No'
              disabled
            >
              <Button size='small' disabled danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'roles/get_roles',
      payload: {
        token
      }
    })
    dispatch({
      type: 'permission/get_permission',
      payload: { token }
    })
  }, [])

  const setDispatch = (payload) => {
    dispatch({
      type: 'roles/set_state',
      payload
    })
  }

  const onHandleVisibleUpdate = (items) => {
    setData(items)
    setVisibleUpdate(!visibleUpadate)
  }

  const onHandleDeleteRole = (roleName) => {
    dispatch({
      type: 'roles/delete_roles',
      payload: {
        token,
        roleName
      }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-roles'>
        <Content className='container'>
          <Header
            simple
            title='Create Roles'
            type='primary'
            placeholder='search roles by roles user'
            icon={<PlusCircleOutlined />}
            onClick={() => setDispatch({ popupVisible: true })}
          />
          <Gap height='1rem' />
          <Table
            loading={loading}
            columns={columns}
            dataSource={items ?? []}
            rowSelection={{
              type: 'checkbox'
            }}
          />
        </Content>
        <CreateRoles token={token} />
        <UpdateRoles
          data={data}
          token={token}
          onCancel={() => setVisibleUpdate(!visibleUpadate)}
          visible={visibleUpadate}
        />
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerRoles }) => {
  return { ...reducerRoles }
}

export const Roles = connect(mapStateToProps)(_Roles)

_Roles.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  items: PropTypes.array,
  loading: PropTypes.bool
}
