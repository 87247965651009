import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Modal,
  Row,
  Select,
  message
} from 'antd'
import { PlusCircleOutlined, AimOutlined } from '@ant-design/icons'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { Maps } from './Maps'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

export const _CreateAddress = ({ token, dispatch, loading, visible, onCancel, profile }) => {
  const [form] = Form.useForm()
  const [fullAddress, setFullAddress] = useState('')
  const [map, setMap] = useState({
    latitude: null,
    longititude: null,
    visible: false
  })

  useEffect(() => {
    loading && onClose()
  }, [loading])

  // const onHandleAllowLocation = () => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setMap({ ...map, longititude: position.coords.longititude, latitude: position.coords.latitude })
  //   })
  // }

  // useEffect(() => {
  //   onHandleAllowLocation()
  // }, [])

  const onHandleSubmit = () => {
    form.validateFields().then(data => {
      if (!map.latitude && !map.longititude) {
        message.warning('Pilih lokasi agar alamat pengiriman lebih akurat')
      } else {
        let newData
        if (profile.account.addresses) {
          newData = {
            addresses: [
              ...profile?.account?.addresses,
              {
                name: data.name,
                phone: data.phone,
                label: data.label,
                province: data.province,
                city: data.city,
                district: data.district,
                zip: data.zip,
                address: data.address,
                description: data.description !== '' ? data.description : null,
                longititude: map.longititude,
                latitude: map.latitude
              }
            ]
          }
        } else {
          newData = {
            addresses: [
              {
                name: data.name,
                phone: data.phone,
                label: data.label,
                province: data.province,
                city: data.city,
                district: data.district,
                zip: data.zip,
                address: data.address,
                description: data.description !== '' ? data.description : null,
                longititude: map.longititude,
                latitude: map.latitude
              }
            ]
          }
        }
        dispatch({
          type: 'profile/update_profile',
          payload: {
            token,
            data: JSON.stringify(newData)
          }
        })
        // console.log('DATA', newData)
      }
    })
  }

  const onCancelMaps = () => {
    setMap((prevState) => ({
      ...prevState,
      visible: false
    }))
  }

  const onHandleChange = (event) => {
    const { value } = event.target
    setFullAddress(value)
  }

  const onClose = () => {
    setMap({
      latitude: null,
      longititude: null,
      visible: false
    })
    form.resetFields()
    setFullAddress('')
    onCancel()
  }

  return (
    <>
      <Modal
        title='Add New Address'
        visible={visible}
        footer={false}
        onCancel={onClose}
        width={500}
        centered
      >
        <Form form={form} onFinish={onHandleSubmit} layout='vertical'>
          <FormItem
            label='Name'
            placeholder='Input your name'
            name='name'
            rules={[rules.required]}
          />
          <FormItem
            label='Phone'
            placeholder='Input your number'
            name='phone'
            rules={[rules.required]}
          />
          <FormItem
            label='Address Label'
            placeholder='Choose Label'
            name='label'
            select
            rules={[rules.required]}
          >
            <Select.Option value='house'>
              House
            </Select.Option>
            <Select.Option value='apartmen'>
              Apartment
            </Select.Option>
            <Select.Option value='office'>
              Office
            </Select.Option>
          </FormItem>
          <Row gutter={[8, 16]}>
            {/* <FormItem
            label='Province'
            placeholder='Select Provinsi'
            name='province'
            col={12}
            select
            rules={[rules.required]}
          >
            <Select.Option value='province'>
              Province
            </Select.Option>
          </FormItem>
          <FormItem
            label='City/Regency'
            placeholder='Select City/Regency'
            name='city'
            col={12}
            select
            rules={[rules.required]}
          >
            <Select.Option value='city'>
              City
            </Select.Option>
          </FormItem> */}
            <FormItem
              label='Province'
              placeholder='Select Provinsi'
              name='province'
              col={12}
              rules={[rules.required]}
            />
            <FormItem
              label='City/Regency'
              placeholder='Select City/Regency'
              name='city'
              col={12}
              rules={[rules.required]}
            />
          </Row>
          <Row gutter={[8, 16]}>
            {/* <FormItem
            label='Districts'
            placeholder='Select Kecamatan'
            name='district'
            col={12}
            select
            rules={[rules.required]}
          >
            <Select.Option value='district'>
              District
            </Select.Option>
          </FormItem> */}
            <FormItem
              label='Districts'
              placeholder='Select Kecamatan'
              name='district'
              col={12}
              rules={[rules.required]}
            />
            <FormItem
              label='Postal Code'
              placeholder='Add postal code'
              name='zip'
              col={12}
              rules={[rules.required]}
            />
          </Row>
          <FormItem
            textarea
            label='Full Address'
            placeholder='Input full address'
            name='address'
            rules={[rules.required]}
            onChange={onHandleChange}
          />
          <FormItem
            textarea
            label='More Detail'
            placeholder='Ex: House Color'
            name='description'
          />

          {!map.latitude && !map.longititude
            ? (
              <div
                className='mb-4'
                style={{
                  width: '100%',
                  height: 60,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                  borderRadius: 7,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setMap((prevState) => ({ ...prevState, visible: !map.visible }))}
              >
                <AimOutlined className='mr-2' style={{ color: '#828282' }} />
                Chose Location in Maps
              </div>)
            : (
              <div onClick={() => setMap((prevState) => ({ ...prevState, visible: !map.visible }))}>
                <MapContainer center={[map?.latitude, map?.longititude]} zoom={13} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                  <Marker position={[map.latitude, map.longititude]}>
                    <Popup>You are here</Popup>
                  </Marker>
                </MapContainer>
              </div>
              )}

          <Row justify='end'>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              icon={<PlusCircleOutlined />}
            >
              Save Address
            </Button>
          </Row>
        </Form>
      </Modal>
      <Maps
        visible={map.visible}
        token={token}
        onCancel={onCancelMaps}
        maps={map}
        fullAddress={fullAddress}
        setMap={setMap}
      />
    </>
  )
}

const mapStateToProps = ({ reducerProfiles }) => {
  return { ...reducerProfiles }
}

export const CreateAddress = connect(mapStateToProps)(_CreateAddress)

_CreateAddress.propTypes = {
  loading: PropTypes.bool,
  token: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func,
  profile: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
