/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Content } from 'antd/lib/layout/layout'
import { io } from 'socket.io-client'
import {
  Button,
  Layout,
  Tag,
  Progress,
  Space,
  Spin,
  Table
} from 'antd'
import {
  EyeOutlined,
  PlusCircleOutlined,
  FormOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { CreateGenerateQR } from 'src/pages/generates-qr/CreateGenerateQR'
import { UpdateGenerateQR } from 'src/pages/generates-qr/UpdateGenerateQR'
import { connect } from 'react-redux'
import { PreviewGenerate } from './PreviewGenerate'
import { useNavigate } from 'react-router'
import moment from 'moment'

export const _GenerateQR = (props) => {
  const {
    data,
    success,
    token,
    dataMaster,
    dataSettingQR,
    dataDistros,
    loading,
    dispatch,
    meta
  } = props
  const [visible, setVisible] = useState(false)
  const [updateItems, setUpdateItems] = useState({
    visible: false,
    data: null
  })
  const navigation = useNavigate()
  const role = localStorage.getItem('role')
  const [socket, setSocket] = useState(null)
  const [percentP, setPercent] = useState([])
  const [newData, setNewData] = useState()
  const [value, setValue] = useState('')
  const [search, setSearch] = useState('')
  const customVal = value?.length <= 0 ? '' : search

  const urlWebSocket = process.env.REACT_APP_API_BASE_URL + 'qrcodes'

  useEffect(() => {
    dispatch({
      type: 'generate/get_generate_qr',
      payload: { token }
    })
    dispatch({
      type: 'product_master/get_product_master',
      payload: { token }
    })
    dispatch({
      type: 'setting_qr/get_setting_qr',
      payload: { token }
    })

    role !== 'free_master' && role !== 'sub_account' &&
      dispatch({
        type: 'distros/get_distros',
        payload: { token }
      })
  }, [])

  useEffect(() => {
    if (success) setVisible(false)
  }, [success])

  useEffect(() => {
    setNewData(data)
  }, [data])

  useEffect(() => {
    const newSocket = io(urlWebSocket, {
      extraHeaders: {
        Authorization: 'Bearer ' + token
      }
    })

    setSocket(newSocket)
    return () => newSocket.close()
  }, [])

  useEffect(() => {
    if (socket) {
      socket.emit('SUBSCRIBE')

      socket.on('SUBSCRIBE', ({ masterId }) => {
        // console.log('subscribe', masterId)
        socket.on('GENERATE_PERCENTAGE', dataPercent => {
          const { generateId, percentage } = dataPercent
          const index = percentP.findIndex(it => it.id === generateId)

          if (index < 0) {
            percentP.push({
              id: generateId,
              percent: percentage
            })
          } else {
            percentP[index] = {
              ...percentP[index],
              percent: percentage
            }

            setPercent([...percentP])
          }
        })

        socket.on('GENERATE_DONE', data => {
          // console.log('done', data)
        })
      })
    }
  }, [socket])

  useEffect(() => {
    dispatch({
      type: 'generate/get_generate_qr',
      payload: {
        token,
        params: {
          customId: customVal
        }
      }
    })
  }, [search, customVal])

  const printStatusParse = (status) => {
    switch (status) {
      case 'NOT_PRINTED':
        return '<Tag>Not Printed</Tag>'
      default:
        break
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'customId',
      key: 'customId',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Product Name',
      dataIndex: ['product', 'name']
    },
    {
      title: 'Count QR',
      dataIndex: 'total'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // render: (status) => status === 'GENERATING' ? <Spin /> : status
      render: (status, obj) => {
        return (
          <Progress
            type='circle'
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068'
            }}
            percent={status === 'GENERATING'
              ? percentP.filter((item) => item.id === obj.id).map(it => it.percent)
              : 100}
            width={36}
          />
        )
      }
    },
    {
      title: 'Production Date',
      dataIndex: 'productionDate',
      render: (date) => <p>{moment(date).format('YYYY-MM-DD, HH:mm')} WIB</p>
    },
    {
      title: 'Print',
      dataIndex: 'printStatus',
      render: (status, obj) => {
        return (
          <p>
            {status === 'NOT_PRINTED' && <Tag color='red'>Not Printed</Tag>}
            {status === 'IN_QUEUE' && <Tag color='yellow'>In Queue</Tag>}
            {status === 'PRINTED' && <Tag color='green'>Printed {obj.size === 'SMALL' ? '(A4)' : '(A3)'} </Tag>}
            {status === 'DELIVERING' && <Tag color='blue'>Delivering</Tag>}
            {status === 'RECEIVED' && <Tag color='green'>Printed {obj.size === 'SMALL' ? '(A4)' : '(A3)'} </Tag>}
          </p>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 300,
      align: 'center',
      render: (txt, obj, index) => {
        return (
          <Space>
            <Button size='defau' className='button-detail d-flex align-items-center' onClick={() => onHandlePreview(obj)} disabled={percentP.length > 0 && percentP[index]?.percent !== 100}>
              <EyeOutlined /> Details
            </Button>
            <Button size='small' className='button-edit d-flex align-items-center fw-bold' onClick={() => setUpdateItems({ visible: true, data: obj })} disabled={percentP.length > 0 && percentP[index]?.percent !== 100}>
              <FormOutlined /> Edit
            </Button>
          </Space>
        )
      }
    }
  ]

  const onHandlePreview = ({ id }) => {
    navigation('/dashboard/generate-qr/' + id + '/detail', { state: id })
  }

  const onHandleDownload = ({ id }) => {
    dispatch({
      type: 'generate/download_generate_qr',
      payload: { token, generateId: id }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'generate/get_generate_qr',
      payload: { token, params: { page: page } }
    })
  }

  const onFilterSelect = (productId) => {
    dispatch({
      type: 'generate/get_generate_qr',
      payload: { token, params: { productId } }
    })
  }
  const onClearFilter = (page) => {
    dispatch({
      type: 'generate/get_generate_qr',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-roles'>
        <Content className='container'>
          <Header
            simple
            placeholder='search generate qr by product name or id'
            title='Generate QR'
            type='primary'
            onClick={() => setVisible(!visible)}
            icon={<PlusCircleOutlined />}
            filter
            data={dataMaster}
            onFilterSelect={onFilterSelect}
            onClearFilter={onClearFilter}
            onClickSearch={() => setSearch(value)}
            onChange={({ target }) =>
              setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey='id'
            rowSelection={{
              type: 'checkbox'
            }}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
          />
        </Content>
        <CreateGenerateQR
          product={dataMaster}
          distros={dataDistros}
          settings={dataSettingQR}
          token={token}
          onCancel={() => setVisible(!visible)}
          visible={visible}
        />
        <UpdateGenerateQR
          product={dataMaster}
          distros={dataDistros}
          settings={dataSettingQR}
          visible={updateItems.visible}
          dataUpdate={updateItems.data}
          token={token}
          onCancel={() => setUpdateItems({ visible: false })}
        />
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({
  reducerGenerateQR,
  reducerProductMaster,
  reducerDistros,
  reducerSettingQR
}) => {
  return {
    ...reducerGenerateQR,
    dataMaster: reducerProductMaster.productMaster,
    dataDistros: reducerDistros.data,
    dataSettingQR: reducerSettingQR.data
  }
}

export const GenerateQR = connect(mapStateToProps)(_GenerateQR)

_GenerateQR.propTypes = {
  token: PropTypes.string,
  data: PropTypes.array,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  dataDistros: PropTypes.array,
  dataSettingQR: PropTypes.array,
  dataMaster: PropTypes.array,
  meta: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
