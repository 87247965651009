import { Button } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
// import { Gap } from '../../atoms/Gap'
import OpimLogo from 'src/assets/images/opim-logo.svg'
import PropTypes from 'prop-types'

const Header = ({ token }) => {
  const navigation = useNavigate()

  const onHandleRedirect = url => {
    navigation(url)
  }

  return (
    <>
      <header className='header'>
        <nav className='navbar navbar-expand-lg fixed-top custom-nav white-bg'>
          <div className='container'>
            <a className='navbar-brand' href='/'>
              <img
                src={OpimLogo}
                width='100'
                alt='logo'
                className='img-fluid mb-2'
              />
            </a>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='ti-menu' />
            </button>

            <div
              className='collapse navbar-collapse main-menu'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ml-auto'>
                <li className='nav-item'>
                  <a className='nav-link page-scroll' href='/#'>
                    Home
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link page-scroll' href='#about'>
                    About
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link page-scroll' href='#features'>
                    Features
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link page-scroll' href='#pricing'>
                    Pricing
                  </a>
                </li>
                {
                  token
                    ? (
                      <li className='nav-item ml-s-3 ml-3'>
                        <Button onClick={() => onHandleRedirect('/dashboard')} className='button button-signin'>
                          Dashboard
                        </Button>
                      </li>
                      )
                    : (
                      <>
                        <li className='nav-item ml-s-3 ml-3 d-flex'>
                          <Button onClick={() => onHandleRedirect('/login')} className='button button-signin'>
                            Sign In
                          </Button>
                          <a className='nav-link p-2 button button-registration page-scroll ml-1' href='#pricing'>
                            Registration
                          </a>
                        </li>
                      </>
                      )
                }
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default connect(state => ({}))(Header)

Header.propTypes = {
  token: PropTypes.string
}
