import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceSubAccount = {
  get: (token, params) => helper.get('api/v1/sub-accounts', token, params),
  create: (token, { data }) => helper.post('api/v1/sub-accounts', data, token),
  update: (token, { data }) => helper.post('api/v1/sub-accounts', data, token),
  access: (token, { subAccountId, data }) => helper.patch(`api/v1/sub-accounts/${subAccountId}/settings`, data, token),
  delete: (token, { subAccountId }) =>
    axios.delete(apiBaseUrl + `api/v1/sub-accounts/${subAccountId}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
