import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Button, Layout, Space, Table, Tag } from 'antd'
// import { Header } from '../../components/molecules/Header'
import moment from 'moment'

export const _ConfirmTopUp = ({ token, dispatch, data, loading, success }) => {
  useEffect(() => {
    dispatch({
      type: 'topup_admin/get_list',
      payload: {
        token
      }
    })
  }, [success])

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username'
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('DD/MM/YYYY hh:mm:ss')
    },
    {
      title: 'Credit',
      dataIndex: 'credit'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => 'Rp ' + new Intl.NumberFormat().format(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return text.toLowerCase() === 'accepted'
          ? <Tag color='green'>{text}</Tag>
          : text.toLowerCase() === 'pending'
            ? <Tag color='orange'>{text}</Tag>
            : <Tag color='red'>{text}</Tag>
      },
      align: 'center'
    },
    {
      title: 'Action',
      render: (txt, obj) => {
        return obj.status.toLowerCase() === 'pending' && (
          <Space>
            <Button size='large' htmlType='submit' className='button-detail' onClick={() => onHandleConfirm(obj.id, 'ACCEPTED')}>Accept</Button>
            <Button size='large' type='primary' danger htmlType='submit' onClick={() => onHandleConfirm(obj.id, 'REJECTED')}>Reject</Button>
          </Space>
        )
      }
    }
  ]

  const onHandleConfirm = (id, confirm) => {
    dispatch({
      type: 'topup_admin/confirm_topup',
      payload: {
        token,
        id,
        params: {
          status: confirm.toUpperCase()
        }
      }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'topup_admin/get_list',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-products'>
        <Layout.Content className='container'>
          {/* <Header
            simple
            type='primary'
            placeholder='search by type or name product'
            onClickSearch={() => setSearch(value)}
            onChange={({ target }) =>
              setValue(target.value)}
          /> */}
          <Gap height='1rem' />
          <Table
            columns={columns}
            loading={loading}
            dataSource={data?.items}
            rowKey='id'
            pagination={data?.meta && { defaultPageSize: data?.meta.itemsPerPage, total: data?.meta.totalItems, pageSize: data?.meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
        </Layout.Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerTopUpAdmin }) => {
  return { ...reducerTopUpAdmin }
}

export const ConfirmTopUp = connect(mapStateToProps)(_ConfirmTopUp)

_ConfirmTopUp.propTypes = {
  token: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  loading: PropTypes.bool,
  success: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
