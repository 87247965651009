import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Image, Button, Layout, Popconfirm, Space, Table } from 'antd'
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { connect } from 'react-redux'

const _ConfirmProduct = ({ dispatch, token, loading, dataConfirms, metaConfirm }) => {
  const text = 'Are you sure to reject this product ?'
  const columnsLog = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'SKU',
      dataIndex: 'sku'
    },
    {
      title: 'Price',
      dataIndex: 'price'
    },
    {
      title: 'Distributed by',
      dataIndex: 'distributedBy'
    },
    {
      title: 'Images Product',
      align: 'center',
      dataIndex: 'images',
      render: (item, obj) => <Avatar shape='square' icon={<Image src={obj?.images[0]?.url} />} />
    },
    {
      title: 'Produced by',
      dataIndex: 'producedBy'
    },
    {
      title: 'Category',
      width: 150,
      dataIndex: 'category'
    },
    {
      title: 'Action',
      fixed: 'right',
      dataIndex: 'action',
      width: 200,
      align: 'center',
      render: (txt, obj) => (
        <Space>
          <Button
            size='small'
            className='button-detail'
            onClick={() => onHandleConfirmProduct(obj)}
          >
            <CheckCircleOutlined /> Accept
          </Button>
          <Popconfirm
            placement='topLeft'
            title={text}
            onConfirm={() => onHandleRejectProduct(obj)}
            okText='Yes'
            cancelText='No'
          >
            <Button size='small' className='button-delete'>
              <MinusCircleOutlined /> Reject
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'product/get_confirm_product',
      payload: {
        token
      }
    })
  }, [])

  const onHandleConfirmProduct = ({ id }) => {
    dispatch({
      type: 'product/accepted_product',
      payload: {
        token,
        productId: id,
        pending: false
      }
    })
  }

  const onHandleRejectProduct = ({ id }) => {
    dispatch({
      type: 'product/rejected_product',
      payload: {
        confirm: true,
        token,
        productId: id
      }
    })
  }
  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-products'>
        <Layout.Content className='container'>
          <Header
            simple
            placeholder='search by type or name product'
            type='primary'
          />
          <Gap height='1rem' />
          <Table
            loading={loading}
            columns={columnsLog}
            dataSource={dataConfirms ?? []}
          />
        </Layout.Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerProducts }) => {
  return { ...reducerProducts }
}

export const ConfirmProduct = connect(mapStateToProps)(_ConfirmProduct)

_ConfirmProduct.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.func,
  dataConfirms: PropTypes.array,
  metaConfirm: PropTypes.object,
  token: PropTypes.string
}
