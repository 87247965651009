export const actions = {
  set_state: 'generate/set_state',
  create_generate_qr: 'generate/create_generate_qr',
  update_generate_qr: 'generate/update_generate_qr',
  print_request: 'generate/print_request',
  update_request: 'generate/update_request',
  get_generate_qr: 'generate/get_generate_qr',
  detail_generate_qr: 'generate/detail_generate_qr',
  download_generate_qr: 'generate/download_generate_qr'
}
