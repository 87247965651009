import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceDistro = {
  get: (token, params) => helper.get('api/v1/distros', token, params),
  search: (token, params) => helper.get('api/v1/distros/search', token, params),
  post: (token, params) => helper.post('api/v1/distros', params, token),
  update: (token, { distroId, data }) => helper.patch('api/v1/distros/' + distroId, data, token),
  delete: (token, { distroID }) =>
    axios.delete(apiBaseUrl + `api/v1/distros/${distroID}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
