import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Row,
  Col,
  Empty,
  message
} from 'antd'
import { PlusOutlined, FormOutlined, CheckOutlined } from '@ant-design/icons'
import { CreateAddress } from './CreateAddress'
import { UpdateAddress } from './UpdateAddress'
import { useLocation } from 'react-router-dom'
import './style/index.css'

export const _Address = ({ token, dispatch, loading, address, onCancel, profile }) => {
  const { state } = useLocation()
  const [visible, setVisible] = useState(false)
  const [update, setUpdate] = useState({
    items: null,
    visible: false
  })
  const [choose, setChoose] = useState(null)

  useEffect(() => {
    loading && onClose()
  }, [loading])

  const onHandleUpdate = (items, index) => {
    setUpdate({ items: { ...items, index }, visible: true })
  }

  const onHandleCancel = () => {
    setUpdate({ items: null, visible: false })
    setVisible(false)
  }

  const doOpen = () => {
    setVisible(!visible)
    onCancel()
  }

  const chooseAddress = (index) => {
    setChoose(index)
  }

  const onClose = () => {
    setUpdate({ items: null, visible: false })
    setVisible(false)
    setChoose(null)
    onCancel()
  }

  const onSubmit = () => {
    if (choose === null) {
      message.warning('Silahkan pilih alamat terlebih dahulu')
    } else {
      const newData = {
        generateQrcodeId: state,
        address: { ...profile.account.addresses[choose] }
      }
      dispatch({
        type: 'generate/print_request',
        payload: {
          token,
          items: JSON.stringify(newData)
        }
      })
    }
  }

  return (
    <>
      <Modal
        visible={address}
        footer={false}
        onCancel={onClose}
        width={700}
        closable={false}
      >
        <div style={{ padding: '0 16px' }}>
          <div className='d-flex mb-4' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <h2 className='address-title'>Choose Address</h2>
            {profile?.account?.addresses?.length === 5
              ? null
              : (
                <div onClick={doOpen} className='d-flex' style={{ alignItems: 'center', color: '#1890ff', cursor: 'pointer' }}>
                  <PlusOutlined className='mr-1' style={{ fontSize: 16 }} />
                  Add Address
                </div>)}
          </div>
          {profile?.account?.addresses && profile?.account?.addresses.length > 0 && profile?.account?.addresses.map((val, index) =>
            <div className={index === choose ? 'list-address active' : 'list-address'} key={index} onClick={() => chooseAddress(index)}>
              <div>
                <h3 className='d-flex address-title' style={{ alignItems: 'center' }}>{val.name} <span className='ml-1' style={{ fontSize: 11, fontWeight: 500 }}>({val.phone.indexOf('0') === 0 ? val.phone.replace('0', '+62') : val.phone})</span><FormOutlined className='ml-2' onClick={() => onHandleUpdate(val, index)} style={{ fontSize: 14, color: '#1890ff' }} /></h3>
                <p className='mb-2' style={{ maxWidth: 400 }}>{val.address}, {val.city}, {val.district}, {val.province}, ID {val.zip}</p>
                {val.description && <div className='p-2' style={{ backgroundColor: '#f7f7f7', borderLeft: '3px solid #1890ff' }}>{val.description}</div>}
              </div>
              {index === choose &&
              (
                <div style={{ width: '30%', textAlign: 'right' }}>
                  <CheckOutlined style={{ color: 'rgb(43 51 178)', fontSize: 20 }} />
                </div>)}
            </div>
          )}
          {(!profile?.account.addresses || profile?.account?.addresses.length === 0) && <div className='d-flex' style={{ height: 140, alignItems: 'center', justifyContent: 'center' }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
        </div>
        <Row justify='center' className='pt-2 mb-2' style={{ paddingRight: 18, paddingLeft: 18 }} gutter={16}>
          <Col span={12}>
            <Button
              type='primary'
              ghost
              loading={loading}
              onClick={onClose}
              block
              size='large'
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              loading={loading}
              block
              size='large'
              onClick={onSubmit}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
      <CreateAddress
        loading={loading}
        visible={visible}
        token={token}
        onCancel={onHandleCancel}
        profile={profile}
      />
      <UpdateAddress
        visible={update.visible}
        items={update.items}
        token={token}
        onCancel={() => setUpdate({ items: null, visible: false })}
        profile={profile}
      />
    </>
  )
}

const mapStateToProps = ({ reducerProfiles, reducerGenerateQR }) => {
  return { ...reducerGenerateQR, profile: reducerProfiles.profile }
}

export const Address = connect(mapStateToProps)(_Address)

_Address.propTypes = {
  loading: PropTypes.bool,
  token: PropTypes.string,
  address: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func,
  profile: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
