import React, { useEffect } from 'react'

import Header from 'src/components/organizes/Header/header'
import HeroSection from 'src/components/organizes/HeroSection/HeroSection1'
import PromoSection from 'src/components/organizes/PromoSection'
import AboutSection from 'src/components/organizes/AboutUs'
// import FeatureSection from 'src/components/organizes/Features'
import DownloadSection from 'src/components/organizes/Download'
import PricingSection from 'src/components/organizes/Pricing'
// import Testimonial from 'src/components/organizes/Testimonial'
// import Screenshots from 'src/components/organizes/Screenshots'
// import TeamMember from 'src/components/organizes/TeamMember'
import ContactSection from 'src/components/organizes/Contact'
import TrustedCompany from 'src/components/organizes/TrustedCompany/TrustedCompany'
import SubscribeSection from 'src/components/organizes/Subscribe'
import FooterSection from 'src/components/organizes/Footer'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const _Landing = ({ token, dispatch, data, loading }) => {
  useEffect(() => {
    dispatch({
      type: 'topup/get_prices',
      payload: {
        token
      }
    })
    // dispatch({
    //   type: 'facebook_pixel/get_facebook_pixel',
    //   payload: {
    //     token
    //   }
    // })
  }, [])

  return (
    <>
      <Header token={token} />
      <div className='main'>
        <HeroSection />
        <PromoSection />
        <AboutSection />
        <DownloadSection />
        <PricingSection hideFaq={false} pricing={data || []} />
        {/* <Testimonial /> */}
        {/* <Screenshots /> */}
        {/* <TeamMember /> */}
        <ContactSection />
        <TrustedCompany />
      </div>
      <SubscribeSection />
      <FooterSection />
    </>
  )
}

const mapStateToProps = ({ reducerTopUp }) => {
  return { ...reducerTopUp }
}

export const Landing = connect(mapStateToProps)(_Landing)

_Landing.propTypes = {
  token: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  loading: PropTypes.bool
}
