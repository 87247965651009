import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Modal,
  Row,
  Select
} from 'antd'
import {
  UserOutlined,
  PlusCircleOutlined,
  DollarCircleOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'

const _CreateRoles = ({
  token,
  permissions,
  dispatch,
  loading,
  popupVisible
}) => {
  const [form] = Form.useForm()

  const setDispatch = (payload) => {
    dispatch({
      type: 'roles/set_state',
      payload
    })
  }

  useEffect(() => {
    if (popupVisible === false) form.resetFields()
    return false
  }, [])

  const onHandleCreateRole = () => {
    form.validateFields().then(data => {
      const form = { ...data, price: Number(data.price) }
      dispatch({
        type: 'roles/create_roles',
        payload: {
          token,
          data: form
        }
      })
    })
  }

  const onCancel = () => {
    setDispatch({
      popupVisible: false
    })
  }

  return (
    <Modal
      title='Creaete Permission'
      centered
      wrapClassName='modal-create--roles'
      maskClosable={false}
      visible={popupVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onHandleCreateRole}
      >
        <FormItem
          rules={[rules.required]}
          prefix={<UserOutlined />}
          name='name'
          label='Role Name'
          placeholder='your role name'
        />
        <FormItem
          label='Choose Permissions'
          placeholder='Silver'
          name='permissions'
          select
          mode='tags'
          rules={[rules.required]}
        >
          {permissions?.map((item, key) =>
            <Select.Option key={key} value={item.name}>
              {item.name}
            </Select.Option>
          )}

        </FormItem>
        <FormItem
          rules={[rules.required]}
          prefix={<DollarCircleOutlined />}
          name='price'
          label='Price'
          placeholder='your price'
        />
        <FormItem
          rules={[rules.required]}
          name='description'
          textarea
          label='Description'
          placeholder='your permission name'
        />
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Create Role
          </Button>

        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerRoles }) => {
  return { ...reducerRoles }
}

export const CreateRoles = connect(mapStateToProps)(_CreateRoles)

_CreateRoles.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  token: PropTypes.string,
  permissions: PropTypes.array,
  popupVisible: PropTypes.bool
}
