import helper from '../helper'

export const serviceProfile = {
  get: (token, params) => helper.get('api/v1/profile', token, params),
  update: (token, { data }) =>
    helper.patch('api/v1/profile', data, token),
  updatePassword: (token, { data }) =>
    helper.patch('api/v1/profile/change-password', data, token),
  updateEmail: (token, { data }) => helper.post('api/v1/profile/update-email', data, token),
  verifyEmail: (token, { data }) =>
    helper.patch('api/v1/profile/update-email', data, token)
}
