import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Form, Select, Tabs, Col } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { Forms } from 'src/components/organizes/Forms'
import { DefaultLayout } from 'src/layouts/default'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { PopUpFinished } from 'src/components/molecules/PopUpFinished'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import moment from 'moment'

const _UpdateCoupon = ({ token, dispatch, data, loading, popupSuccess }) => {
  const { TabPane } = Tabs
  // const { RangePicker } = DatePicker

  const [form] = Form.useForm()
  const [forms, setForms] = useState({})
  const { state } = useLocation()
  const navigation = useNavigate()

  useEffect(() => {
    dispatch({
      type: 'coupon/get_one',
      payload: {
        token,
        couponId: state.id
      }
    })
  }, [])
  useEffect(() => {
    data && setForms(data)
    const newData = {
      ...forms,
      startDate: moment(state.startDate).format('L'),
      endDate: moment(state.endDate).format('L')
    }

    form.setFieldsValue(newData)
  }, [forms, data])

  const onHandleSubmit = () => {
    form.validateFields().then((data) =>
      dispatch({
        type: 'sub_account/create_subaccount',
        payload: {
          token: state,
          data
        }
      })
    )
  }

  const onHandleNavigation = () => {
    navigation('/dashboard/sub-account')
  }

  const onHandleCreateNew = () => {
    form.resetFields()
    dispatch({
      type: 'sub_account/set_state',
      payload: { popupSuccess: false }
    })
  }
  const product = []
  // const [date, setDate] = useState('')

  return (
    <DefaultLayout>
      <Forms form={form} onFinish={onHandleSubmit} titleBar='Update Coupon'>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Choose Product'
            placeholder='Choose Product'
            name='productId'
            select
            rules={[rules.required]}
          >
            {product?.map((item, index) => (
              <Select.Option key={index} value={item?.id}>
                {item?.pending === true
                  ? item?.name + ' (pending)'
                  : item?.name}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            col={12}
            name='coupon'
            label='Coupon'
            placeholder='your coupon'
            rules={[rules.required]}
          />
        </Row>
        <Tabs defaultActiveKey='1' col={12}>
          <TabPane tab='Percent' key='1'>
            <Row gutter={16}>
              <FormItem
                col={4}
                label='Discount (%)'
                name='discountCoupon'
                placeholder='discount coupon'
                // prefix={<KeyOutlined />}
                rules={[rules.required]}
              />
              <FormItem
                col={12}
                label='Coupon Price'
                name='couponPrice'
                placeholder='your coupon price'
                disabled
                // prefix={<KeyOutlined />}
                rules={[rules.required]}
              />
              <Col span={8} style={{ textAlign: 'center', marginTop: '32px' }}>
                Rp 200000
              </Col>
            </Row>
          </TabPane>
          <TabPane tab='Price' key='2'>
            <Row gutter={16}>
              <FormItem
                col={4}
                label='Discount (Rp)'
                name='discountCoupon'
                placeholder='discount coupon'
                disabled
                // prefix={<KeyOutlined />}
                rules={[rules.required]}
              />
              <FormItem
                col={12}
                label='Campaign Price'
                name='couponPrice'
                placeholder='your coupon price'
                // dependencies={['password']}
                // prefix={<KeyOutlined />}
                // rules={[
                //   rules.required,
                //   ({ getFieldValue }) => ({
                //     validator (_, value) {
                //       if (!value || getFieldValue('password') === value) {
                //         return Promise.resolve()
                //       }
                //       return Promise.reject(
                //         new Error(
                //           'The two passwords that you entered do not match!'
                //         )
                //       )
                //     }
                //   })
                // ]}
              />
              <Col span={8} style={{ textAlign: 'center', marginTop: '32px' }}>
                Rp 200000
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Start Date'
            name='startDate'
            placeholder='start date'
            // prefix={<KeyOutlined />}
            rules={[rules.required]}
            date
          />
          <FormItem
            col={12}
            label='End Date'
            name='endDate'
            placeholder='end date'
            // prefix={<KeyOutlined />}
            rules={[rules.required]}
            date
          />
          {/* <RangePicker onChange={({ value }) => { setDate(value) }} /> */}
        </Row>
        {/* <Row gutter={16}>
          <FormItem
            name='email'
            label='Email'
            placeholder='your email'
            rules={[rules.required]}
            prefix={<MailOutlined />}
          />
        </Row> */}
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Submit
          </Button>
        </Row>
      </Forms>
      <PopUpFinished
        popupSuccess={popupSuccess}
        onClickCreateNew={() => onHandleCreateNew()}
        onClickCheckList={() => onHandleNavigation()}
        buttonRight='Check Sub Account'
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerCoupons }) => {
  return { ...reducerCoupons }
}

export const UpdateCoupon = connect(mapStateToProps)(_UpdateCoupon)

_UpdateCoupon.propTypes = {
  token: PropTypes.string,
  loading: PropTypes.bool,
  popupSuccess: PropTypes.bool,
  dispatch: PropTypes.func,
  data: PropTypes.object
}
