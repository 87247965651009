import React from 'react'
import { Menu, Popover } from 'antd'
import PropTypes from 'prop-types'
import {
  ToolOutlined,
  UserOutlined,
  BellOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

export const MPopover = ({ children }) => {
  const title = 'My Profile'
  const myProfile = '/dashboard/my-profile'
  const myNotification = '/dashboard/notification'
  return (
    <Popover
      placement='topLeft'
      overlayClassName='sidebar-popover'
      content={
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode='inline'
        >
          <Menu.Item icon={<UserOutlined />} key='profile'>
            <Link to={myProfile}>
              {title}
            </Link>
          </Menu.Item>
          <Menu.Item icon={<BellOutlined />} key='notificaition'>
            <Link to={myNotification}>
              Notification
            </Link>
          </Menu.Item>
          <Menu.Item icon={<ToolOutlined />} key='settings'>
            Settings
          </Menu.Item>
        </Menu>
      }
      trigger='click'
    >
      {children}
    </Popover>
  )
}

MPopover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
