/* eslint-disable no-unused-vars */
import { message } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/roles/actions'
import { serviceRoles } from 'src/services/api/roles'
import { servicePermissions } from 'src/services/api/permissions'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'roles/set_state',
    payload
  })
}

export function * getRoles ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceRoles.get, token, params)
  if (response.status === 200) {
    yield setState({
      loading: false,
      items: response.data.data.items ?? [],
      meta: response.data.data.meta ?? {}
    })
  } else yield load(false)
}

export function * createRoles ({ payload }) {
  yield load(true)
  const { token, data } = payload

  const response = yield call(serviceRoles.create, token, { data })

  if (response.status === 201) {
    yield put({
      type: 'roles/get_roles',
      payload: { token }
    })
    yield setState({
      popupVisible: false,
      loading: false
    })
    message.success('craeted role success!')
  } else yield load(false)
}

export function * updateRoles ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceRoles.update, token, { data, roleName: data.name })

  if (response.status === 200) {
    yield put({
      type: 'roles/get_roles',
      payload: {
        token
      }
    })
    message.success('updated roles success!')
  } else yield load(false)
}

export function * deleteRole ({ payload }) {
  yield load(true)
  const { roleName, token } = payload
  const response = yield call(serviceRoles.delete, token, { roleName })

  if (response.status === 200) {
    yield put({
      type: 'roles/get_roles',
      payload: { token }
    })
    message.success('deleted role success!')
  } yield load(false)
}

export function * getPermissions ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(servicePermissions.get, token, params)
  if (response.status === 200) {
    yield setState({
      loading: false,
      permissions: response.data?.data ?? []
    })
  } else yield load(false)
}

export function * createPermission ({ payload }) {
  yield load(true)
  const { token, data } = payload

  const response = yield call(servicePermissions.create, token, { data })

  if (response.status === 201) {
    yield put({
      type: 'roles/get_roles',
      payload: {
        token
      }
    })
    yield load(false)
    message.success('success created permission!')
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_roles, getRoles),
    takeEvery(actions.create_roles, createRoles),
    takeEvery(actions.update_roles, updateRoles),
    takeEvery(actions.delete_roles, deleteRole),
    takeEvery(actions.get_permission, getPermissions),
    takeEvery(actions.create_permission, createPermission)
  ])
}
