/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Button, Layout, Result, Space, Spin } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const _ValidateEmail = ({ dispatch, loading, message }) => {
  const navigation = useNavigate()
  const onHandleContinue = () => {
    document.location.href = '/login'
  }

  const location = useLocation()
  const token = location.pathname.split('/')[2]
  const email = location.pathname.split('/')[3]

  useEffect(() => {
    if (token && email) {
      dispatch({
        type: 'credentials/verify_email',
        payload: {
          token,
          email
        }
      })
    }
  }, [])

  return (
    <Layout className='container-common'>
      {
        loading
          ? <Spin size='large' />
          : <Result
              status='success'
              title='Verification Successfully'
              subTitle='please continue to sign in and get more information'
              extra={[
                <Button onClick={onHandleContinue} type='primary' key='console'>
                  Continue
                </Button>
              ]}
            />
      }
    </Layout>
  )
}

export const ValidateEmail = connect()(_ValidateEmail)

_ValidateEmail.propTypes = {
  dispatch: PropTypes.func,
  message: PropTypes.string,
  loading: PropTypes.bool
}
