import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceAdminAffiliate = {
  get: (token, params) => helper.get('api/v1/transaction-affiliates-admin', token, params),
  one: (token, id, params) => helper.get('api/v1/transaction-affiliates-admin/' + id, token, params),
  create: (token, id, { params }) => helper.post('api/v1/transaction-affiliates-admin/' + id + '/withdraw', params, token),
  patch: (token, id, { params }) => helper.patch('api/v1/transaction-affiliates-admin/' + id, params, token),
  delete: (token, { id }) =>
    axios.delete(apiBaseUrl + `api/v1/transaction-affiliates-admin/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
