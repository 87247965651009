/* eslint-disable no-undef */
import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Routes,
  BrowserRouter
} from 'react-router-dom'

import {
  routesAdmin,
  routesFreeMaster,
  routesMaster,
  routesSilverMaster,
  routeNoAccess,
  routeSubAccount
} from 'src/utils/routers'
import {
  PrivateRouter,
  ReverseRouter
} from 'src/middleware/credentials'
import { ForgotPassword } from 'src/pages/common/ForgotPassword'
import { ResetPassword } from 'src/pages/common/ResetPassword'
import { Login } from 'src/pages/common/Login'
import { Register } from 'src/pages/common/Register'
import { ValidateEmail } from 'src/pages/common/ValidateEmail'
import { ScanQR } from 'src/pages/common/ScanQR'
import { Redirect } from 'src/pages/common/Redirect'
import { Landing } from 'src/pages/common/Landing'
import { NotFound } from 'src/pages/common/NotFound'

const _Routers = () => {
  const token = localStorage.getItem('token')
  const role = localStorage.getItem('role')
  const subaccount = () =>
    localStorage?.getItem('subaccount') && true
      ? JSON.parse(localStorage?.getItem('subaccount'))
      : null

  const nothingAccess = !subaccount()?.accessProduct && !subaccount()?.accessGenerateQr && !subaccount()?.accessSettingGenerateQr

  const setRoutes = () => {
    switch (role) {
      case 'admin':
        return routesAdmin(token)
      case 'gold_master':
        return routesMaster(token)
      case 'free_master':
        return routesFreeMaster(token)
      case 'silver_master':
        return routesSilverMaster(token)
      case 'platinum_master':
        return routesMaster(token)
      case 'sub_account':
        return nothingAccess ? routeNoAccess() : routeSubAccount(token)
      default:
        return routesAdmin(token)
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {setRoutes()?.map((items, index) =>
          <Route
            key={index}
            path={items.path}
            element={
              <PrivateRouter>
                {items.component}
              </PrivateRouter>
          }
          />
        )}
        <Route path='/' element={<Landing token={token} />} />
        <Route path='/login' element={<ReverseRouter><Login /></ReverseRouter>} />
        <Route path='/forgot-password' element={<ReverseRouter><ForgotPassword /></ReverseRouter>} />
        <Route path='/reset-password' element={<ReverseRouter><ResetPassword /></ReverseRouter>} />
        <Route path='/register' element={<ReverseRouter><Register /></ReverseRouter>} />
        <Route path='/email-verify/:token/:email' element={<ValidateEmail />} />
        <Route path='/q/:qrid' element={<ScanQR token={token} />} />
        <Route path='/:sku_qrcode' element={<Redirect token={token} />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

const mapStateToProps = ({ reducerCredentials }) => {
  return { ...reducerCredentials }
}

export const Routers = connect(mapStateToProps)(_Routers)

_Routers.propTypes = {}
