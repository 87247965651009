/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Input, PageHeader, Row, Select, Dropdown, Button } from 'antd'
import { AButton } from 'src/components/atoms/Button'
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons'
import { Gap } from '../atoms/Gap'
import { TrafficFilter } from 'src/utils/dropdown/trafficFilter'

export const Header = ({
  prefix,
  simple,
  onClick,
  children,
  breadcrumb,
  onFilterSelect,
  onClickSearch,
  onClearFilter,
  onFilterType,
  onSelectDateF,
  onSelectDateT,
  placeholder,
  className,
  generate,
  onChange,
  disabled,
  title,
  type,
  icon,
  filter,
  data,
  search,
  traffic,
  trafficFilter,
  dateFrom,
  dateTo
}) => {
  const [visible, setVisible] = React.useState(false)
  const handleMenuClick = () => {
    setVisible(false)
  }
  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }
  // const roles = localStorage.getItem('role')
  // const username = localStorage.getItem('username')
  const input = (
    <Row gutter={12} align='middle' className='' style={{ width: '74rem' }}>
      {search
        ? (
          <>
            <Col span={7}>
              <Input
                allowClear
                onChange={onChange}
                placeholder={placeholder}
                prefix={<SearchOutlined /> || prefix}
              />
            </Col>
            <AButton
              type={type}
              title='Search'
              icon={<SearchOutlined />}
              onClick={onClickSearch}
              className='dashboard-btn'
            />
          </>
          )
        : null}
      <Gap width='1rem' />
      {filter && (
        <Row>
          <p>Filter by</p>
          <Gap width='.5rem' />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder='Select Product Name'
            optionFilterProp='children'
            onSelect={onFilterSelect}
            onClear={onClearFilter}
            allowClear
          >
            {data?.map((item, index) => (
              <Select.Option value={item.id} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Row>
      )}
      <Gap width='1rem' />
      {traffic && (
        <Row align='center' style={{ width: '100%' }}>
          <Dropdown trigger={['click']} onVisibleChange={handleVisibleChange} visible={visible} overlay={<TrafficFilter onFilterType={onFilterType} onSelectDateF={onSelectDateF} onSelectDateT={onSelectDateT} handleMenuClick={handleMenuClick} />}>
            <Button type='text' style={{ display: 'flex', alignItems: 'center' }}>
              <CalendarOutlined style={{ fontWeight: 'bold', fontSize: 16 }} />
              <Gap width='.5rem' />
              <b style={{
                textTransform: 'capitalize',
                fontWeight: 'bold'
              }}
              >{trafficFilter}
              </b>
              <Gap width='.5rem' />
              <p>{`(${dateFrom} ~ ${dateTo})`}</p>
            </Button>
          </Dropdown>
        </Row>
      )}
    </Row>
  )
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      ghost={false}
      className={className}
      onBack={simple || generate ? false : () => window.history.back()}
      title={
        simple
          ? input
          : generate || (
            <p
              style={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontSize: 14
              }}
            >
              Dashboard
            </p>
          )
      }
      extra={[
        children,
        <AButton
          disabled={disabled}
          key='button'
          icon={icon}
          type={type}
          onClick={onClick}
          title={title}
          className='dashboard-btn'
        />
      ]}
    />
  )
}

Header.propTypes = {
  simple: PropTypes.bool,
  className: PropTypes.string,
  generate: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  breadcrumb: PropTypes.string,
  onClearFilter: PropTypes.func,
  onFilterSelect: PropTypes.func,
  onFilterType: PropTypes.func,
  onSelectDateF: PropTypes.func,
  onSelectDateT: PropTypes.func,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  prefix: PropTypes.object,
  onClickSearch: PropTypes.func,
  filter: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  search: PropTypes.bool,
  traffic: PropTypes.bool,
  trafficFilter: PropTypes.string,
  dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}

Header.defaultProps = {
  search: true
}
