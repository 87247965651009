/* eslint-disable no-undef */
import React from 'react'
import { Button, Card, Layout, Result } from 'antd'
import { DefaultLayout } from 'src/layouts/default'

export const EmptyItems = () => {
  const role = localStorage.getItem('role')
  const onHandleLogout = () => {
    localStorage.clear()
    localStorage.removeItem('applicationState')
    window.location.replace('/login')
  }
  return (
    <DefaultLayout>
      <Layout.Content className='container-empty'>
        <Card>
          <Result
            status='404'
            title='Ups you dont have access for this page'
            subTitle={`Please contact your ${role === 'sub_account' ? 'account master' : 'admin'} for more details`}
            extra={<Button onClick={onHandleLogout} type='primary'>Logout</Button>}
          />
        </Card>
      </Layout.Content>
    </DefaultLayout>
  )
}
