import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceSettingQR = {
  get: (token, params) => helper.get('api/v1/qrcodes/settings', token, params),
  post: (token, { newData }) => helper.post('api/v1/qrcodes/settings', newData, token),
  uploadLogo: (token, { data, settingQrcodeId }) => helper.patch('api/v1/qrcodes/settings/' + settingQrcodeId + '/upload', data, token),
  update: (token, { settingQrcodeId, data }) => helper.patch('api/v1/qrcodes/settings/' + settingQrcodeId, data, token),
  delete: (token, { settingID }) =>
    axios.delete(apiBaseUrl + `api/v1/qrcodes/settings/${settingID}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
