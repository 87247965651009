import React from 'react'
import { connect } from 'react-redux'
import _data from 'src/data'

const PromoSection = () => {
  return (
    <>
      <section className='promo-section ptb-100' id='promo'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-8'>
              <div className='section-heading text-center mb-5'>
                <h2>{_data.promo.title}</h2>
                <p className='lead'>{_data.promo.description}</p>
              </div>
            </div>
          </div>
          <div className='row equal  justify-content-center'>
            {(_data?.promo.items || []).map(item => {
              return (
                <div className='col-md-4 col-lg-4 mb-3' key={item.title}>
                  <div className='single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100'>
                    <div className='circle-icon mb-5'>
                      <span className={item.icon} />
                    </div>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default connect(state => ({
  state
}))(PromoSection)
