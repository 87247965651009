export const productItem = [
  'Makanan',
  'Minuman',
  'Asuransi',
  'Digital',
  'Lainnya'
]

export const agentItem = [
  'Rudi Sanja',
  'Ridwansyah',
  'Umi Herdian',
  'Bagus Subagio',
  'Ahmad Surajdi',
  'Dahlan Iskandar'
]

export const typeQRItem = [
  '1 Product many QR',
  '1 Product 1 QR'
]

export const limitScanItem = [
  '1',
  '2',
  '3',
  '4',
  '5',
  'Unlimited',
  'Unlimited - unique id'
]

export const countQRItem = [
  '10',
  '50',
  '100',
  '200',
  '500',
  '1000',
  'Custom'
]

export const chooseQRItem = [
  '1 product many QR',
  '1 product 1 QR'
]
