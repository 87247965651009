import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Form, Select, Tabs } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { Forms } from 'src/components/organizes/Forms'
import { DefaultLayout } from 'src/layouts/default'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { PopUpFinished } from 'src/components/molecules/PopUpFinished'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

const _CreateCoupon = ({ token, dispatch, loading, popupSuccess, dataMaster, dataProduct }) => {
  const { TabPane } = Tabs
  // const { RangePicker } = DatePicker
  const [discountPercent, setDiscountPercent] = useState(null)
  const [discountPrice, setDiscountPrice] = useState(null)
  const [discountType, setDiscountType] = useState(1)

  const [form] = Form.useForm()
  const { state } = useLocation()
  const navigation = useNavigate()

  const onHandleSubmit = () => {
    form.validateFields().then((data) => {
      dispatch({
        type: 'coupon/create_coupon',
        payload: {
          token: state,
          data: {
            ...data,
            discount: discountType === 1 ? +discountPercent : discountPrice,
            discountType: discountType === 1 ? 'PERCENT' : 'PRICE'
          }
        }
      })
    }
    )
  }

  const onHandlePercent = (e) => {
    const discPercent = Number(e.target.value) > 100 ? 100 : Number(e.target.value)
    const discPrice = (dataProduct?.price / 100) * discPercent

    form.setFieldsValue({
      discountPrice: `Rp${Intl.NumberFormat('id').format(discPrice)},-`,
      discountPriceNumber: (dataProduct?.price / 100) * discountPercent
    })

    setDiscountPercent(discPercent)
    setDiscountPrice(discPrice)
  }

  const onHandlePrice = (e) => {
    const check = 100 / (dataProduct?.price / e.target.value)
    if (check >= 100) {
      setDiscountPrice(Number(dataProduct?.price))
      form.setFieldsValue({
        discountPrice: `Rp${Intl.NumberFormat('id').format((dataProduct?.price / 100) * 100)},-`,
        discountPriceNumber: Number(dataProduct?.price),
        discountPercent: (100)
      })
    } else {
      setDiscountPrice(Number(e.target.value))
      form.setFieldsValue({
        discountPercent: ((100 / (dataProduct?.price / e.target.value)).toFixed(2)),
        discountPrice: `Rp${Intl.NumberFormat('id').format(discountPrice)},-`
      })
    }
  }

  const onHandleNavigation = () => {
    navigation('/dashboard/coupon')
  }

  const onHandleCreateNew = () => {
    form.resetFields()
    dispatch({
      type: 'coupon/set_state',
      payload: { popupSuccess: false }
    })
  }

  const onSelectProduct = (productId) => {
    dispatch({
      type: 'product_master/get_one',
      payload: { token: token, productId: productId }
    })
  }

  useEffect(() => {
    dispatch({
      type: 'product_master/get_product_master',
      payload: { token }
    })
  }, [])

  useEffect(() => {
    if (dataProduct) {
      form.setFieldsValue({ productPrice: `Rp${Intl.NumberFormat('id').format(dataProduct.price)},-` })
    }
  }, [dataProduct])

  // const [date, setDate] = useState('')
  const callback = (key) => {
    setDiscountType(key)
  }

  return (
    <DefaultLayout>
      <Forms form={form} onFinish={onHandleSubmit} titleBar='Create Coupon'>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Choose Product'
            placeholder='Choose Product'
            name='productId'
            select
            onChange={value => { onSelectProduct(value) }}
            rules={[rules.required]}
          >
            {dataMaster?.map((item, index) => (
              <Select.Option key={index} value={item?.id}>
                {item?.pending === true
                  ? item?.name + ' (pending)'
                  : item?.name}
              </Select.Option>
            ))}
          </FormItem>
          <FormItem
            col={12}
            name='coupon'
            label='Coupon'
            placeholder='Your coupon'
            rules={[rules.required]}
          />
        </Row>
        <Tabs defaultActiveKey='1' col={12} onChange={callback}>
          <TabPane tab='Percent' key='1'>
            <Row gutter={16}>
              <FormItem
                col={4}
                label='Discount (%)'
                name='discountPercent'
                placeholder='Discount coupon'
                onChange={e => onHandlePercent(e)}
                disabled={!dataProduct}
              />
              <FormItem
                col={12}
                label='Discount (Rp)'
                name='discountPrice'
                placeholder='Your coupon price'
                disabled
              />
              <FormItem
                col={4}
                label='Product Price'
                name='productPrice'
                placeholder='Product price'
                disabled
              />
              <FormItem
                col={4}
                label='Fix Price'
                name='fixPrice'
                placeholder='Fix price'
                disabled
                value={form.setFieldsValue({ fixPrice: `Rp${Intl.NumberFormat('id').format(dataProduct && discountPrice ? dataProduct.price - discountPrice : 0)},-` })}
              />
              {/* <Col span={8} style={{ textAlign: 'center', marginTop: '32px' }}>
                Rp {dataProduct ? dataProduct.price : 0}
              </Col> */}
            </Row>
          </TabPane>
          <TabPane tab='Price' key='2'>
            <Row gutter={16}>
              <FormItem
                col={4}
                label='Discount (%)'
                name='discountPercent'
                placeholder='Discount coupon'
                disabled
              />
              <FormItem
                col={12}
                label='Discount (Rp)'
                name='discountPriceNumber'
                placeholder='Your coupon price'
                onChange={(e) => onHandlePrice(e)}
                disabled={!dataProduct}
              />
              <FormItem
                col={4}
                label='Product Price'
                name='productPrice'
                placeholder='Product price'
                value={0}
                disabled
              />
              <FormItem
                col={4}
                label='Fix Price'
                name='fixPrice'
                placeholder='Fix price'
                disabled
                value={form.setFieldsValue({ fixPrice: `Rp${Intl.NumberFormat('id').format(dataProduct && discountPrice ? dataProduct.price - discountPrice : 0)},-` })}
              />
            </Row>
          </TabPane>
        </Tabs>
        <Row gutter={16}>
          <FormItem
            col={12}
            label='Start Date'
            name='startDate'
            placeholder='Start date'
            // prefix={<KeyOutlined />}
            rules={[rules.required]}
            date
          />
          <FormItem
            col={12}
            label='End Date'
            name='endDate'
            placeholder='End date'
            // prefix={<KeyOutlined />}
            rules={[rules.required]}
            date
          />
          {/* <RangePicker onChange={({ value }) => { setDate(value) }} /> */}
        </Row>
        {/* <Row gutter={16}>
          <FormItem
            name='email'
            label='Email'
            placeholder='your email'
            rules={[rules.required]}
            prefix={<MailOutlined />}
          />
        </Row> */}
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Submit
          </Button>
        </Row>
      </Forms>
      <PopUpFinished
        popupSuccess={popupSuccess}
        onClickCreateNew={() => onHandleCreateNew()}
        onClickCheckList={() => onHandleNavigation()}
        buttonRight='Check Coupon'
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({
  reducerCoupons,
  reducerProductMaster
}) => {
  return {
    ...reducerCoupons,
    dataMaster: reducerProductMaster.productMaster,
    dataProduct: reducerProductMaster.data
  }
}

export const CreateCoupon = connect(mapStateToProps)(_CreateCoupon)

_CreateCoupon.propTypes = {
  token: PropTypes.string,
  loading: PropTypes.bool,
  popupSuccess: PropTypes.bool,
  dispatch: PropTypes.func,
  dataProduct: PropTypes.object,
  dataMaster: PropTypes.array
}
