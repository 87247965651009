/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/affiliate-admin/actions'
import { serviceAdminAffiliate } from 'src/services/api/transactionAffiliateAdmin'
import { message, notification } from 'antd'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'admin_affiliate/set_state',
    payload
  })
}

export function * get ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceAdminAffiliate.get, token, params)
  if (response.status === 200) {
    yield setState({
      data: response?.data?.data?.items,
      meta: response?.data?.data?.meta,
      loading: false
    })
  } else yield load(false)
}

export function * getOne ({ payload }) {
  yield load(true)
  const { token, id, params } = payload

  const response = yield call(serviceAdminAffiliate.one, token, id, params)
  if (response.status === 200) {
    yield setState({
      data: response?.data?.data?.items,
      meta: response?.data?.data?.meta,
      loading: false
    })
  } else yield load(false)
}

export function * create ({ payload }) {
  yield load(true)
  const { token, id, body, page } = payload

  const response = yield call(serviceAdminAffiliate.create, token, id, { params: JSON.stringify(body) })
  if (response.status === 202) {
    yield setState({
      loading: false,
      popupSuccess: true
    })
    yield put({
      type: 'admin_affiliate/get_affiliates',
      payload: {
        token,
        params: { page }
      }
    })
  } else {
    yield load(false)
    message.error(response.data?.message)
  }
}

export function * update ({ payload }) {
  yield load(true)
  const { token, id, body, affiliateId } = payload

  const response = yield call(serviceAdminAffiliate.patch, token, id, { params: JSON.stringify(body) })
  if (response.status === 202) {
    yield setState({
      loading: false,
      popupSuccess: true
    })
    yield put({
      type: 'admin_affiliate/get_one',
      payload: {
        token,
        id: affiliateId
      }
    })
  } else {
    yield load(false)
    message.error(response.data?.message)
  }
}

export function * deleteData ({ payload }) {
  yield load(true)
  const { token, id } = payload

  const response = yield call(serviceAdminAffiliate.delete, token, { id })

  if (response.status === 202) {
    notification.warning({
      message: 'Delete Withdraw Success!',
      description: 'Withdraw remove for permanent'
    })
    window.location.reload()
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_affiliates, get),
    takeEvery(actions.get_one, getOne),
    takeEvery(actions.update_affiliates, update),
    takeEvery(actions.create_affiliates, create),
    takeEvery(actions.delete_affiliates, deleteData)
  ])
}
