import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/topup/actions'
import { serviceTopUp } from '../../services/api/topup'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'topup/set_state',
    payload
  })
}

export function * getPrices ({ payload }) {
  yield load(true)
  const { token } = payload

  const response = yield call(serviceTopUp.get, token)
  if (response.status === 200) {
    yield setState({
      type: 'topup/get_prices',
      data: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * topUp ({ payload }) {
  yield load(true)
  const { token, role } = payload

  const response = yield call(serviceTopUp.post, token, role)
  if (response.status === 201) {
    yield setState({
      type: 'topup/topup',
      loading: false,
      success: response.data
    })
  } else yield setState({ loading: false })
}

export function * getTransaction ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceTopUp.getTransaction, token, params)
  if (response.status === 200) {
    yield setState({
      type: 'topup/get_transaction',
      history: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_prices, getPrices),
    takeEvery(actions.top_up, topUp),
    takeEvery(actions.get_transaction, getTransaction)
  ])
}
