import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  Popconfirm,
  // Pagination,
  Button,
  Layout,
  Avatar,
  Image,
  Space,
  Table,
  // Row,
  Switch
} from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'

const { Content } = Layout

export const _SubAccount = ({ dispatch, token, data, loading, meta }) => {
  const [value, setValue] = useState('')
  const [role, setRole] = useState('')
  // console.log(data);

  const navigation = useNavigate()
  const text = 'Are you sure to delete this sub account?'

  useEffect(() => {
    setDispatch({
      token,
      params: { role }
    })
  }, [role])

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      fixed: 'left',
      width: 180,
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Name',
      width: 130,
      dataIndex: 'username'
    },
    {
      title: 'Email',
      width: 180,
      dataIndex: 'email'
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      width: 100,
      align: 'center',
      render: () => (
        <Avatar
          src={
            <Image
              preview={false}
              src='https://joeschmoe.io/api/v1/random'
              style={{ width: 32 }}
            />
          }
        />
      )
    },
    {
      title: 'Access Product',
      align: 'center',
      dataIndex: 'accessProduct',
      render: (item, obj) => (
        <Switch
          checked={obj.subAccountSetting?.accessProduct}
          checkedChildren='On'
          onChange={(value) => onHandleChecked(value, 'accessProduct', obj.id)}
          unCheckedChildren='Off'
        />
      )
    },
    {
      title: 'Access Setting QR',
      align: 'center',
      dataIndex: 'accessSettingGenerateQr',
      render: (item, obj) => (
        <Switch
          checked={obj.subAccountSetting?.accessSettingGenerateQr}
          onChange={(value) => onHandleChecked(value, 'accessSettingGenerateQr', obj.id)}
          checkedChildren='On'
          unCheckedChildren='Off'
        />
      )
    },
    {
      title: 'Access Generate QR',
      align: 'center',
      dataIndex: 'accessGenerateQr',
      render: (item, obj) => (
        <Switch
          checked={obj.subAccountSetting?.accessGenerateQr}
          checkedChildren='On'
          onChange={(value) => onHandleChecked(value, 'accessGenerateQr', obj.id)}
          unCheckedChildren='Off'
        />
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      fixed: 'right',
      align: 'center',
      render: (file, obj) => (
        <Space>
          <Popconfirm
            placement='topLeft'
            title={text}
            onConfirm={() => onHandleDelete(obj?.id)}
            okText='Yes'
            cancelText='No'
          >
            <Button
              size='small'
              className='button-delete'
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  const onHandleSearch = () => {
    setDispatch({
      token,
      params: {
        name: value
      }
    })
  }

  const setDispatch = (payload) => {
    return dispatch({
      type: 'sub_account/get_subaccount',
      payload
    })
  }

  const onHandleCreate = () => {
    navigation('/dashboard/sub-account/create', { state: token })
  }

  const onFilterSelect = (value) => {
    setRole(value)
  }

  const onClearFilter = (value) => {
    if (value === undefined) setRole('')
  }

  const onHandleDelete = (subAccountId) => {
    dispatch({
      type: 'sub_account/delete_subaccount',
      payload: {
        token, subAccountId
      }
    })
  }

  const onHandleChecked = (value, type, subAccountId) => {
    dispatch({
      type: 'sub_account/access_subaccount',
      payload: {
        token,
        subAccountId,
        value,
        checked: type,
        data: { [type]: value }
      }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    setDispatch({
      token, params: { page: page }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Header
            simple
            type='primary'
            title='Create Sub Account'
            placeholder='search by username or fullname'
            icon={<PlusCircleOutlined />}
            onClick={onHandleCreate}
            onFilterSelect={onFilterSelect}
            onClickSearch={onHandleSearch}
            onClearFilter={onClearFilter}
            onChange={({ target }) =>
              setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            rowKey='id'
            scroll={{ x: 1500 }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
          {/* <Row justify='end'>
            <Pagination
              defaultCurrent={pagination.page}
              total={meta?.totalItems}
              pageSize={pagination.perPage}
              onChange={(page, pageSize) =>
                onHandlePagination(page, pageSize)}
            />
          </Row> */}
          <Gap height='2rem' />
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerSubAccount }) => {
  return { ...reducerSubAccount }
}

export const SubAccount = connect(mapStateToProps)(_SubAccount)

_SubAccount.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  meta: PropTypes.object
}
