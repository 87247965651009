import { actions } from './actions'

const initialState = {
  loading: false
}

export const reducerSubAccount = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
