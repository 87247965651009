/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from 'src/redux/setting-qr/actions'
import { serviceSettingQR } from 'src/services/api/settingQR'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'setting_qr/set_state',
    payload
  })
}

export function * getSettingQR ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceSettingQR.get, token, params)
  if (response.status === 200) {
    let datas = response.data.data.items
    datas = datas.map((data) => {
      const size = data.size
      switch (size) {
        case 'SMALL':
          data.size = 'A4 : QR Code Size 10x10mm'
          break

        case 'A4_1_FOR_1_PAGE_QR_20x20':
          data.size = 'A4 : 1 QR Code for 1 Page - QR Code Size 20x20mm'
          break

        case 'A4_1_FOR_1_PAGE_QR_30x30':
          data.size = 'A4 : 1 QR Code for 1 Page - QR Code Size 30x30mm'
          break

        case 'A4_1_FOR_1_PAGE_QR_40x40':
          data.size = 'A4 : 1 QR Code for 1 Page - QR Code Size 30x30mm'
          break

        case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
          data.size = 'A4 : 1 QR Code for 1 Page - QR Code Size 20x20mm with Text'
          break

        case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
          data.size = 'A4 : 1 QR Code for 1 Page - QR Code Size 40x40mm with Text'
          break

        case 'MEDIUM':
          data.size = 'A3 : QR Code SIze 20x20mm with Text'
          break

        case 'LARGE':
          data.size = 'A3 : QR Code Size 20x20mm'
          break

        case 'A3_10x10':
          data.size = 'A3 : QR Code Size 10x10mm'
          break

        default:
          break
      }
      return data
    })
    yield setState({
      data: datas,
      meta: response.data.data.meta,
      loading: false
    })
  } else yield load(false)
}

export function * createSettingQR ({ payload }) {
  yield load(true)
  const { token, data } = payload

  const newData = new FormData()
  newData.append('logo', data.logo.fileList[0].originFileObj)
  newData.append('name', data.name)
  newData.append('size', data.size)
  newData.append('textInQrcode', data.textInQrcode)
  data?.type === 'CUSTOM' && newData.append('urlScanPage', data.url)

  const response = yield call(serviceSettingQR.post, token, { newData })

  if (response.status === 201) {
    yield put({
      type: 'setting_qr/get_setting_qr',
      payload: { token }
    })
    message.success('setting-qr success created!')
  } else {
    yield load(false)
    let msg = response.data.message
    msg = msg.map((text) => {
      return text.replace('textInQrcode', 'Custom Text')
    })
    notification.error({
      message: 'Update Setting QR Failed!!',
      description: msg
    })
  }
}

export function * updateSettingQR ({ payload }) {
  yield load(true)
  const { token, data, settingQrcodeId } = payload
  const response = yield call(serviceSettingQR.update, token, { settingQrcodeId, data })
  if (response.status === 202) {
    yield put({
      type: 'setting_qr/get_setting_qr',
      payload: { token }
    })
    notification.success({
      message: 'Update Form QR Success!',
      description: 'Please check list table more details'
    })
  } else {
    yield load(false)
    let msg = response.data.message
    msg = msg.map((text) => {
      return text.replace('textInQrcode', 'Custom Text')
    })
    notification.error({
      message: 'Update Setting QR Failed!!',
      description: msg
    })
  }
}

export function * updateLogoSettingQR ({ payload }) {
  yield load(true)
  const { token, logo, settingQrcodeId } = payload
  const newForm = new FormData()
  newForm.append('logo', logo)

  const response = yield call(serviceSettingQR.uploadLogo, token, { settingQrcodeId, data: newForm })
  if (response.status === 202) {
    yield put({
      type: 'setting_qr/get_setting_qr',
      payload: { token }
    })
    notification.success({
      message: 'Update Logo QR Success!',
      description: 'Please check list table more details'
    })
  } else {
    yield load(false)
    notification.error({
      message: 'Update Logo QR Failed!!',
      description: response.data.message
    })
  }
}

export function * deleteSettingQR ({ payload }) {
  yield load(true)
  const { token, settingID } = payload

  const response = yield call(serviceSettingQR.delete, token, { settingID })

  if (response.status === 200) {
    yield put({
      type: 'setting_qr/get_setting_qr',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Setting QR Success!',
      description: 'Setting QR delete for permanent'
    })
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_setting_qr, getSettingQR),
    takeEvery(actions.update_setting_qr, updateSettingQR),
    takeEvery(actions.upload_setting_qr, updateLogoSettingQR),
    takeEvery(actions.delete_setting_qr, deleteSettingQR),
    takeEvery(actions.create_setting_qr, createSettingQR)
  ])
}
