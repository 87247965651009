import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Modal,
  Row,
  Select,
  Upload
} from 'antd'

import {
  ToolOutlined,
  CameraOutlined
} from '@ant-design/icons'

import { AButton } from 'src/components/atoms/Button'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'

import settingData from './settingData.json'

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const CreateSettingQR = ({ onFinish, loading, form, visible, onCancel }) => {
  const [select, setSelect] = React.useState('')
  const [size, setSize] = React.useState('')

  const onSubmit = async () => {
    await onFinish()
    setSelect('')
    setSize('')
  }

  const close = () => {
    onCancel()
    setSelect('')
    setSize('')
  }

  const withCustomText = (size) => {
    switch (size) {
      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        return true
      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        return true
      case 'MEDIUM':
        return true
      case 'A0_10x10_TEXT':
        return true
      case 'A4_10x10_TEXT':
        return true
      default:
        return false
    }
  }

  React.useEffect(() => {
    if (size === 'MEDIUM') {
      const newData = {
        ...form,
        textInQrcode: 'SCAN QR CODE INI UNTUK KEASLIAN PRODUK'
      }

      form.setFieldsValue(newData)
    }
  }, [size])

  return (
    <Modal
      title='Create Setting QR '
      visible={visible}
      footer={false}
      onCancel={close}
      wrapClassName='modal-setting-qr'
    >
      <Form form={form} onFinish={onSubmit} layout='vertical'>
        <Form.Item
          name='logo'
          label='Upload Logo'
          rules={[rules.required]}
        >
          <Upload
            customRequest={dummyRequest}
            listType='picture-card'
            maxCount={1}
          >
            <CameraOutlined />
          </Upload>
        </Form.Item>
        <FormItem
          prefix={<ToolOutlined />}
          name='name'
          label='Setting Name'
          placeholder='Setting name'
        />
        <FormItem
          name='size'
          label='Choose size QR'
          placeholder='Setting size QR'
          select
          onChange={(e) => setSize(e)}
        >
          {settingData.map((setting) => (
            <Select.Option key={setting.size}>{setting.label}</Select.Option>
          ))}
        </FormItem>
        <FormItem
          name='type'
          label='Choose Scan Page'
          placeholder='Setting scan page'
          select
          onChange={(e) => setSelect(e)}
        >
          <Select.Option key='DEFAULT'>Default</Select.Option>
          <Select.Option key='CUSTOM'>Custom Page</Select.Option>
        </FormItem>
        {select === 'CUSTOM' &&
          <FormItem
            name='url'
            label='Custom URL Page'
            placeholder='example: https://rifus.id/product-baju-example'
            rules={[rules.required]}
          />}
        {withCustomText(size) && (
          <FormItem
            name='textInQrcode'
            label='Custom Text Scan QR Code '
            placeholder='SCAN QR CODE INI UNTUK KEASLIAN PRODUK'
            value='SCAN QR CODE INI UNTUK KEASLIAN PRODUK'
            textarea
          />
        )}
        <Row justify='end'>
          <AButton loading={loading} type='primary' title='Submit' />
        </Row>
      </Form>
    </Modal>
  )
}

CreateSettingQR.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  loading: PropTypes.bool,
  form: PropTypes.object
}
