import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import moment from 'moment'
import {
  Button,
  Layout,
  Popconfirm,
  Space,
  Table,
  Avatar,
  Modal,
  Card,
  Row,
  Col
} from 'antd'
import {
  // ExperimentTwoTone,
  PlusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { UploadCertificates } from './UploadCertificates'
import { SettingProduct } from './SettingProduct'

import iconHerbal from 'src/assets/images/icon-herbal.png'
import iconFood from 'src/assets/images/icon-food.svg'
import iconFashion from 'src/assets/images/icon-fashion.png'

const _ProductMaster = ({
  loading,
  dispatch,
  productMaster,
  meta,
  close,
  token
}) => {
  const navigation = useNavigate()
  const [value, setValue] = useState('')

  const text = 'Are you sure to delete this task?'
  const [updateItems, setUpdateItems] = useState({
    visible: false,
    productId: null
  })
  const [visibleProduct, setVisibleProduct] = useState(false)
  const [setting, setSetting] = useState({
    visible: false,
    productId: null
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      width: 200
    },
    {
      title: 'SKU',
      width: 150,
      align: 'center',
      dataIndex: 'sku'
    },
    {
      title: 'Price',
      align: 'center',
      width: 110,
      dataIndex: 'price'
    },
    {
      title: 'Production Date',
      width: 130,
      align: 'center',
      dataIndex: 'productionDate',
      render: (date, obj) => moment(date).format('L')
    },
    {
      title: 'Expired Date',
      width: 150,
      dataIndex: 'expiredAt',
      render: (date, obj) => moment(date).format('L')
    },
    {
      title: 'Distributed by',
      width: 150,
      align: 'center',
      dataIndex: 'distributedBy'
    },
    {
      title: 'Status',
      width: 150,
      align: 'center',
      dataIndex: 'pending',
      render: (item, obj) => !item ? <CheckCircleTwoTone style={{ fontSize: 24 }} /> : <ClockCircleTwoTone style={{ fontSize: 24 }} />
    },
    {
      title: 'Images Product',
      width: 150,
      align: 'center',
      dataIndex: 'images',
      render: () => <Avatar shape='square' />
    },
    {
      title: 'Produced by',
      width: 150,
      dataIndex: 'producedBy'
    },
    {
      title: 'Category',
      width: 150,
      dataIndex: 'category'
    },
    {
      title: 'Licenses',
      width: 150,
      align: 'center',
      render: () => <Button type='primary'>Detail</Button>
    },
    {
      title: 'Short Description',
      width: 250,
      dataIndex: 'shortDescription',
      render: (text, obj) => text.length > 50 ? text.substr(0, 50) + '...' : text
    },
    {
      title: 'Action',
      fixed: 'right',
      dataIndex: 'action',
      width: 410,
      align: 'center',
      render: (txt, obj) => (
        <Space>
          <Button
            size='small'
            className='button-upload dashboard-btn d-flex align-items-center'
            onClick={() => setSetting({ productId: obj.id, visible: true })}
          >
            GTM & PIXEL
          </Button>
          <Button
            size='small'
            className='button-upload d-flex align-items-center'
            onClick={() => setUpdateItems({ productId: obj.id, visible: true })}
          >
            <UploadOutlined /> Certificates
          </Button>
          <Button
            size='small'
            className='button-detail d-flex align-items-center'
            onClick={() => onHandlePreview(obj)}
          >
            <EyeOutlined />
          </Button>
          <Button
            size='small'
            className='button-update d-flex align-items-center'
            onClick={() => onHandleUpdate(obj)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement='topLeft'
            title={text}
            okText='Yes'
            cancelText='No'
            onConfirm={() => onHandleDeleteProduct(obj)}
          >
            <Button size='small' className='button-delete'>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  const category = [
    {
      name: 'Herbals',
      category: 'herbal',
      icon: (<img src={iconHerbal} width={48} />)
    },
    {
      name: 'Makanan',
      category: 'food',
      icon: (<img src={iconFood} width={48} />)
    },
    {
      name: 'Fashion',
      category: 'fashion',
      icon: (<img src={iconFashion} width={48} />)
    }
  ]

  useEffect(() => {
    if (close) {
      setUpdateItems({ visible: false })
    }
  }, [close])

  useEffect(() => {
    dispatch({
      type: 'product_master/get_product_master',
      payload: { token }
    })
  }, [])

  const onHandleSearch = () => {
    dispatch({
      type: 'product_master/get_product_master',
      payload: { token, params: { name: value } }
    })
  }

  const onHandleCreate = (category) => {
    navigation('/dashboard/product/create', { state: category })
  }

  const onHandleUpdate = (items) => {
    navigation(`/dashboard/product/${items.id}/update`, { state: items })
  }

  const onHandlePreview = (items) => {
    // console.log(items)
    navigation(`/dashboard/product/${items.id}/detail`, { state: items })
  }

  const onHandleDeleteProduct = (items) => {
    dispatch({
      type: 'product_master/delete_product_master',
      payload: { token, productId: items.id }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'product_master/get_product_master',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-products'>
        <Layout.Content className='container'>
          <Header
            simple
            type='primary'
            placeholder='search by type or name product'
            title='Create Product'
            onClickSearch={onHandleSearch}
            icon={<PlusCircleOutlined />}
            onClick={() => setVisibleProduct(!visibleProduct)}
            onChange={({ target }) => setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            rowKey='id'
            columns={columns}
            loading={loading}
            dataSource={productMaster}
            scroll={{ x: 1500 }}
            rowSelection={{
              type: 'checkbox'
            }}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
          />
          <Gap height='2rem' />
        </Layout.Content>
      </Layout>

      <Modal
        footer={false}
        visible={visibleProduct}
        title='Product Type'
        onCancel={() => setVisibleProduct(!visibleProduct)}
      >
        <Row gutter={16} justify='center'>
          {
            category.map((item, index) => (
              <Col md={8} key={index}>
                <Card
                  hoverable
                  onClick={() => onHandleCreate(item.category)}
                  style={{ textAlign: 'center' }}
                >
                  {item.icon}
                  <br />
                  {item.name}
                </Card>
              </Col>
            ))
          }
        </Row>
      </Modal>
      <SettingProduct
        token={token}
        productId={setting.productId}
        onCancel={() => setSetting({ visible: false })}
        visible={setting.visible}
      />
      <UploadCertificates
        token={token}
        productId={updateItems.productId}
        onCancel={() => setUpdateItems({ visible: false })}
        visible={updateItems.visible}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerProductMaster }) => {
  return { ...reducerProductMaster }
}

export const ProductMaster = connect(mapStateToProps)(_ProductMaster)

_ProductMaster.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  close: PropTypes.bool,
  productMaster: PropTypes.array,
  loading: PropTypes.bool,
  meta: PropTypes.object
}
