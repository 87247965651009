import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Layout,
  Table,
  Select,
  DatePicker,
  Card,
  Space,
  Button,
  Popconfirm
} from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { UpdateWithdraw } from './UpdateWithdraw'
import { useLocation } from 'react-router-dom'
import { Content } from 'antd/lib/layout/layout'
import { connect } from 'react-redux'
import moment from 'moment'
import './style.css'

export const _PreviewWithdraw = ({ meta, reset, loading, token, data, dispatch }) => {
  const { state } = useLocation()
  const { Text } = Typography

  const [update, setUpdate] = useState({
    visible: false,
    items: null
  })
  const text = 'Are you sure to delete this data?'
  // const customVal = value?.length <= 0 ? '' : search

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: (text) => <p>{text ?? '-'}</p>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => <p>{email ?? '-'}</p>
    },
    {
      title: 'Aksi',
      dataIndex: 'action',
      render: (action) =>
        <p>
          {action === 'NEW_REFERRAL_REGISTERED' && 'Mendaftar'}
          {action === 'REFERRAL_TOPUP_BONUS' && 'Top Up'}
          {action === 'WITHDRAW' && 'Penarikan'}
        </p>
    },
    {
      title: 'Tanggal, Jam',
      dataIndex: 'createdAt',
      render: (date) =>
        <p>
          {moment(date).format('DD-MM-YYYY, HH:mm')} WIB
        </p>
    },
    {
      title: 'Catatan',
      dataIndex: 'description',
      render: (description) => <p>{description ?? '-'}</p>
    },
    {
      title: 'Nominal',
      align: 'right',
      dataIndex: 'nominal',
      render: (item, obj) => {
        return (
          <Text className={obj.action === 'WITHDRAW' ? 'withdraw' : 'income'}>
            Rp{Intl.NumberFormat('id').format(Math.abs(item))}
          </Text>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (txt, obj) => (
        <>
          {obj.action === 'WITHDRAW'
            ? (
              <Space>
                <Button
                  size='small'
                  onClick={() => onHandleVisibleUpdate(obj)}
                  className='button-update'
                >
                  <EditOutlined />
                </Button>
                <Popconfirm
                  placement='topLeft'
                  title={text}
                  okText='Yes'
                  onConfirm={() => onHandleDelete(obj)}
                  cancelText='No'
                >
                  <Button size='small' className='button-delete'>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Space>)
            : '-'}
        </>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'admin_affiliate/get_one',
      payload: {
        token,
        id: state?.id
      }
    })
  }, [])

  const onHandleDelete = ({ id }) => {
    dispatch({
      type: 'admin_affiliate/delete_affiliates',
      payload: {
        token,
        id
      }
    })
    // console.log(id)
  }

  const onHandleVisibleUpdate = (items) => {
    setUpdate({
      visible: true,
      items
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'admin_affiliate/get_one',
      payload: { token, id: state?.id, params: { page: page } }
    })
  }

  const onHandleDate = (event) => {
    if (event) {
      const date = moment(event).format('YYYY-MM')
      const endDate = moment(date).endOf('month')

      dispatch({
        type: 'admin_affiliate/get_one',
        payload: {
          token,
          id: state?.id,
          params: {
            dateFrom: moment(date).format('YYYY-MM-DD'),
            dateTo: moment(endDate).format('YYYY-MM-DD')
          }
        }
      })
    } else {
      dispatch({
        type: 'admin_affiliate/get_one',
        payload: {
          token,
          id: state?.id
        }
      })
    }
  }

  const onFilterSelect = (val) => {
    dispatch({
      type: 'admin_affiliate/get_one',
      payload: { token, id: state?.id, params: { action: val } }
    })
  }

  const onClearFilter = (page) => {
    dispatch({
      type: 'admin_affiliate/get_one',
      payload: { token, id: state?.id, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Card>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <DatePicker
                onChange={onHandleDate}
                picker='month'
                style={{ width: 200 }}
                disabledDate={(current) => {
                  return moment() <= current
                }}
              />

              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Filter aksi'
                optionFilterProp='children'
                onSelect={onFilterSelect}
                onClear={onClearFilter}
                allowClear
              >
                <Select.Option value='REFERRAL_TOPUP_BONUS'>
                  Top Up
                </Select.Option>
                <Select.Option value='NEW_REFERRAL_REGISTERED'>
                  Registrasi
                </Select.Option>
                <Select.Option value='WITHDRAW'>
                  Penarikan
                </Select.Option>
              </Select>
            </div>
          </Card>
          <Gap height='1rem' />
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={data ?? []}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
        </Content>
      </Layout>
      <UpdateWithdraw
        visible={update.visible}
        token={token}
        onCancel={() => setUpdate({ ...update, visible: false })}
        items={update.items}
        loading={loading}
        balance={state?.balance}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerAdminAffiliates }) => {
  return { ...reducerAdminAffiliates }
}

export const PreviewWithdraw = connect(mapStateToProps)(_PreviewWithdraw)

_PreviewWithdraw.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  meta: PropTypes.object,
  reset: PropTypes.bool
}
