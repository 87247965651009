/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/traffics/actions'
import { serviceTraffics } from 'src/services/api/traffics'
// import { message } from 'antd'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'traffics/set_state',
    payload
  })
}

export function * getTraffics ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceTraffics.get, token, params)

  if (response.status === 200) {
    yield setState({
      dataTraffics: response?.data?.data,
      meta: response?.data?.data?.meta,
      loading: false
    })
  } else yield load(false)
}

export function * downloadTraffics ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceTraffics.download, token, params)

  if (response.status === 200) {
    yield setState({
      dataDownload: response?.data,
      loading: false
    })
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_traffics, getTraffics),
    takeEvery(actions.download_traffics, downloadTraffics)
  ])
}
