import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

export const _PreviewMap = ({ detailPrint, visible, onClose }) => {
  return (
    <Modal
      visible={visible}
      footer={false}
      onCancel={onClose}
      width={800}
      closable={false}
      centered
    >
      <div className='d-flex mb-2' style={{ alignItems: 'center' }}>
        <ArrowLeftOutlined style={{ fontSize: 16, cursor: 'pointer' }} onClick={onClose} />
        <div className='ml-3'>
          <h2 className='address-title'>Location Point</h2>
          <div style={{ fontSize: 12 }}>
            {detailPrint?.address.address}
            <br />
            Latitude: {detailPrint?.address?.latitude}
            <br />
            Longititude: {detailPrint?.address?.longititude}
          </div>

        </div>
      </div>

      <MapContainer center={[detailPrint?.address?.latitude ?? -7.932869927992499, detailPrint?.address?.longititude ?? 110.43418769657211]} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={[detailPrint?.address.latitude, detailPrint?.address.longititude]}>
          <Popup>Point is here</Popup>
        </Marker>
      </MapContainer>
    </Modal>
  )
}

const mapStateToProps = ({ reducerPrintRequest }) => {
  return { ...reducerPrintRequest }
}

export const PreviewMap = connect(mapStateToProps)(_PreviewMap)

_PreviewMap.propTypes = {
  detailPrint: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  visible: PropTypes.bool,
  onClose: PropTypes.func
}
