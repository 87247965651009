/* eslint-disable no-unused-vars */
import { message } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/users/actions'
import { serviceUsers } from 'src/services/api/users'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'users/set_state',
    payload
  })
}

export function * getUsers ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceUsers.get, token, params)

  if (response.status === 200) {
    yield setState({
      loading: false,
      items: response.data.data.items ?? [],
      meta: response.data.data.meta ?? {}
    })
  } else yield load(false)
}

export function * deleteUser ({ payload }) {
  yield load(true)
  const { token, userId } = payload
  const response = yield call(serviceUsers.delete, token, { userId })

  if (response.status === 202) {
    yield put({
      type: 'users/get_users',
      payload: { token }
    })
    message.success('Deleted Success!')
  } else yield load(false)
}

export function * createUser ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceUsers.create, token, { data })

  if (response.status === 201) {
    yield setState({
      loading: false,
      popupSuccess: true
    })
  } else {
    yield load(false)
    const description = response.data.error === 'Conflict'
      ? response.data.message
      : response.data.message.length === 1
        ? response.data.message
        : 'please check your fields'

    message.error(description)
  }
}

export function * updateUser ({ payload }) {
  yield load(true)
  const { token, userId, data } = payload
  const response = yield call(serviceUsers.update, token, { data, userId })

  if (response.status === 202) {
    yield load(false)
    message.success('Update user success!')
  } else {
    yield load(false)
    message.error(response.data.message)
  }
}

export function * addCredit ({ payload }) {
  yield load(true)
  const { token, userId, amount, price } = payload
  const response = yield call(serviceUsers.credit, token, { userId, amount, price })

  if (response.status === 201) {
    yield put({
      type: 'users/get_users',
      payload: { token }
    })
    message.success('add credit success!')
  } else {
    yield load(false)
    message.error('amount must be a number')
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_users, getUsers),
    takeEvery(actions.add_credit, addCredit),
    takeEvery(actions.create_users, createUser),
    takeEvery(actions.update_users, updateUser),
    takeEvery(actions.delete_users, deleteUser)
  ])
}
