import React, { forwardRef } from 'react'
import { PropTypes } from 'prop-types'
import styles from 'src/pages/generates-qr/styles/a4-1of1-qr-20x20-text.module.css'

const PrintPage = forwardRef((props, ref) => {
  const page = Array.from({ length: parseInt(Math.ceil(props?.data?.length / 1)) }, (x, i) => i * 1)

  const mapItem = (page) => {
    return (
      <div className={styles.page}>
        {
          props?.data?.map((item, index) => {
            index++
            return (index > page && index < page + 2) && (
              <div className={styles.container}>
                <div className={styles.container_qr_code}>
                  <div className={styles.qr_code_location}>
                    <img src={item.url} alt={item?.uniqueCode} />
                  </div>
                  <div className={styles.text_location}>
                    {props?.textInQrcode}
                  </div>
                </div>
                <div className={styles.unique_code_location}>
                  <span>{item?.uniqueCode}</span>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
  return (
    <div ref={ref} className={styles.paper} id='paper'>
      {
        page?.map((item, index) => {
          return mapItem(item)
        })
      }
    </div>
  )
})

export default PrintPage

PrintPage.propTypes = {
  data: PropTypes.array,
  textInQrcode: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string])
}
