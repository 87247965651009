import { actions } from './actions'

const initialState = {
  gtmProduct: null,
  loading: false,
  popupSuccess: false
}

export const reducerProductGtm = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
