/* eslint-disable no-undef */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/facebook-pixel/product/actions'
import { serviceProductPixel } from 'src/services/api/productPixel'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'product_pixel/set_state',
    payload
  })
}

export function * getProductPixel ({ payload }) {
  yield load(true)
  const { token, productId } = payload

  const response = yield call(serviceProductPixel.get, token, productId)
  if (response.status === 200) {
    yield setState({
      productPixel: response.data.data,
      meta: response.data.data.meta,
      loading: false
    })
  } yield load(false)
}

export function * getProductPixelQR ({ payload }) {
  yield load(true)
  const { qrcodeId } = payload

  const response = yield call(serviceProductPixel.getQR, qrcodeId)
  if (response.status === 200) {
    yield setState({
      productPixel: response.data.data,
      meta: response.data.data.meta,
      loading: false
    })
  } yield load(false)
}

export function * createProductPixel ({ payload }) {
  yield load(true)
  const { token, data, productId } = payload

  const response = yield call(serviceProductPixel.create, token, data, productId)
  if (response.status === 202) {
    yield setState({
      loading: false,
      productPixel: response.data.data,
      popupSuccess: true
    })
    message.success('Created success')
  } else {
    yield load(false)
    message.error('created failed! check your field')
  }
}

export function * deleteProductPixel ({ payload }) {
  yield load(true)
  const { token, productId } = payload

  const response = yield call(serviceProductPixel.delete, token, productId)
  if (response.status === 202) {
    yield put({
      type: 'product_pixel/get_product_pixel',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Product Success!',
      description: 'Product delete for permanent '
    })
  } yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_product_pixel, getProductPixel),
    takeEvery(actions.get_product_pixel_qr, getProductPixelQR),
    takeEvery(actions.delete_product_pixel, deleteProductPixel),
    takeEvery(actions.create_product_pixel, createProductPixel)
  ])
}
