import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceProduct = {
  get: (token, params) => helper.get('api/v1/products-admin?pending=false', token, params),
  log: (token, params) => helper.get('api/v1/log-activities', token),
  delete: (token, { productId }) =>
    axios.delete(apiBaseUrl + `api/v1/products-admin/${productId}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
