import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/topup-admin/actions'
import { serviceTopUpAdmin } from 'src/services/api/topUpAdmin'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'topup_admin/set_state',
    payload
  })
}

export function * getListTopUp ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceTopUpAdmin.get, token, params)

  if (response.status === 200) {
    yield setState({
      type: 'topup_admin/get_list',
      data: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * confirmTopUp ({ payload }) {
  yield load(true)
  const { token, id, params } = payload

  const response = yield call(serviceTopUpAdmin.patch, token, id, params)
  // console.log(response)
  if (response.status === 202) {
    yield setState({
      type: 'topup_admin/confirm_topup',
      success: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_list, getListTopUp),
    takeEvery(actions.confirm_topup, confirmTopUp)
  ])
}
