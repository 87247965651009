import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Button,
  Row,
  Col,
  Tabs,
  Avatar,
  Upload,
  message,
  Card,
  Steps,
  Typography,
  Tooltip,
  Layout
} from 'antd'
import { DefaultLayout } from 'src/layouts/default'
import {
  KeyOutlined,
  UserOutlined,
  MailOutlined,
  EditOutlined,
  // UploadOutlined,
  PhoneOutlined,
  SolutionOutlined,
  SendOutlined,
  AuditOutlined
  // EyeFilled,
  // EyeOutlined,
  // DownOutlined,
  // UpOutlined
} from '@ant-design/icons'

import { rules } from 'src/utils/rules'
import { Gap } from 'src/components/atoms/Gap'
import { FormItem } from 'src/components/molecules/FormItem'
import { Forms } from 'src/components/organizes/Forms'
// import { useLocation } from 'react-router'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import './style.css'

export const _MyProfile = ({ profile, dataInfo, loading, dispatch, token }) => {
  // const { state } = useLocation()
  const navigation = useNavigate()
  const [forms, setForms] = useState({})
  const { TabPane } = Tabs
  const { Step } = Steps
  const { Title, Text } = Typography
  const [tabActive, setTabActive] = useState('1')
  const [searchParams] = useSearchParams()

  const [current, setCurrent] = useState(0)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const callback = (key) => {
    setTabActive(key)
  }

  useEffect(() => {
    setMenuTab()
    dispatch({
      type: 'profile/get_profile',
      payload: { token }
    })
    dispatch({
      type: 'affiliates/get_affiliates_info',
      payload: { token }
    })
  }, [])

  useEffect(() => {
    setForms(profile)
  }, [profile])

  const onHandleGeneralUpdate = () => {
    dispatch({
      type: 'profile/update_profile',
      payload: {
        token,
        data: forms
      }
    })
  }

  const setMenuTab = () => {
    if (searchParams.get('menu') === 'affiliate') {
      setTabActive('4')
    }
  }

  const onHandlePasswordUpdate = () => {
    dispatch({
      type: 'profile/update_password',
      payload: {
        token,
        data: forms
      }
    })
  }

  const onHandleEmailUpdate = () => {
    dispatch({
      type: 'profile/update_email',
      payload: {
        token,
        data: forms
      }
    })
  }

  const onHandleEmailVerification = () => {
    dispatch({
      type: 'profile/verify_email',
      payload: {
        token,
        data: forms
      }
    })
  }

  const firstContent = () => {
    return (
      <Forms onFinish={onHandleEmailUpdate}>
        <Row gutter={16}>
          <FormItem
            rules={[rules.required]}
            prefix={<MailOutlined />}
            value={forms?.email}
            onChange={({ target }) =>
              setForms({ ...forms, email: target.value })}
            col={12}
            label='Email'
            placeholder='your email'
          />
        </Row>
        <Row justify='end'>
          <Button
            icon={<SendOutlined />}
            htmlType='submit'
            type='primary'
            loading={loading}
          >
            Send
          </Button>
        </Row>
      </Forms>
    )
  }

  const secondContent = () => {
    return (
      <Forms onFinish={onHandleEmailVerification}>
        <Row gutter={16}>
          <FormItem
            rules={[rules.required]}
            prefix={<SolutionOutlined />}
            value={forms?.code}
            onChange={({ target }) =>
              setForms({ ...forms, code: target.value })}
            col={12}
            label='Code'
            placeholder='your code verification'
          />
        </Row>
        <Row justify='end'>
          <Button
            htmlType='submit'
            type='primary'
            loading={loading}
          >
            Verify
          </Button>
        </Row>
      </Forms>
    )
  }

  const steps = [
    {
      title: 'Send Code',
      content: firstContent(),
      icon: <MailOutlined />
    },
    {
      title: 'Verification',
      content: secondContent(),
      icon: <SolutionOutlined />
    }
  ]

  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text'
    },
    onChange (info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
  }

  const onHandleDetail = (items) => {
    navigation('/dashboard/my-profile/detail', { state: items })
  }

  const formatter = (price) => {
    let format
    let simbol

    if (price !== null || price !== undefined) {
      if (price < 900) {
        format = price
        simbol = ''
      } else if (price < 900000) {
        format = price / 1000
        simbol = 'rb'
      } else if (price < 900000000) {
        format = price / 1000000
        simbol = 'jt'
      } else if (price < 900000000000) {
        format = price / 1000000000
        simbol = 'M'
      } else {
        format = price / 1000000000000
        simbol = 'T'
      }
      return format + simbol
    } else {
      return 0
    }

    // if ($presisi > 0) {
    //   $pisah = '.'.str_repeat('0', $presisi)
    //   $format_angka = str_replace($pisah, '', $format_angka)
    // }
  }

  return (
    <DefaultLayout>
      <Layout className='container-profile'>
        <Layout.Content className='container'>
          <Gap height='1rem' />
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <Gap height='1rem' />
                <Row gutter={16} justify='center'>
                  <Col>
                    <Avatar size={150} icon={<UserOutlined />} />
                  </Col>
                </Row>
                <Gap height='1rem' />
                <Row gutter={16} justify='center'>
                  <Col>
                    <Upload
                      {...props}
                      maxCount={1}
                    >
                      <Button icon={<EditOutlined />}>Change Avatar</Button>
                    </Upload>
                  </Col>
                </Row>
                <Gap height='1rem' />
                <Gap height='1rem' />
                <Row gutter={16} justify='center'>
                  <Col>
                    <h4>{profile?.fullName}</h4>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={18}>
              <Card>
                <Tabs defaultActiveKey='1' activeKey={tabActive} onChange={callback} gutter={16}>
                  <TabPane tab='General' key='1'>
                    <Forms onFinish={onHandleGeneralUpdate}>
                      <Row gutter={16}>
                        <FormItem
                          label='Username'
                          placeholder='your username'
                          prefix={<UserOutlined />}
                          rules={[rules.required]}
                          value={forms?.username}
                          onChange={({ target }) =>
                            setForms({ ...forms, username: target.value })}
                          col={12}
                        />
                        <FormItem
                          rules={[rules.required]}
                          value={forms?.fullName}
                          prefix={<UserOutlined />}
                          col={12}
                          onChange={({ target }) =>
                            setForms({ ...forms, fullName: target.value })}
                          label='Full Name'
                          placeholder='your fullname'
                        />
                        <FormItem
                          rules={[rules.required]}
                          value={forms?.phone}
                          prefix={<PhoneOutlined />}
                          col={12}
                          onChange={({ target }) =>
                            setForms({ ...forms, phone: target.value })}
                          label='Phone'
                          placeholder='your phone number'
                          extra='example: 6289533786100'
                        />
                      </Row>
                      <Row justify='end'>
                        <Button
                          icon={<EditOutlined />}
                          htmlType='submit'
                          type='primary'
                          loading={loading}
                        >
                          Update
                        </Button>
                      </Row>
                    </Forms>
                  </TabPane>
                  <TabPane tab='Change Password' key='2'>
                    <Forms onFinish={onHandlePasswordUpdate}>
                      <Row gutter={16}>
                        <FormItem
                          password
                          name='password'
                          label='Password'
                          extra='required password must be string, alphabet'
                          placeholder='your password'
                          prefix={<KeyOutlined />}
                          col={12}
                          onChange={({ target }) =>
                            setForms({ ...forms, password: target.value })}
                          hasFeedback
                        />
                        <FormItem
                          password
                          hasFeedback
                          col={12}
                          prefix={<KeyOutlined />}
                          name='confirmPassword'
                          label='Confirm Password'
                          dependencies={['password']}
                          placeholder='your password'
                          extra='required password must be string, alphabet'
                          onChange={({ target }) =>
                            setForms({ ...forms, confirmPassword: target.value })}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator (_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  new Error(
                                    'The two passwords that you entered do not match!'
                                  )
                                )
                              }
                            })
                          ]}
                        />
                      </Row>
                      <Row justify='end'>
                        <Button
                          icon={<EditOutlined />}
                          htmlType='submit'
                          type='primary'
                          loading={loading}
                        >
                          Update
                        </Button>
                      </Row>
                    </Forms>
                  </TabPane>
                  <TabPane tab='Change Email' key='3' style={{ padding: '33px' }}>
                    <Steps current={current}>
                      {steps.map(item => (
                        <Step key={item.title} title={item.title} icon={item.icon} />
                      ))}
                    </Steps>
                    <div className='steps-content'>{steps[current].content}</div>
                    <div className='steps-action'>
                      {current < steps.length - 1 && (
                        <Button type='primary' onClick={() => next()}>
                          Next
                        </Button>
                      )}
                      {current > 0 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                          Previous
                        </Button>
                      )}
                    </div>
                  </TabPane>
                  {profile?.role !== 'admin' &&
                    <TabPane tab='Affiliate' key='4' style={{ padding: '33px', background: '#f0f2f5' }}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Card className='text-center'>
                            <Title level={5} style={{ textTransform: 'uppercase', fontWeight: 500 }}>
                              Bonus Referalmu
                            </Title>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                            >
                              <p
                                className='mr-2 amount mb-0'
                                style={{
                                  fontSize: '18px'
                                }}
                              >
                                {`Rp${Intl.NumberFormat('id').format(dataInfo?.balance)}`}
                              </p>
                              <Tooltip placement='right' title='Detail'>
                                <span
                                  onClick={() => onHandleDetail(profile)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <AuditOutlined style={{
                                    color: '#1890ff',
                                    cursor: 'pointer',
                                    fontSize: 18
                                  }}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </Card>
                        </Col>
                        <Col span={12} className='mb-4'>
                          <Card className='text-center'>
                            <Title level={5} style={{ textTransform: 'uppercase', fontWeight: 500 }}>
                              Kode Referal Kamu
                            </Title>
                            <Text
                              className='amount'
                              copyable={{ tooltips: false }}
                              style={{
                                fontSize: '18px',
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              {profile?.account.referralCode}
                            </Text>
                          </Card>
                        </Col>
                        <Col span={24} className='mb-4'>
                          <Card>
                            <Text style={{ fontSize: 12 }}>
                              Dapatkan Bonus setiap ada yang mendaftar menggunakan kode referalmu sesua paket yang temanmu pilih
                            </Text>
                            <Row gutter={16} className='my-4'>
                              <Col span={8}>
                                <Card className='text-left product-card'>
                                  <Text className='product-title'>Basic</Text>
                                  <div
                                    className='mt-3'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <span style={{ fontSize: 11 }}>dapatkan</span>
                                    <Text
                                      strong
                                      className='product-bonus'
                                    >
                                      {`Rp${formatter(dataInfo?.setting?.NEW_SILVER_REFERRAL_REGISTERED_BONUS)}`}
                                    </Text>
                                  </div>
                                </Card>
                              </Col>
                              <Col span={8}>
                                <Card className='text-left product-card'>
                                  <Text className='product-title'>Standart</Text>
                                  <div
                                    className='mt-3'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <span style={{ fontSize: 11 }}>dapatkan</span>
                                    <Text
                                      strong
                                      className='product-bonus'
                                    >
                                      {`Rp${formatter(dataInfo?.setting?.NEW_GOLD_REFERRAL_REGISTERED_BONUS)}`}
                                    </Text>
                                  </div>
                                </Card>
                              </Col>
                              <Col span={8}>
                                <Card className='text-left product-card'>
                                  <Text className='product-title'>Corporate</Text>
                                  <div
                                    className='mt-3'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <span style={{ fontSize: 11 }}>dapatkan</span>
                                    <Text
                                      strong
                                      className='product-bonus'
                                    >
                                      {`Rp${formatter(dataInfo?.setting?.NEW_PLATINUM_REFERRAL_REGISTERED_BONUS)}`}
                                    </Text>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                            <Text style={{ fontSize: 12 }}>
                              dan dapatkan + Bonus {dataInfo?.setting?.BONUS_REFERRAL_TOPUP ?? 0}% setiap temanmu yang menggunakan kode referalmu melakukan topup
                            </Text>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card className='text-left'>
                            <Title className='amount' level={5} style={{ fontWeight: 600 }}>
                              Bagaimana caranya?
                            </Title>
                            <Text strong>
                              Bagikan Kode Unik
                            </Text>
                            <p style={{ fontSize: 12 }}>
                              Ajak teman untuk mendaftar OPIM dengan kode unik referral kamu
                            </p>
                            <Text strong>
                              Bermanfaat untuk teman anda
                            </Text>
                            <div style={{ fontSize: 12 }}>
                              Selain teman anda mendapatkan manfaat dari OPIM untuk melindungi produknya dari pemalsuan, teman anda juga mendapatkan bonus kredit langsung sesuai paket
                              <ol>
                                <li className='d-flex'>
                                  <span className='mr-1' style={{ minWidth: 12 }}>1.</span>
                                  Basic = +{dataInfo?.setting?.BONUS_CREDIT_REFERRAL_SILVER ?? 0} Kredit
                                </li>
                                <li className='d-flex'>
                                  <span className='mr-1' style={{ minWidth: 12 }}>2.</span>
                                  Standart = +{dataInfo?.setting?.BONUS_CREDIT_REFERRAL_PLATINUM ?? 0} Kredit
                                </li>
                                <li className='d-flex'>
                                  <span className='mr-1' style={{ minWidth: 12 }}>3.</span>
                                  Corporate = +{dataInfo?.setting?.BONUS_CREDIT_REFERRAL_GOLD ?? 0} Kredit
                                </li>
                              </ol>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>}
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerProfiles, reducerAffiliates }) => {
  return { ...reducerProfiles, ...reducerAffiliates }
}

export const MyProfile = connect(mapStateToProps)(_MyProfile)

_MyProfile.propTypes = {
  profile: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataInfo: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool
}
