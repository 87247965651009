import React from 'react'
// import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Content } from 'antd/lib/layout/layout'
// import { connect } from 'react-redux'

export const _Home = () => {
  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>

          <Gap height='2rem' />
        </Content>
      </Layout>

    </DefaultLayout>
  )
}

// const mapStateToProps = ({ reducerDistros }) => {
//   return { ...reducerDistros }
// }

// export const Home = connect(mapStateToProps)(_Home)
export const Home = (_Home)

// _Home.propTypes = {
//   dispatch: PropTypes.func,
//   token: PropTypes.string,
//   loading: PropTypes.bool,
//   data: PropTypes.array,
//   meta: PropTypes.object,
//   reset: PropTypes.bool
// }
