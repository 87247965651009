import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Upload, message, Row } from 'antd'
import { UploadOutlined, ContainerOutlined, InboxOutlined } from '@ant-design/icons'
import { FormItem } from 'src/components/molecules/FormItem'
import { Gap } from 'src/components/atoms/Gap'
import { AButton } from 'src/components/atoms/Button'
import { connect } from 'react-redux'
import { rules } from 'src/utils/rules'
import Select from 'rc-select'

const { Dragger } = Upload

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

const types = [
  { label: 'Image', value: 'IMAGE' },
  { label: 'Certificate', value: 'CERTIFICATE' }
]

const _UploadCertificates = ({ dispatch, loading, token, close, productId, visible, onCancel }) => {
  const [form] = Form.useForm()
  const [filePdf, setFilePdf] = useState({})

  useEffect(() => {
    if (close) {
      form.resetFields()
      setFilePdf(null)
    }
  }, [close])

  const props = {
    name: 'file',
    beforeUpload (file) {
      const isPdf = file.type === 'application/pdf'
      if (!isPdf) {
        message.error('You can only upload PDF file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      if (isPdf && isLt2M) {
        setFilePdf(file)
      }
      return isPdf ? true : Upload.LIST_IGNORE
    },
    onDrop (e) {
      // console.log('Dropped files', e.dataTransfer.files)
    }
  }

  const onHandleSubmit = () => {
    form.validateFields().then(data => {
      const forms = {
        ...data,
        file: filePdf
      }
      dispatch({
        type: 'product_master/upload_product_master',
        payload: { token, data: forms, productId }
      })
    })
  }

  return (
    <Modal
      title='Upload Certificate'
      onCancel={onCancel}
      footer={false}
      visible={visible}
    >
      <Form layout='vertical' form={form} onFinish={onHandleSubmit}>
        <FormItem
          rules={[rules.required]}
          name='name'
          prefix={<ContainerOutlined />}
          placeholder='Input your text'
          label='Name Certificate'
        />
        <FormItem label='Choose Type' select name='type'>
          {
            types.map((item, idx) =>
              <Select.Option placeholder='Select Item' key={item.value}>
                {item.label}
              </Select.Option>
            )
          }
        </FormItem>
        <Form.Item rules={[rules.required]} name='file'>
          <Dragger maxCount={1} customRequest={dummyRequest} {...props}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
        </Form.Item>
        <Gap height='2rem' />
        <Row justify='end'>
          <AButton
            loading={loading}
            icon={<UploadOutlined />}
            type='primary'
            title='Upload'
          />
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerProductMaster }) => {
  return { ...reducerProductMaster }
}

export const UploadCertificates = connect(mapStateToProps)(_UploadCertificates)

_UploadCertificates.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.bool,
  token: PropTypes.string,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  productId: PropTypes.string
}
