/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Descriptions, Form, Image, Input, Layout, Modal, Row, Space, Spin, Tooltip, notification } from 'antd'
// import LogoProduct from 'src/assets/images/icon-logo-company.jpg'
import LogoSNI from 'src/assets/images/logo-sni.png'
import LogoMUI from 'src/assets/images/logo-mui.png'
import LogoHalal from 'src/assets/images/logo-halal.png'
import LogoUI from 'src/assets/images/logo-ui.png'
import LogoBPOM from 'src/assets/images/logo-bpom.png'
import LogoOriginal from 'src/assets/images/logo-original.png'
import CertificateEmpty from 'src/assets/images/certificate-empty.png'

import bgLeft from 'src/assets/images/Left.svg'
import bgRight from 'src/assets/images/Right.svg'
import bgBottom from 'src/assets/images/Down.svg'

import { Gap } from 'src/components/atoms/Gap'
import { useParams, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Text from 'antd/lib/typography/Text'

import iconHerbal from 'src/assets/images/icon-herbal.png'
import iconFood from 'src/assets/images/icon-food.svg'
import iconFashion from 'src/assets/images/icon-fashion.png'

import iconFacebook from 'src/assets/images/socials/facebook-round.png'
import iconTwitter from 'src/assets/images/socials/twitter-round.png'
import iconTiktok from 'src/assets/images/socials/tiktok-round.png'
import iconInstagram from 'src/assets/images/socials/instagram-round.png'
import iconYoutube from 'src/assets/images/socials/youtube-round.png'
import iconGlobe from 'src/assets/images/socials/globe-round.png'

import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { rules } from 'src/utils/rules'
import Slider from 'react-slick'

// import { ClaimVoucher } from './ClaimVoucher'

import 'src/pages/common/styles/scan-page.css'

export const _ScanQR = (props) => {
  const { dispatch, data, token, dataPixels, dataCoupon, loading, dataTagManager, productPixel, gtmProduct } = props
  const { qrid, params } = useParams()
  const [latitude, setLatitude] = useState(false)
  const [longititude, setLongitude] = useState(false)
  const [visibleCoupon, setVisibleCoupon] = useState(false)
  const [visibleUniqueId, setVisibleUniqueId] = useState(false)
  const [form] = Form.useForm()
  const [locationStatus, setLocationStatus] = useState('unset')

  const query = new URLSearchParams(useLocation().search)
  const uniqueId = query.get('unique-code')

  const statusError = [400, 404]

  const productionDate = data && !statusError.includes(data.statusCode) && data.productionDate ? data.productionDate.split('T') : ''
  const expiredDate = data && !statusError.includes(data.statusCode) && data.expiredAt !== null ? data.expiredAt.split('T') : '-'
  const category = data && !statusError.includes(data.statusCode) ? data.product.category : ''
  let iconTop

  switch (category) {
    case 'herbal':
      iconTop = iconHerbal
      break
    case 'food':
      iconTop = iconFood
      break
    case 'fashion':
      iconTop = iconFashion
      break
    default:
      iconTop = iconHerbal
  }

  const settings = {
    infinite: false,
    autoplay: false,
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    slidesToShow: 2.2,
    slidesToScroll: 1
  }

  useEffect(() => {
    dispatch({
      type: 'product_pixel/get_product_pixel_qr',
      payload: {
        qrcodeId: qrid
      }
    })
    dispatch({
      type: 'gtm_product/get_gtm_product_qr',
      payload: {
        qrcodeId: qrid
      }
    })
    if (uniqueId) {
      form.setFieldsValue({
        uniqueId: uniqueId
      })
    }
    // onHandleAllowLocation()
  }, [])

  useEffect(() => {
    if (productPixel) {
      const index = productPixel?.events?.findIndex(f => f.position === 'landing-page-scan')
      ReactPixel.init(productPixel.pixelId)
      ReactPixel.pageView()
      ReactPixel.track(productPixel?.events[index].eventName, {})
    }
  }, [productPixel])

  useEffect(() => {
    if (gtmProduct) {
      TagManager.initialize({
        gtmId: gtmProduct.googleTagManager
      })
    }
  }, [gtmProduct])

  useEffect(() => {
    if (!dataCoupon) {
      form.setFieldsValue({
        couponCode: null
      })
    }
  }, [dataCoupon])

  useEffect(() => {
    if (data && !statusError.includes(data.statusCode) && locationStatus === 'on') {
      if (data.totalChecked !== data.limitCheck) {
        notification.warning({
          message: 'Try again',
          description: 'Scan lagi untuk klaim kupon discount'
        })
      } else {
        notification.success({
          message: 'Claim coupon Success!',
          description: 'Selamat anda telah berhasil memasukan kode kupon'
        })
      }
    }
  }, [data])

  const onHandleAllowLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocationStatus('on')
      const body = {
        latitude: position.coords.latitude,
        longititude: position.coords.longitude
      }
      dispatch({
        type: 'scan_qr/get_scan_qr',
        payload: {
          qrid,
          body
        }
      })
      setLatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    }, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        setLocationStatus('off')
      }
    })
  }

  const onHandleFinishUniqueId = () => {
    form.validateFields().then(data => {
      const body = {
        latitude: latitude,
        longititude: longititude,
        uniqueCode: data?.uniqueId
      }
      dispatch({
        type: 'scan_qr/get_scan_qr',
        payload: { qrid, body }
      })
    })
    setVisibleUniqueId(false)
  }

  const onHandleFinishCoupon = () => {
    const index = dataPixels?.events?.findIndex(f => f.position === 'submit')

    form.validateFields().then((data) => {
      const body = {
        latitude: latitude,
        longititude: longititude,
        uniqueCode: data?.uniqueId,
        coupon: data?.couponCode,
        name: data?.name,
        phone: data?.phone,
        email: data?.email,
        eventName: dataPixels?.events[index].eventName
      }
      dispatch({
        type: 'scan_qr/get_scan_qr',
        payload: { qrid, body }
      })
    })
    setVisibleCoupon(false)
    ReactPixel.track(dataPixels?.events[index].eventName, {
      currency: 'IDR',
      value: data.product.price
    })
    form.resetFields()
  }

  const checkCoupon = () => {
    form.validateFields().then((data) => {
      dispatch({
        type: 'coupon/check_coupon',
        payload: {
          token,
          data: {
            qrcodeId: qrid,
            coupon: data?.couponCode
          }
        }
      })
    })
  }

  const claimCoupon = () => {
    const index = dataPixels?.events?.findIndex(f => f.position === 'button-popup-coupon')
    ReactPixel.track(dataPixels?.events[index].eventName, {})
    setVisibleCoupon(true)
  }

  const fashionAttributes = (attr) => {
    switch (attr) {
      case 'model':
        return 'Model'
      case 'color':
        return 'Warna'
      case 'size':
        return 'Ukuran'
      case 'material':
        return 'Bahan'
      default:
        return null
    }
  }

  return (
    <Layout className='scan-page-container webview-component'>
      {
        data && !statusError.includes(data.statusCode) && locationStatus === 'on'
          ? (
            <>
              <Card className='pb-lg-5 pb-0'>
                <img src={bgLeft} className='bg-left' />
                <img src={bgRight} className='bg-right' />
                <img src={bgBottom} className='bg-bottom' />
                <Image src={iconTop} width={48} style={{ opacity: 0.8 }} />

                <p className='big-title-sertifikat'>
                  <span>CERTIFICATE</span>
                  <br />
                  {
                    data.expiredAt === null && data.limitCheck === null ? 'Original Product' : null
                  }
                </p>
                <div className='logo-wrapper'>
                  <Image src={data.product.images[0] ? data.product.images[0].url : iconTop} preview={false} width={150} style={{ borderRadius: 20 }} />
                  <img src={LogoOriginal} className='bg-original' />
                </div>
                <Gap height='.6rem' />
                <Layout.Content className='certificate-description px-0 px-md-3 px-lg-3'>
                  {/* <Row justify='center' className='description-items' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Nama Produk
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{data.product.name}</span>
                  </Row>
                  <Gap height='.6rem' /> */}
                  <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Produk asli dari..
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                  </Row>
                  <Gap height='.6rem' />
                  {/* <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Diproduksi oleh
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                  </Row>
                  <Gap height='.6rem' />
                  <Row justify='center' className='description-items' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Distributor
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {
                        data?.distros?.map((item, index) => {
                          return <span key={index} className='text-right w-100 pl-1'>{item.name}</span>
                        })
                      }
                    </div> */}
                  {/* <span className='text-right w-50 pl-1'>{data.product.distributedBy}</span> */}
                  {/* </Row>
                  <Gap height='.6rem' />
                  <Row justify='center' className='description-items' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Diterbitkan oleh
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                  </Row>
                  <Gap height='.6rem' />
                  <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Tanggal Terbit
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{productionDate[0] ? productionDate[0] : productionDate}</span>
                  </Row>
                  <Gap height='.6rem' />
                  <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Expired Date
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{expiredDate[0] ? expiredDate[0] : expiredDate}</span>
                  </Row>
                  <Gap height='.6rem' /> */}
                  {
                    data.product.licenses.map((item, index) => {
                      return (
                        <div key={index}>
                          <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                            <Descriptions.Item>
                              <div className='d-flex justify-content-between w-50 text-left'>No. {item.type}
                                <Text>:</Text>
                              </div>
                            </Descriptions.Item>
                            <span className='text-right w-50 pl-1'>{item.no}</span>
                          </Row>
                          <Gap height='.6rem' />
                        </div>
                      )
                    })
                  }
                  {/* <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                    <Descriptions.Item>
                      <div className='d-flex justify-content-between w-50 text-left'>Diskripsi
                        <Text>:</Text>
                      </div>
                    </Descriptions.Item>
                    <span className='text-right w-50 pl-1'>{data.product.shortDescription}</span>
                  </Row>
                  <Gap height='.6rem' />
                  {
                    data.product.category === 'fashion'
                      ? (
                          data.product.attributes.map((item, index) =>
                            <Row justify='center' className='description-items hover' style={{ padding: 0, marginBottom: '.6rem' }} key={index}>
                              <Descriptions.Item>
                                <div className='d-flex justify-content-between w-50 text-left'>
                                  {
                                    fashionAttributes(item.key)
                                  }
                                  <Text>:</Text>
                                </div>
                              </Descriptions.Item>
                              <span className='text-right w-50 pl-1'>{item.value}</span>
                            </Row>
                          )
                        )
                      : null
                  }
                  <Gap height='.6rem' /> */}
                </Layout.Content>
                <Gap height='1rem' />
                {
                  data.expiredAt == null && data.limitCheck === null
                    ? (
                      <Button
                        type='primary'
                        size='middle'
                        onClick={() => setVisibleUniqueId(true)}
                      >
                        Input Unique Id
                      </Button>
                      )
                    : null
                }
                {data.product.socials &&
                  <>
                    <Gap height='1rem' />
                    <Row justify='center' align='middle'>
                      <Col span={24} className='socials'>
                        {data.product.socials.map((social, index) => {
                          return (
                            <a key={index} target='_blank' href={social?.url} rel='noreferrer'>
                              {social?.name.toLowerCase() === 'facebook' && <img src={iconFacebook} alt='icn-fb' />}
                              {social?.name.toLowerCase() === 'twitter' && <img src={iconTwitter} alt='icn-tw' />}
                              {social?.name.toLowerCase() === 'tiktok' && <img src={iconTiktok} alt='icn-tt' />}
                              {social?.name.toLowerCase() === 'instagram' && <img src={iconInstagram} alt='icn-ig' />}
                              {social?.name.toLowerCase() === 'youtube' && <img src={iconYoutube} alt='icn-yt' />}
                              {social?.name.toLowerCase() !== ('facebook' || 'twitter' || 'tiktok' || 'instagram' || 'youtube') && <img src={iconGlobe} alt='icn-gb' />}
                            </a>
                          )
                        })}

                      </Col>
                    </Row>
                  </>}
                {data.product.certificates &&
                  <>
                    <Gap height='1rem' />
                    <Row justify='center'>
                      <Col span={24} className='carousel'>
                        <h5>Sertifikat</h5>
                        <Slider {...settings}>
                          {data.product.certificates.map(c =>
                            <div className='carousel-item' key={c?.id}>
                              <div className='carousel-image-wrapp'>
                                <Image className='carousel-image' src={c?.url ?? CertificateEmpty} alt={`cert-${c?.id}`} />
                              </div>
                              <div className='carousel-text'>
                                {c?.data}
                              </div>
                            </div>

                          )}
                        </Slider>
                      </Col>
                    </Row>
                  </>}
                <Gap height='1rem' />
                <Row justify='center'>
                  <Layout.Content className='footer-description'>
                    <span>
                      Sertifikat keaslian produk telah dijamin keasliannya oleh OPIM.
                      Segala tindakan pemalsuan akan dipidana sesuai hukum yang berlaku.
                      <br />
                      <Gap height='1rem' />
                      @2021 Ori Premium Indonesia Market
                    </span>
                  </Layout.Content>
                </Row>
                <Row justify='center' gutter={16} className='pb-lg-5 pb-0'>
                  <Space size='large'>
                    {
                      data.product.licenses.map((item, index) => {
                        const data = item.type === 'halal-mui'
                          ? <Image src={LogoMUI} preview={false} width={50} key={index} />
                          : item.type === 'sni'
                            ? <Image src={LogoSNI} preview={false} width={40} key={index} />
                            : item.type === 'ui'
                              ? <Image src={LogoUI} preview={false} width={50} key={index} />
                              : item.type === 'halal'
                                ? <Image src={LogoHalal} preview={false} width={50} key={index} />
                                : item.type === 'bpom'
                                  ? <Image src={LogoBPOM} preview={false} width={50} key={index} />
                                  : null
                        return data
                      })
                    }
                  </Space>
                </Row>
              </Card>
              <Modal
                style={{ top: 20 }}
                visible={visibleUniqueId}
                onOk={() => setVisibleUniqueId(!visibleUniqueId)}
                onCancel={() => setVisibleUniqueId(!visibleUniqueId)}
                footer={false}
                bodyStyle={{ textAlign: 'center' }}
                closable
              >
                <Gap height='2rem' />
                <Form form={form} className='d-flex flex-row align-items-center flex-wrap' onFinish={onHandleFinishUniqueId}>
                  <Form.Item className='w-75 mr-2' name='uniqueId'>
                    <Input placeholder='Type your unique code' size='large' />
                  </Form.Item>
                  <Form.Item>
                    <Button type='primary' size='medium' htmlType='submit'>Check</Button>
                  </Form.Item>
                </Form>
              </Modal>
              <Modal
                title='Claim Coupon'
                visible={visibleCoupon}
                onOk={() => setVisibleCoupon(!visibleCoupon)}
                onCancel={() => setVisibleCoupon(!visibleCoupon)}
                footer={false}
                bodyStyle={{ textAlign: 'center' }}
                closable
                className='modal-claim'
              >
                {/* <Gap height='2rem' /> */}
                <Form form={form} className='d-flex flex-row align-items-center flex-wrap' onFinish={onHandleFinishCoupon}>
                  <Form.Item className='w-100 mr-2' name='name' rules={[rules.required]}>
                    <Input placeholder='Name' size='large' />
                  </Form.Item>
                  <Form.Item className='w-100 mr-2' name='phone' rules={[rules.required]}>
                    <Input placeholder='Phone' size='large' />
                  </Form.Item>
                  <Form.Item className='w-100 mr-2' name='email' rules={[rules.required]}>
                    <Input placeholder='Email' size='large' />
                  </Form.Item>
                  <div className='coupon-form' style={{ flexDirection: 'column' }}>
                    <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                      <Form.Item className='mr-2 customColumn' style={{ width: '80%' }} name='couponCode'>
                        <Input placeholder='Code Coupon' size='large' />
                      </Form.Item>
                      <Form.Item className='mr-2'>
                        <Button type='primary' size='medium' loading={loading} onClick={() => checkCoupon()}>Check</Button>
                      </Form.Item>
                    </div>
                    {dataCoupon &&
                      <div className='ant-alert-info' style={{ marginBottom: 24, textAlign: 'left' }}>
                        <Col span={24} className='py-2 px-4'>
                          <div className='mb-1' style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{dataCoupon?.coupon}</div>
                          <div style={{ fontStyle: 'italic', fontSize: 13 }}>
                            {`Dapatkan Diskon ${dataCoupon?.discountCalculated?.percentage}%`}
                            <span
                              className='mx-1' style={{
                                textDecoration: 'line-through',
                                color: '#1486C9'
                              }}
                            >{`Rp ${Intl.NumberFormat('id').format(dataCoupon?.product?.price)}`}
                            </span> {`Rp${Intl.NumberFormat('id').format(dataCoupon?.product?.price - dataCoupon?.discountCalculated?.priceDiscounted)} untuk pembelian selanjutnya pada produk yang sama`}
                          </div>
                        </Col>
                      </div>}
                  </div>
                  <Form.Item>
                    <Button type='primary' size='medium' htmlType='submit'>Submit Coupon</Button>
                  </Form.Item>
                </Form>
              </Modal>
              <div className='scan-claim-popup'>
                <Button
                  type='primary'
                  className='claim-btn claim-popup-btn'
                  title='Claim Coupon'
                  onClick={claimCoupon}
                >
                  CLAIM COUPON
                </Button>
              </div>
            </>
            )
          : locationStatus === 'off'
            ? <span className='text-center px-4 d-flex align-items-center' style={{ height: '100vh', fontWeight: 'bold' }}>Silahkan izinkan pengecekan lokasi untuk melihat informasi produk ini</span>
            : data && statusError.includes(data.statusCode) && data.message === "Unique Code Doesn't Matched"
              ? <span className='text-center px-4 d-flex align-items-center' style={{ height: '100vh', fontWeight: 'bold' }}>Unique Code tidak valid silahkan refresh dan coba lagi!</span>
              : data && statusError.includes(data.statusCode)
                ? <span className='text-center px-4 d-flex align-items-center' style={{ height: '100vh', fontWeight: 'bold' }}>QR Rusak, produk tidak terjamin. Silahkan tanyakan kepada penjual</span>
                : <Spin spinning size='large' className='spin-height' />
      }
    </Layout>
  )
}

const mapStateToProps = ({ reducerScanQR, reducerFacebookPixel, reducerCoupons, reducerGoogleTagManager }) => {
  return { ...reducerScanQR, ...reducerFacebookPixel, ...reducerCoupons, ...reducerGoogleTagManager }
}

export const ScanQR = connect(mapStateToProps)(_ScanQR)

_ScanQR.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  data: PropTypes.object,
  dataPixels: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataCoupon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataTagManager: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  productPixel: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  gtmProduct: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  token: PropTypes.string
}
