import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Modal, Row } from 'antd'
import {
  UserSwitchOutlined,
  ToTopOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { AButton } from 'src/components/atoms/Button'

const _CreateWithdraw = ({ loading, visible, onCancel, form, onFinish, updateData }) => {
  useEffect(() => {
    if (updateData?.balance) {
      form.setFieldsValue({
        balance: updateData?.balance
      })
    }
  }, [updateData])

  return (
    <Modal
      title='Create Withdraw'
      visible={visible}
      onCancel={onCancel}
      footer={false}
      forceRender
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout='vertical'
      >
        <FormItem
          prefix='Rp'
          label='Saldo'
          name='balance'
          disabled
        />
        <FormItem
          rules={[rules.required]}
          prefix='Rp'
          label='Withdraw'
          name='withdraw'
          placeholder='500.000'
        />
        <FormItem
          rules={[rules.required]}
          prefix={<UserSwitchOutlined />}
          label='Note'
          name='note'
          textarea
          placeholder='add your note'
        />
        <Row justify='end'>
          <AButton
            loading={loading}
            icon={<ToTopOutlined />}
            title='Process'
            type='primary'
          />
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerUsers, reducerRoles }) => {
  return { ...reducerUsers, ...reducerRoles }
}

export const CreateWithdraw = connect(mapStateToProps)(_CreateWithdraw)

_CreateWithdraw.propTypes = {
  form: PropTypes.object,
  onFinish: PropTypes.func,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  updateData: PropTypes.instanceOf(Object)
}
