/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Button, Layout } from 'antd'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Content } from 'antd/lib/layout/layout'
import { useForm } from 'antd/lib/form/Form'
import { FormItem } from 'src/components/molecules/FormItem'
// import { rules } from 'src/utils/rules'
import { connect } from 'react-redux'

const FORM_INITIAL = {
  id: ''
}

export const _GoogleTagManager = ({
  loading,
  dataTagManager,
  token,
  dispatch
}) => {
  const [form] = useForm()
  const [formData, setFormData] = useState(FORM_INITIAL)

  useEffect(() => {
    dispatch({
      type: 'google_tag_manager/get',
      payload: {
        token
      }
    })
  }, [])

  useEffect(() => {
    // const arr = []
    if (dataTagManager) {
      setFormData((prevState) => ({
        ...prevState,
        id: dataTagManager.googleTagManager
      }))
      form.setFieldsValue({
        id: dataTagManager.googleTagManager
      })
    }
  }, [dataTagManager])

  const handleChange = (e) => {
    const { target } = e
    const { id, value } = target

    setFormData((prevState) => ({ ...prevState, [id]: value }))
  }

  const submit = async () => {
    const data = {
      googleTagManager: formData.id
    }

    dispatch({
      type: 'google_tag_manager/create',
      payload: {
        token,
        data
      }
    })
    // console.log(formData.id)
    form.resetFields()
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Gap height='1rem' />
          {/* <Button type='ghost' htmlType='tes' loading={loading} onClick={onDelete}>
            Delete
          </Button> */}
          <Form onFinish={submit} autoComplete='off' form={form}>
            <Row
              gutter={12}
              style={{
                padding: '16px 24px',
                marginLeft: 0,
                marginRight: 0,
                backgroundColor: '#fff',
                borderRadius: '7px'
              }}
            >
              <Col span={24} style={{ marginBottom: 10 }}>
                <Gap height='1rem' />
                <FormItem
                  col={24}
                  name='id'
                  placeholder='Google Tag Manager ID'
                  onChange={handleChange}
                />
              </Col>
              <Row justify='end'>
                <Button type='primary' htmlType='submit' loading={loading}>
                  Submit
                </Button>
              </Row>
            </Row>
          </Form>
          <Gap height='2rem' />
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerGoogleTagManager }) => {
  return { ...reducerGoogleTagManager }
}

export const GoogleTagManager = connect(mapStateToProps)(_GoogleTagManager)

_GoogleTagManager.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  dataTagManager: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
