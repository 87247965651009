import React from 'react'
import PropTypes from 'prop-types'

export const Gap = ({ height, width }) => {
  return <div style={{ width: width, height: height }} />
}
Gap.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
}
