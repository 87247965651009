import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Modal,
  Row,
  Select
} from 'antd'
import {
  UserOutlined,
  PlusCircleOutlined,
  DollarCircleOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'

const _UpdateRoles = ({
  visible,
  dispatch,
  onCancel,
  token,
  loading,
  data
}) => {
  const [formItem, setFormItem] = useState({
    name: null,
    price: null,
    credit: null,
    description: null
  })

  useEffect(() => {
    setFormItem(data)
  }, [data])

  const onHandleUpdate = () => {
    const data = {
      ...formItem,
      price: Number(formItem.price),
      credit: Number(formItem.credit)
    }
    dispatch({
      type: 'roles/update_roles',
      payload: {
        token,
        data
      }
    })
  }

  return (
    <Modal
      title='Creaete Permission'
      centered
      wrapClassName='modal-create--roles'
      maskClosable={false}
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form layout='vertical'>
        <FormItem
          value={formItem?.name}
          rules={[rules.required]}
          prefix={<UserOutlined />}
          disabled
          label='Role Name'
          onChange={({ target }) => setFormItem({ ...formItem, name: target.value })}
        />
        <FormItem
          label='Choose Permissions'
          select
          disabled
          placeholder='Choose permission'
          mode='tags'
          rules={[rules.required]}
        >
          <Select.Option>
            Custom Select
          </Select.Option>
        </FormItem>
        <FormItem
          rules={[rules.required]}
          prefix={<DollarCircleOutlined />}
          label='Price'
          value={formItem?.price}
          placeholder='your price'
          onChange={({ target }) => setFormItem({ ...formItem, price: target.value })}
        />
        <FormItem
          rules={[rules.required]}
          prefix={<DollarCircleOutlined />}
          value={formItem?.credit}
          label='Credit'
          onChange={({ target }) => setFormItem({ ...formItem, credit: target.value })}
          placeholder='your credit'
        />
        <FormItem
          rules={[rules.required]}
          textarea
          value={formItem?.description}
          label='Description'
          onChange={({ target }) => setFormItem({ ...formItem, description: target.value })}
          placeholder='your permission name'
        />
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            onClick={onHandleUpdate}
            icon={<PlusCircleOutlined />}
          >
            Update Role
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerRoles }) => {
  return { ...reducerRoles }
}

export const UpdateRoles = connect(mapStateToProps)(_UpdateRoles)

_UpdateRoles.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
  token: PropTypes.string
}
