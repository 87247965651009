import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Modal, Row } from 'antd'
import {
  TagOutlined,
  UserOutlined,
  AimOutlined,
  UserSwitchOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'

import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { AButton } from 'src/components/atoms/Button'

const _CreateDistro = ({ loading, visible, onCLick, onCancel, form, onFinsih }) => {
  return (
    <Modal
      title='Create Distro'
      visible={visible}
      onCLick={onCLick}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        onFinish={onFinsih}
        form={form}
        layout='vertical'
      >
        <FormItem
          rules={[rules.required]}
          prefix={<UserOutlined />}
          label='Store name'
          name='name'
          placeholder='type your store name'
        />
        <FormItem
          rules={[rules.required]}
          prefix={<TagOutlined />}
          label='Code Distro'
          name='code'
          placeholder='add your store code'
        />
        <FormItem
          rules={[rules.required]}
          prefix={<UserSwitchOutlined />}
          label='Distro Owner'
          name='owner'
          placeholder='add your store owner'
        />
        <FormItem
          rules={[rules.required]}
          prefix={<AimOutlined />}
          label='Address'
          name='address'
          placeholder='add your addresess'
        />
        <Row justify='end'>
          <AButton
            loading={loading}
            icon={<PlusCircleOutlined />}
            title='submit'
            type='primary'
          />
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerUsers, reducerRoles }) => {
  return { ...reducerUsers, ...reducerRoles }
}

export const CreateAgent = connect(mapStateToProps)(_CreateDistro)

_CreateDistro.propTypes = {
  onCLick: PropTypes.func,
  form: PropTypes.object,
  onFinsih: PropTypes.func,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool
}
