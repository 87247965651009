/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { serviceCouponClaimers } from 'src/services/api/couponClaimers'

import { actions } from './actions'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'coupon_claimers/set_state',
    payload
  })
}

export function * getCouponClaimers ({ payload }) {
  yield load(true)
  const { token, params } = payload
  const response = yield call(serviceCouponClaimers.get, token, params)

  if (response.status === 200) {
    yield setState({
      // credit: response.data.data.account.credit,
      data: response.data.data.items,
      meta: response.data.data.meta,
      loading: false
    })
    // eslint-disable-next-line no-undef
    // localStorage.setItem('credit', response.data.data.account.credit)
  } else message.warning('get coupon claimers failed')
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_coupon_claimers, getCouponClaimers)
  ])
}
