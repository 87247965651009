module.exports = [
  {
    title: '1. Selamat Datang di OPIM (Ori Premium Indonesia Market)',
    video: 'https://drive.google.com/file/d/1wYlC7Y9Z8RXj17h2TWX3STjXmPXFd4MQ/preview',
    pdf: 'https://drive.google.com/file/d/1PsJgztEurlxECpCrmcyX5hLP5cbGwArQ/preview'
  },
  {
    title: '2. Mengenal Fitur Distributor dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1dg5l1G2z1FN7FvhW_TdmL-19Tve70Nt9/preview',
    pdf: 'https://drive.google.com/file/d/1ZvhUOAd-e4N-RaUgWvQO91A-cOwnHkdg/preview'
  },
  {
    title: '3. Mengenal Fitur Sub Account dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1TjbxOY5HuL5X5xBRk03kVbPekSxTHPC1/preview',
    pdf: 'https://drive.google.com/file/d/1HNLgVSAZ4NV7ecklZWUHWdgD-mRQaCwk/preview'
  },
  {
    title: '4. Mengenal Fitur Produk dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1EFZn7ibgQIBMew4tJwxoaFNZBl3Khmbz/preview',
    pdf: 'https://drive.google.com/file/d/1tYULeIGNtCx7b1qQHBvKsEQmMlVbIkyG/preview'
  },
  {
    title: '5. Mengenal Log dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1OpVKS2LeLUuF9ClJeUGeA7gc5bCH3bps/preview',
    pdf: 'https://drive.google.com/file/d/1QwzhKH7jhgX2AH1UAe74tbHbbG7Uvwkk/preview'
  },
  {
    title: '6. Mengenal Setting QR dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1lEmNbjvjB-QDZfkFAVMKjcbsmezNsE_C/preview',
    pdf: 'https://drive.google.com/file/d/1pKwizv40C9YkCAaqi-jxrr_wjMq7-v5d/preview'
  },
  {
    title: '7. Mengenal Fitur Generate QR dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1BiHK6BwHw8WoUQC1ec_Knbh5ClG9d_k6/preview',
    pdf: 'https://drive.google.com/file/d/198wZVCyzWd1dw0HlisZ1HUhdj8j3Lsym/preview'
  },
  {
    title: '8. Mengenal Fitur Top UP dan Cara Menggunakannya',
    video: 'https://drive.google.com/file/d/1zYLIjEw_nZzLsV6vlkjmDrkaAvkcpogg/preview',
    pdf: 'https://drive.google.com/file/d/13mickP0p4E-3Bh0gk30QgBmV_ajwG4dI/preview'
  }
]
