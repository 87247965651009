/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import {
  Badge,
  Avatar,
  Divider,
  Layout,
  Menu,
  Row
} from 'antd'

import imgSidebar from 'src/assets/images/Dashboard.svg'
import logoOpim from 'src/assets/images/logo-opim.svg'

import { Gap } from 'src/components/atoms/Gap'
import { MPopover } from 'src/components/molecules/Popover'
import { menuFreeMaster, menuMaster, menus, menuSilverMaster, menuSubAccount } from 'src/utils/menu'
import OpimLogo from 'src/assets/images/opim-logo.svg'

const { Sider } = Layout

export const Sidebar = ({ role, credit }) => {
  const subaccount = JSON.parse(localStorage?.getItem('subaccount'))
  const username = localStorage.getItem('username')
  const location = useLocation()
  const setRolesMenu = () => {
    switch (role) {
      case 'admin':
        return menus
      case 'gold_master':
        return menuMaster
      case 'silver_master':
        return menuSilverMaster
      case 'free_master':
        return menuFreeMaster
      case 'platinum_master':
        return menuMaster
      case 'sub_account': {
        return menuSubAccount(subaccount)
      }
      default:
        return menuMaster
    }
  }

  return (
    <Sider
      className='dashboard-siderbar'
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        overflowX: 'hidden',
        borderRadius: '0 3rem 3rem 0'
      }}
    >

      <img src={imgSidebar} className='img-sider' />

      <Gap height='3rem' />

      <div className='sidebar-header'>
        <Row align='middle' style={{ paddingLeft: '1.5rem', background: 'transparent' }} className='pt-5'>
          <Badge>
            <Avatar shape='square' icon={<UserOutlined />} className='bg-white' style={{ color: '#1486C9' }} />
          </Badge>
          <Gap width='1rem' />
          <MPopover>
            <div style={{ lineHeight: '.7rem' }}>
              <p className='title-profile'>
                {username.replace('_', ' ')} <br />
              </p>
              <span className='credit'>Credit {credit}</span>
            </div>
          </MPopover>
          <Divider style={{ background: '#0272ae' }} />
        </Row>
      </div>

      <div className='sidebar-menu'>
        <Menu
          mode='inline'
          defaultSelectedKeys={[location.pathname]}
          className='sidebar-menu-content'
          style={{
            borderRight: 0
          }}
        >
          {setRolesMenu()?.map((item, index) =>
            <Menu.Item key={[item.redirect]} icon={item.icon}>
              <Link to={item.redirect}>
                {item.title}
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </div>

      <div className='sidebar-logo'>
        <img src={OpimLogo} />
      </div>
      {/* <img src={imgSidebar} className='img-sider' />
      <Gap height='3rem' />
      <Row align='middle' style={{ paddingLeft: '1.5rem', background: 'transparent' }} className='pt-5'>
        <Badge>
          <Avatar shape='square' icon={<UserOutlined />} className='bg-white' style={{ color: '#1486C9' }} />
        </Badge>
        <Gap width='1rem' />
        <MPopover>
          <div style={{ lineHeight: '.7rem' }}>
            <p className='title-profile'>
              {username.replace('_', ' ')} <br />
            </p>
            <span className='credit'>Credit {credit}</span>
          </div>
        </MPopover>
        <Divider style={{ background: '#0272ae' }} />
      </Row>

      <div className='container-sidbar-menu'>
        <Menu
          mode='inline'
          defaultSelectedKeys={[location.pathname]}
          style={{
            borderRight: 0
          }}
        >
          {setRolesMenu()?.map((item, index) =>
            <Menu.Item key={[item.redirect]} icon={item.icon}>
              <Link to={item.redirect}>
                {item.title}
              </Link>
            </Menu.Item>
          )}
        </Menu>
        <div className='brand-logo-sidebar'>
          asssssssssssssssssssssssssssssssasssssssssssssssssssssss
        </div>
      </div> */}

    </Sider>
  )
}

Sidebar.propTypes = {
  role: PropTypes.string,
  credit: PropTypes.number
}
