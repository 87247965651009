import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Layout, Space, Table, message } from 'antd'
import { ToTopOutlined, EyeOutlined } from '@ant-design/icons'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { Content } from 'antd/lib/layout/layout'
import { CreateWithdraw } from './CreateWithdraw'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router'

export const _Affiliate = ({ data, loading, token, dispatch, meta }) => {
  const navigation = useNavigate()
  const [value, setValue] = useState('')
  const [search, setSearch] = useState('')
  const [update, setUpdate] = useState({
    visible: false,
    items: null
  })
  const [form] = Form.useForm()
  const customVal = value?.length <= 0 ? '' : search

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Member',
      dataIndex: 'totalReferrals'
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      render: (item) => <p>Rp{Intl.NumberFormat('id').format(item)}</p>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (txt, obj) => (
        <Space>
          <Button
            size='small'
            className='button-detail'
            onClick={() => onHandlePreview(obj)}
          >
            <EyeOutlined />
          </Button>
          <Button
            size='small'
            onClick={() => onHandleVisibleUpdate(obj)}
            className='button-detail'
            style={{
              backgroundColor: '#BF24AD'
            }}
          >
            <ToTopOutlined />
            Withdraw
          </Button>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'admin_affiliate/get_affiliates',
      payload: {
        token,
        params: {
          search: customVal,
          page: meta?.currentPage
        }
      }
    })
  }, [customVal, search])

  const onHandleVisibleUpdate = (items) => {
    setUpdate({
      visible: true,
      items
    })
  }

  const onHandleCreate = () => {
    form.validateFields().then(data => {
      if (Number(data?.withdraw) < 500000) {
        message.error('Minimal withdraw Rp500.000')
      } else {
        dispatch({
          type: 'admin_affiliate/create_affiliates',
          payload: {
            token,
            id: update?.items?.id,
            body: {
              nominal: Number(data?.withdraw),
              description: data?.note
            },
            page: meta?.currentPage
          }
        })
      }
    })
    setUpdate({ update: null, visible: false })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'admin_affiliate/get_affiliates',
      payload: { token, params: { page: page } }
    })
  }

  const onHandlePreview = (items) => {
    navigation(`/dashboard/affiliate/${items.id}/detail`, { state: items })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Header
            simple
            type='primary'
            placeholder='search by name or email'
            onClickSearch={() => setSearch(value)}
            onChange={({ target }) => setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={data ?? []}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
        </Content>
      </Layout>
      <CreateWithdraw
        visible={update.visible}
        form={loading ? form.resetFields() : form}
        loading={loading}
        updateData={update.items}
        onFinish={onHandleCreate}
        onCancel={() => setUpdate({ update: null, visible: false })}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerAdminAffiliates }) => {
  return { ...reducerAdminAffiliates }
}

export const Affiliate = connect(mapStateToProps)(_Affiliate)

_Affiliate.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  meta: PropTypes.object
}
