import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  message,
  Modal,
  Row,
  Select,
  Upload
} from 'antd'

import {
  ToolOutlined,
  CameraOutlined,
  UploadOutlined
} from '@ant-design/icons'

import { AButton } from 'src/components/atoms/Button'
import { FormItem } from 'src/components/molecules/FormItem'
import { connect } from 'react-redux'

import settingData from './settingData.json'

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

const _UpdateSettingQR = ({ dispatch, reset, token, dataUpdate, loading, visible, onCancel }) => {
  const [form] = Form.useForm()
  const [items, setItems] = useState({})
  const [withText, setWithText] = useState()
  const [select, setSelect] = React.useState('')

  useEffect(() => {
    setItems(dataUpdate)
    if (dataUpdate?.urlScanPage === 'DEFAULT') {
      setSelect(dataUpdate?.urlScanPage)
    } else {
      setSelect('CUSTOM')
    }
    withCustomText(onHandleSizeValtoKey(dataUpdate?.size))
  }, [dataUpdate])

  const onHandleSizeValtoKey = (size) => {
    if (size !== 'A3 : QR Code SIze 20x20mm with Text') {
      if (size) {
        size = size.replace(/ /g, '_')
        size = size.toUpperCase()
        size = size.replace('_:', '')
        size = size.replace('1_QR_CODE_', '1_')
        size = size.replace('-_QR_CODE', 'QR')
        size = size.replace('MM', '')
        size = size.replace('WITH_', '')
        size = size.replace('SIZE_', '')
        size = size.replace('X', 'x')
        return size
      }
    } else {
      return 'MEDIUM'
    }
  }

  const beforeUpload = (file) => {
    const isImages = file.type === 'image/png' || file.type === 'image/jpg'
    if (!isImages) {
      message.error('You can only upload PNG/JPG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    dispatch({
      type: 'setting_qr/upload_setting_qr',
      payload: {
        token,
        logo: file,
        settingQrcodeId: dataUpdate?.id
      }
    })
    return isImages ? true : Upload.LIST_IGNORE
  }

  const withCustomText = (size) => {
    switch (size) {
      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        return setWithText(true)
      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        return setWithText(true)
      case 'A0_10x10_TEXT':
        return setWithText(true)
      case 'A4_10x10_TEXT':
        return setWithText(true)
      case 'MEDIUM':
        return setWithText(true)
      default:
        return setWithText(true)
    }
  }

  const onHandleUpdate = () => {
    const urlScanPage = items?.urlScanPage === 'DEFAULT' ? null : items?.urlScanPage

    const data = {
      size: onHandleSizeValtoKey(items?.size),
      name: items?.name,
      urlScanPage: urlScanPage,
      textInQrcode: items?.textInQrcode
    }
    dispatch({
      type: 'setting_qr/update_setting_qr',
      payload: {
        token,
        settingQrcodeId: dataUpdate.id,
        data: data
      }
    })
  }

  return (
    <Modal
      title='Update Setting QR '
      visible={visible}
      footer={false}
      onCancel={onCancel}
      wrapClassName='modal-setting-qr'
    >
      <Form layout='vertical' form={form}>
        <Form.Item
          name='logo'
          label='Upload Logo'
        >
          <Upload
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            listType='picture-card'
            fileList={[
              {
                name: `${items?.name}.jpg`,
                url: items?.logo,
                status: 'done'
              }
            ]}
            maxCount={1}
          >
            <CameraOutlined />
          </Upload>
        </Form.Item>
        <FormItem
          value={items?.name}
          prefix={<ToolOutlined />}
          label='Setting Name'
          onChange={({ target }) => setItems({ ...items, name: target.value })}
          placeholder='Setting name'
        />
        <FormItem
          label='Choose size QR'
          value={items?.size}
          placeholder='Setinng size QR'
          select
          onChange={(value) => setItems({ ...items, size: value })}
        >
          {settingData.map((setting) => (
            <Select.Option key={setting.size}>{setting.label}</Select.Option>
          ))}
        </FormItem>
        <FormItem
          label='Choose Scan Page'
          placeholder='Setting scan page'
          select
          onChange={(value) => setSelect(value)}
          value={select}
        >
          <Select.Option key='DEFAULT'>Default</Select.Option>
          <Select.Option key='CUSTOM'>Custom Page</Select.Option>
        </FormItem>
        {select === 'CUSTOM' &&
          <FormItem
            value={items?.urlScanPage}
            label='Custom URL Page'
            placeholder='example: https://rifus.id/product-baju-example'
            onChange={({ target }) => setItems({ ...items, urlScanPage: target.value })}
          />}

        {withText && (
          <FormItem
            textarea
            value={items?.textInQrcode}
            label='Custom Text Scan QR Code'
            placeholder='SCAN QR CODE INI UNTUK KEASLIAN PRODUK'
            onChange={({ target }) => setItems({ ...items, textInQrcode: target.value })}
          />
        )}
        <Row justify='end'>
          <AButton
            onClick={onHandleUpdate}
            loading={loading}
            icon={<UploadOutlined />}
            type='primary'
            title='Submit'
          />
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerSettingQR }) => {
  return { ...reducerSettingQR }
}

export const UpdateSettingQR = connect(mapStateToProps)(_UpdateSettingQR)

_UpdateSettingQR.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  reset: PropTypes.bool,
  token: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  dataUpdate: PropTypes.object
}
