/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { message } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { serviceProfile } from 'src/services/api/profiles'

import { actions } from './actions'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'profile/set_state',
    payload
  })
}

export function * getProfiles ({ payload }) {
  const { token } = payload
  const response = yield call(serviceProfile.get, token)

  if (response.status === 200) {
    yield setState({
      credit: response.data.data.role === 'sub_account' ? response.data.data.master.account.credit : response.data.data.account.credit,
      profile: response.data.data
    })
    // eslint-disable-next-line no-undef
    if (response.data.data.role === 'sub_account') {
      localStorage.setItem('credit', response.data.data.master.account.credit)
    } else {
      localStorage.setItem('credit', response.data.data.account.credit)
    }
  } else {
    localStorage.removeItem('token')
    window.location.replace('/login')
  }
}

export function * updateProfile ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceProfile.update, token, { data })

  if (response.status === 202) {
    yield load(false)
    yield setState({
      credit: response.data.data.account.credit,
      profile: response.data.data
    })
    message.success('Update profile success!')
  } else {
    yield load(false)
    response.data.message.map(msg => {
      return (
        message.error(msg))
    })
  }
}

export function * updatePassword ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceProfile.updatePassword, token, { data })

  if (response.status === 202) {
    yield load(false)
    message.success('Update password success!')
  } else {
    yield load(false)
    response.data.message.map(msg => {
      return (
        message.error(msg))
    })
  }
}

export function * updateEmail ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceProfile.updateEmail, token, { data })

  if (response.status === 202) {
    yield load(false)
    message.success('Send Code success!')
  } else {
    yield load(false)
    message.error(response.data.message)
  }
}

export function * verifyEmail ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceProfile.verifyEmail, token, { data })

  if (response.status === 202) {
    yield load(false)
    message.success('Update email success!')
  } else {
    yield load(false)
    message.error(response.data.message)
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_profile, getProfiles),
    takeEvery(actions.update_profile, updateProfile),
    takeEvery(actions.update_password, updatePassword),
    takeEvery(actions.update_email, updateEmail),
    takeEvery(actions.verify_email, verifyEmail)
  ])
}
