/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/transaction-affiliates/actions'
import { serviceTAffiliate } from 'src/services/api/transactionAffiliate'
// import { message } from 'antd'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'affiliates/set_state',
    payload
  })
}

export function * getAffiliates ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceTAffiliate.get, token, params)

  if (response.status === 200) {
    yield setState({
      dataAffiliates: response?.data?.data?.items,
      meta: response?.data?.data?.meta,
      loading: false
    })
  } else yield load(false)
}

export function * getInfo ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceTAffiliate.info, token, params)

  if (response.status === 200) {
    yield setState({
      dataInfo: response?.data?.data,
      loading: false
    })
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_affiliates, getAffiliates),
    takeEvery(actions.get_affiliates_info, getInfo)
  ])
}
