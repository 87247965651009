import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceFacebookPixel = {
  get: (token) => helper.get('api/v1/facebook-pixels', token),
  getQR: (qrcodeId) => helper.get('api/v1/facebook-pixels/' + qrcodeId),
  create: (token, data) => helper.post('api/v1/facebook-pixels', data, token),
  delete: (token) =>
    axios.delete(apiBaseUrl + 'api/v1/facebook-pixels', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
