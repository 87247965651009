/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Typography, Form, Button, Layout, Select } from 'antd'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Content } from 'antd/lib/layout/layout'
import { useForm } from 'antd/lib/form/Form'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { connect } from 'react-redux'

const FORM_INITIAL = {
  id: '',
  landing: '',
  button: '',
  submitEvent: '',
  events: []
}

export const _FacebookPixel = ({
  loading,
  dataPixels,
  token,
  dispatch
}) => {
  const [form] = useForm()
  const [formData, setFormData] = useState(FORM_INITIAL)
  const [selectedEvent, setEvent] = useState([])
  const events = [
    'AddPaymentInfo',
    'AddtoCart',
    'AddtoWishlist',
    'Purchase',
    'Lead',
    'CompleteRegistration',
    'Search',
    'ViewContent',
    'InitiateCheckout'
  ]

  useEffect(() => {
    dispatch({
      type: 'facebook_pixel/get_facebook_pixel',
      payload: {
        token
      }
    })
  }, [])

  useEffect(() => {
    // const arr = []
    if (dataPixels) {
      setFormData((prevState) => ({
        ...prevState,
        id: dataPixels.pixelId,
        events: dataPixels.events
      }))
      dataPixels?.events?.map((event) => {
        if (event.position === 'landing-page-scan') {
          setFormData((prevState) => ({
            ...prevState,
            landing: event.eventName
          }))
          form.setFieldsValue({
            'landing-page-scan': event.eventName
          })
        } else if (event.position === 'submit') {
          setFormData((prevState) => ({
            ...prevState,
            submitEvent: event.eventName
          }))
          form.setFieldsValue({
            submit: event.eventName
          })
        } else {
          setFormData((prevState) => ({
            ...prevState,
            button: event.eventName
          }))
          form.setFieldsValue({
            'button-popup-coupon': event.eventName
          })
        }
        return event.eventName
      })
      form.setFieldsValue({
        id: dataPixels.pixelId
      })
    }
  }, [dataPixels])

  const handleChange = (e) => {
    const { target } = e
    const { id, value } = target

    setFormData((prevState) => ({ ...prevState, [id]: value }))
  }

  const handleSelect = (value, position) => {
    const selectedEvents = [...selectedEvent]
    const currentEvents = [...formData.events]
    const index = currentEvents.findIndex((f) => f.position === position)
    const indexSelected = selectedEvents.findIndex((f) => f === value)

    if (index !== -1) {
      currentEvents.splice(index, 1)
      setFormData((prevState) => ({
        ...prevState,
        events: [
          ...currentEvents,
          {
            position,
            eventName: value
          }
        ]
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        events: [
          ...formData.events,
          {
            position,
            eventName: value
          }
        ]
      }))
    }

    if (indexSelected !== -1) {
      selectedEvents.splice(index, 1)
      selectedEvents.push(value)
      setEvent(selectedEvents)
    } else {
      selectedEvents.push(value)
      setEvent(selectedEvents)
    }
  }

  const submit = async () => {
    const data = {
      pixelId: formData.id,
      events: formData.events
    }

    dispatch({
      type: 'facebook_pixel/create_facebook_pixel',
      payload: {
        token,
        data
      }
    })
    form.resetFields()
  }

  // const onDelete = () => {
  //   dispatch({
  //     type: 'facebook_pixel/delete_facebook_pixel',
  //     payload: {
  //       token
  //     }
  //   })
  // }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Gap height='1rem' />
          {/* <Button type='ghost' htmlType='tes' loading={loading} onClick={onDelete}>
            Delete
          </Button> */}
          <Form onFinish={submit} autoComplete='off' form={form}>
            <Row
              gutter={12}
              style={{
                padding: '16px 24px',
                marginLeft: 0,
                marginRight: 0,
                backgroundColor: '#fff',
                borderRadius: '7px'
              }}
            >
              <Col span={24} style={{ marginBottom: 10 }}>
                <Gap height='1rem' />
                <FormItem
                  col={24}
                  name='id'
                  placeholder='Pixel ID'
                  onChange={handleChange}
                />
                <Typography.Title level={5}>Event Pixel</Typography.Title>
                <Row gutter={16} className='align-items-center'>
                  <FormItem
                    col={12}
                    placeholder={`Landing Page Scan ${
                      formData.landing !== '' ? '- ' + formData.landing : ''
                    }`}
                    disabled
                  />
                  <FormItem
                    rules={[rules.required]}
                    col={12}
                    select
                    placeholder='Select Event'
                    name='landing-page-scan'
                    onChange={(e) => handleSelect(e, 'landing-page-scan')}
                    value={formData.landing}
                  >
                    {events.map(
                      (event, index) =>
                        !selectedEvent.includes(event) && (
                          <Select.Option key={index} value={event}>
                            {event}
                          </Select.Option>
                        )
                    )}
                  </FormItem>
                </Row>
                <Row gutter={16} className='align-items-center'>
                  <FormItem
                    col={12}
                    placeholder={`Button Popup Coupon ${
                      formData.button !== '' ? '- ' + formData.button : ''
                    }`}
                    disabled
                  />
                  <FormItem
                    rules={[rules.required]}
                    col={12}
                    select
                    placeholder='Select Event'
                    name='button-popup-coupon'
                    onChange={(e) => handleSelect(e, 'button-popup-coupon')}
                  >
                    {events.map(
                      (event, index) =>
                        !selectedEvent.includes(event) && (
                          <Select.Option key={index} value={event}>
                            {event}
                          </Select.Option>
                        )
                    )}
                  </FormItem>
                </Row>
                <Row gutter={16} className='align-items-center'>
                  <FormItem
                    col={12}
                    placeholder={`Submit Coupon ${
                      formData.submitEvent !== '' ? '- ' + formData.submitEvent : ''
                    }`}
                    disabled
                  />
                  <FormItem
                    rules={[rules.required]}
                    col={12}
                    select
                    placeholder='Select Event'
                    name='submit'
                    onChange={(e) => handleSelect(e, 'submit')}
                    value={formData.submitEvent}
                  >
                    {events.map(
                      (event, index) =>
                        !selectedEvent.includes(event) && (
                          <Select.Option key={index} value={event}>
                            {event}
                          </Select.Option>
                        )
                    )}
                  </FormItem>
                </Row>
              </Col>
              <Row justify='end'>
                <Button type='primary' htmlType='submit' loading={loading}>
                  Submit
                </Button>
              </Row>
            </Row>
          </Form>
          <Gap height='2rem' />
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerFacebookPixel }) => {
  return { ...reducerFacebookPixel }
}

export const FacebookPixel = connect(mapStateToProps)(_FacebookPixel)

_FacebookPixel.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  dataPixels: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
