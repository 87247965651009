/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Content } from 'antd/lib/layout/layout'
import {
  Popconfirm,
  Button,
  Layout,
  Space,
  Form,
  Table,
  Menu,
  Image,
  Avatar,
  Row,
  Col,
  Dropdown
} from 'antd'
import {
  DeleteOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { CreateCampaign } from './CreateCoupon'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { CSVLink } from 'react-csv'
// import { UpdateSettingQR } from './UpdateSettingQR'
import './style/style.css'

export const _Coupon = ({ token, dispatch, data, dataMaster, dataDownload, loading, meta }) => {
  const downloadCsvRef = useRef()
  const navigation = useNavigate()
  const [filename, setFileName] = useState('')
  const [visible, setVisible] = useState(false)
  const [updateItems, setUpdateItems] = useState({
    viible: false,
    data: null
  })

  const [value, setValue] = useState('')
  const [form] = Form.useForm()
  const text = 'Are you sure to delete this task?'

  const columns = [
    // {
    //   title: 'No',
    //   dataIndex: 'no',
    //   width: 200,
    //   render: (text) => <a>{text}</a>
    // },
    {
      title: 'Product Name',
      dataIndex: 'product',
      align: 'center',
      render: (item, obj) => (
        <a>{item.name}</a>
        // <Avatar
        //   shape='square'
        //   size={34}
        //   icon={<Image src={obj?.logo} alt='logo' />}
        // />
      )
    },
    {
      title: 'Code Coupon',
      dataIndex: 'coupon',
      align: 'center'
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      align: 'center',
      render: (item) => {
        return (
          <div>
            {item > 100 ? `Rp${Intl.NumberFormat('id').format(item)}` : `${item}%`}
          </div>
        )
      }
    },
    {
      title: 'Fix Price',
      dataIndex: 'fixprice',
      align: 'center',
      render: (item, obj) => {
        return (
          <div className='flex'>
            Rp{Intl.NumberFormat('id').format(obj.product?.price - obj.discountCalculated?.priceDiscounted)}
            <span
              className='ml-2' style={{
                textDecoration: 'line-through',
                color: '#1486C9',
                fontSize: 10
              }}
            >
              Rp{Intl.NumberFormat('id').format(obj.product?.price)}
            </span>
          </div>
        )
      }

    },
    {
      title: 'Periode',
      dataIndex: 'periode',
      align: 'center',
      render: (txt, obj) => moment(obj.startDate).format('L') + ' - ' + moment(obj.endDate).format('L')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 300,
      align: 'center',
      render: (txt, obj) => {
        return (
          <Space>
            <Button
              size='small'
              className='button-detail'
              onClick={() => onHandlePreview(obj)}
            >
              <EyeOutlined />
            </Button>
            {/* <Button
              size='small'
              className='button-update'
              onClick={() => onHandleUpdate(obj)}
            >
              <EditOutlined />
            </Button> */}
            <Popconfirm
              placement='topLeft'
              title={text}
              onConfirm={() => onHandleDelete(obj)}
              okText='Yes'
              cancelText='No'
            >
              <Button size='small' className='button-delete'>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Dropdown overlay={<DownloadMenu couponId={obj.id} downloadCsv={downloadCsv} />}>
              <Button
                size='small'
                className='button-upload'
              >
                <DownloadOutlined />
                Export Data
              </Button>
            </Dropdown>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'coupon/get_coupon',
      payload: { token }
    })
    dispatch({
      type: 'product_master/get_product_master',
      payload: { token }
    })
  }, [])

  useEffect(() => {
    if (dataDownload) {
      downloadCsvRef.current?.link?.click()
    }
  }, [dataDownload])

  const onHandleSearch = () => {
    dispatch({
      type: 'coupon/get_coupon',
      payload: { token, params: { coupon: value } }
    })
  }

  const onHandleCreateCampaign = () => {
    navigation('/dashboard/coupon/create', { state: token })
  }

  const onHandleDelete = (items) => {
    dispatch({
      type: 'coupon/delete_coupon',
      payload: { token, couponId: items.id }
    })
  }

  // const onHandleUpdate = (items) => {
  //   navigation(`/dashboard/coupon/${items.id}/update`, { state: items })
  // }

  const onHandlePreview = (items) => {
    navigation(`/dashboard/coupon/${items.id}/detail`, { state: items })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'coupon/get_coupon',
      payload: { token, params: { page: page } }
    })
  }

  const onFilterSelect = (productId) => {
    dispatch({
      type: 'coupon/get_coupon',
      payload: { token, params: { productId } }
    })
  }
  const onClearFilter = (page) => {
    dispatch({
      type: 'coupon/get_coupon',
      payload: { token, params: { page: page } }
    })
  }

  const downloadCsv = (couponId, type, name) => {
    const filename = `Report_${name}_${moment().format('DD-MM-YYYY')}.csv`
    dispatch({
      type: 'coupon/download_coupon',
      payload: {
        couponId,
        token,
        params: {
          type
        }
      }
    })
    setFileName(filename)
  }

  return (
    <DefaultLayout>
      {dataDownload &&
        <CSVLink
          data={dataDownload}
          filename={filename}
          ref={downloadCsvRef}
          target='_blank'
          style={{ display: 'none' }}
        />}

      <Gap height='1rem' />
      <Layout className='container-roles'>
        <Content className='container'>
          <Header
            simple
            title='Create Coupon'
            type='primary'
            placeholder='search coupon by code coupon'
            icon={<PlusCircleOutlined />}
            onClick={onHandleCreateCampaign}
            onChange={({ target }) => setValue(target.value)}
            onClickSearch={onHandleSearch}
            filter
            data={dataMaster}
            onFilterSelect={onFilterSelect}
            onClearFilter={onClearFilter}
          />
          <Gap height='1rem' />
          {/* <Dropdown overlay={downloadMenu}>
            <Button type='text' style={{ display: 'flex', alignItems: 'center', color: '#1890FF', fontSize: 14 }}>
              <DownloadOutlined style={{ fontWeight: 'bold', fontSize: 16 }} />
              <Gap width='.5rem' />
              Export
            </Button>
          </Dropdown> */}
          <Gap height='1rem' />

          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={data ?? []}
            rowSelection={{
              type: 'checkbox'
            }}
            pagination={
              meta && {
                defaultPageSize: meta.itemsPerPage,
                total: meta.totalItems,
                pageSize: meta.itemsPerPage,
                onChange: (page, pageSize) => onHandlePaginate(page, pageSize)
              }
            }
          />
        </Content>
      </Layout>
      {/*
      <UpdateSettingQR
        visible={updateItems.visible}
        dataUpdate={updateItems.data}
        token={token}
        onCancel={() => setUpdateItems({ visible: false })}
      /> */}
    </DefaultLayout>
  )
}

const mapStateToProps = ({
  reducerCoupons,
  reducerProductMaster
}) => {
  return {
    ...reducerCoupons,
    dataMaster: reducerProductMaster.productMaster
  }
}

export const Coupon = connect(mapStateToProps)(_Coupon)

_Coupon.propTypes = {
  token: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.array,
  dataMaster: PropTypes.array,
  dataDownload: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  loading: PropTypes.bool,
  meta: PropTypes.object
}

const DownloadMenu = ({ couponId, downloadCsv }) => {
  return (
    <Menu>
      <Menu.Item onClick={() => downloadCsv(couponId, 'customer-files', 'Facebook_Customer_Files')}>
        <Row>
          <Col span={24} className='download-data-coupon'>
            Facebook Customer Files
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item onClick={() => downloadCsv(couponId, 'ltv', 'Facebook_Customer_Files_with_LTV')}>
        <Row>
          <Col span={24} className='download-data-coupon'>
            Facebook Customer Files with LTV
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item onClick={() => downloadCsv(couponId, 'offline-conversion', 'Facebook_Offline_Conversion')}>
        <Row>
          <Col span={24} className='download-data-coupon'>
            Facebook Offline Conversion
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  )
}

DownloadMenu.propTypes = {
  couponId: PropTypes.string,
  downloadCsv: PropTypes.func
}
