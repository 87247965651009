import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { connect } from 'react-redux'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { useNavigate } from 'react-router-dom'

import { FormItem } from 'src/components/molecules/FormItem'
import { AButton } from 'src/components/atoms/Button'
import { rules } from 'src/utils/rules'
import { Gap } from 'src/components/atoms/Gap'

export const _Login = ({ dispatch, loading }) => {
  const [form] = Form.useForm()
  const navigation = useNavigate()
  useEffect(() => {

  }, [])

  const onHandleSignIn = () => {
    form.validateFields()
      .then(data =>
        dispatch({
          type: 'credentials/credential_sign_in',
          payload: { params: data }
        })
      )
  }

  return (
    <div className='container-login'>
      <section
        className='hero-section ptb-100 background-img full-screen'
        style={{
          backgroundImage: "url('img/hero-bg-1.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0'>
            <div className='col-md-7 col-lg-6'>
              <div className='hero-content-left text-white'>
                <h1 className='text-white'>Welcome Back !</h1>
                <p className='lead'>
                  Keep your face always toward the sunshine - and shadows will
                  fall behind you.
                </p>
              </div>
            </div>
            <div className='col-md-5 col-lg-5'>
              <div className='card login-signup-card shadow-lg mb-0'>
                <div className='card-body px-md-5 py-5'>
                  <div className='mb-5'>
                    <h5 className='h3'>Sign In</h5>
                    <p className='text-muted mb-0'>
                      Sign in to your account to continue.
                    </p>
                  </div>

                  <Form
                    layout='vertical'
                    form={form}
                    onFinish={onHandleSignIn}
                    className='login-signup-form'
                  >
                    <FormItem
                      className='wrap-input'
                      placeholder='@example: admin_091'
                      name='username'
                      label='Username'
                      rules={[rules.required]}
                      prefix={<UserOutlined />}
                    />
                    <FormItem
                      password
                      name='password'
                      label='Password'
                      rules={[rules.required]}
                      className='wrap-input'
                      placeholder='Input your password'
                      prefix={<LockOutlined />}
                    />
                    <medium className='text-muted text-center'>
                      <a href='' onClick={() => navigation('/forgot-password')}>Forgot Password?</a>
                    </medium>
                    <Gap height='1rem' />
                    <AButton
                      loading={loading}
                      block
                      title='Sign In'
                      htmlType='submit'
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-img-absolute'>
          <img
            src='img/hero-bg-shape-1.svg'
            alt='wave shape'
            className='img-fluid'
          />
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = ({ reducerCredentials }) => {
  return { ...reducerCredentials }
}

export const Login = connect(mapStateToProps)(_Login)

_Login.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool
}
