import React from 'react'
import footerBg from 'src/assets/images/footer-bg.png'
// import { OpimLogo } from 'src/1assets/images/logo-opim-white.png'

const Footer = () => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className='footer-top background-img-2'
          style={{
            backgroundImage: `url('${footerBg}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
            backgroundSize: 'cover'
          }}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-3 mb-3 mb-lg-0'>
                <div className='footer-nav-wrap text-white'>
                  <img
                    src='/img/logo-opim-white.png'
                    alt='footer logo'
                    width='120'
                    className='img-fluid mb-3'
                  />
                  <p>
                    Increase the security of your business and drive leaps and bounds of business value through connectivity
                  </p>

                  <div className='social-list-wrap'>
                    <ul className='social-list list-inline list-unstyled'>
                      <li className='list-inline-item'>
                        <a href='/#' target='_blank' title='Facebook'>
                          <span className='ti-facebook' />
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='/#' target='_blank' title='Twitter'>
                          <span className='ti-twitter' />
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='/#' target='_blank' title='Instagram'>
                          <span className='ti-instagram' />
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='/#' target='_blank' title='printerst'>
                          <span className='ti-pinterest' />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 ml-auto mb-4 mb-lg-0'>
                <div className='footer-nav-wrap text-white'>
                  <h5 className='mb-3 text-white'>Others Links</h5>
                  <ul className='list-unstyled'>
                    <li className='mb-2'>
                      <a href='/#about'>About</a>
                    </li>
                    <li className='mb-2'>
                      <a href='/#features'>Features</a>
                    </li>
                    <li className='mb-2'>
                      <a href='/#pricing'>Pricing</a>
                    </li>
                    {/* <li className='mb-2'>
                      <a href='/#'>Privacy Policy</a>
                    </li>
                    <li className='mb-2'>
                      <a href='/#'>Terms and Conditions</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className='col-lg-3 ml-auto mb-4 mb-lg-0'>
                <div className='footer-nav-wrap text-white'>
                  <h5 className='mb-3 text-white'>Support</h5>
                  <ul className='list-unstyled support-list'>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-location-pin mr-2' />
                      JL. Bandeng 3 No.20 Minomartani
                      <br />
                      ngaglik, Sleman, Yogyakarta
                    </li>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-mobile mr-2' />{' '}
                      <a href='tel:+61283766284'> +62 857 5822 2170</a>
                    </li>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-email mr-2' />
                      <a href='mailto:opim.mails@gmail.com'> opim.mails@gmail.com</a>
                    </li>
                    <li className='mb-2 d-flex align-items-center'>
                      <span className='ti-world mr-2' />
                      <a href='/#'> verifikasi.opim.co.id</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className='col-lg-3'>
                <div className='footer-nav-wrap text-white'>
                  <h5 className='mb-3 text-white'>Location</h5>
                  <img
                    src='img/map.png'
                    alt='location map'
                    className='img-fluid'
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='footer-bottom gray-light-bg pt-4 pb-4'>
          <div className='container'>
            <div className='row text-center justify-content-center'>
              <div className='col-md-6 col-lg-5'>
                <p className='copyright-text pb-0 mb-0'>
                  Copyrights © 2021. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
