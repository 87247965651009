/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Content } from 'antd/lib/layout/layout'
import {
  Popconfirm,
  Button,
  Layout,
  Space,
  Form,
  Table,
  Image,
  Avatar
} from 'antd'
import {
  DeleteOutlined,
  PlusCircleOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { CreateSettingQR } from './CreateSettingQR'
import { UpdateSettingQR } from './UpdateSettingQR'
import moment from 'moment'

export const _SettingQR = ({ token, dispatch, data, loading, meta }) => {
  const [visible, setVisible] = useState(false)
  const [updateItems, setUpdateItems] = useState({
    viible: false,
    data: null
  })

  const [value, setValue] = useState('')
  const [form] = Form.useForm()
  const text = 'Are you sure to delete this task?'

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Images',
      dataIndex: 'images',
      align: 'center',
      render: (item, obj) =>
        <Avatar
          shape='square'
          size={34}
          icon={<Image src={obj?.logo} alt='logo' />}
        />
    },
    {
      title: 'Size',
      dataIndex: 'size',
      align: 'center'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      align: 'center',
      render: (item) => <p>{moment(item).format('YYYY-MM-DD, HH:mm')} WIB</p>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 300,
      align: 'center',
      render: (txt, obj) => {
        return (
          <Space>
            {/* <Button
              size='small'
              className='button-detail'
            >
              <EyeOutlined />
            </Button> */}
            <Button
              size='small'
              className='button-update'
              onClick={() => setUpdateItems({ visible: true, data: obj })}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              placement='topLeft'
              title={text}
              onConfirm={() => onHandleDelete(obj.id)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                size='small'
                className='button-delete'
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'setting_qr/get_setting_qr',
      payload: { token }
    })
  }, [])

  const onHandleSearch = () => {
    dispatch({
      type: 'setting_qr/get_setting_qr',
      payload: { token, params: { name: value } }
    })
  }

  const onHandleCreateSettingQR = () => {
    form.validateFields().then(items => {
      dispatch({
        type: 'setting_qr/create_setting_qr',
        payload: {
          data: items,
          token
        }
      })
    })
  }

  const onHandleDelete = (id) => {
    dispatch({
      type: 'setting_qr/delete_setting_qr',
      payload: { token, settingID: id }
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'setting_qr/get_setting_qr',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-roles'>
        <Content className='container'>
          <Header
            simple
            title='Create Setting QR'
            type='primary'
            placeholder='search qr by username or logo'
            icon={<PlusCircleOutlined />}
            onClick={() => setVisible(!visible)}
            onChange={({ target }) =>
              setValue(target.value)}
            onClickSearch={onHandleSearch}
          />
          <Gap height='1rem' />
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={data ?? []}
            rowSelection={{
              type: 'checkbox'
            }}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
          />
        </Content>
      </Layout>
      <CreateSettingQR
        visible={visible}
        form={loading ? form.resetFields() : form}
        onFinish={onHandleCreateSettingQR}
        loading={loading}
        onCancel={() => setVisible(!visible)}
      />
      <UpdateSettingQR
        visible={updateItems.visible}
        dataUpdate={updateItems.data}
        token={token}
        onCancel={() => setUpdateItems({ visible: false })}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerSettingQR }) => {
  return { ...reducerSettingQR }
}

export const SettingQR = connect(mapStateToProps)(_SettingQR)

_SettingQR.propTypes = {
  token: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  meta: PropTypes.object
}
