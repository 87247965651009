import React from 'react'
import _data from 'src/data'

const TrustedCompany = () => {
  return (
    <>
      <section className='client-section ptb-100 gray-light-bg'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='section-heading text-center mb-5'>
                <h2>Sudah dipercaya oleh:</h2>
                {/* <p className='lead'>
                  Rapidiously morph transparent internal or "organic" sources whereas
                  resource sucking e-business. Conveniently innovate compelling internal.
                </p> */}
              </div>
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-md-12'>
              <div className='owl-carousel owl-theme clients-carousel dot-indicator'>
                {(_data.trustedCompanyAboutUs.companies || []).map(
                  (company, index) => {
                    return (
                      <div className='item single-client' key={index}>
                        <img
                          src={company}
                          alt='client logo'
                          className='client-img'
                        />
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TrustedCompany
