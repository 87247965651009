import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import moment from 'moment'

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const ChartLine = ({ datasets, dateFrom, dateTo, charts }) => {
  const [labels, setLabels] = useState([])
  const konversi = datasets.findIndex(f => f.label === 'Konversi')
  const sales = datasets.findIndex(f => f.label === 'Pendapatan')
  const guest = datasets.findIndex(f => f.label === 'Pengunjung')
  let maxIncomes = Math.max.apply(Math, charts?.map(v => v.totalIncomes))

  if (maxIncomes) {
    const dataCount = maxIncomes + (maxIncomes * 0.25)

    maxIncomes = dataCount.toFixed(0)
  }

  const maxVisitors = Math.max.apply(Math, charts?.map(v => v.totalVisitors))
  // const maxIncomes = Math.max.apply(Math, dataTraffics.map(v => v.totalSales))
  // const filter = datasets.length
  // console.log('FILTER', datasets.length)z

  const data = {
    labels: labels,
    datasets
  }
  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      y: {
        type: 'linear',
        // display: sales !== -1 && (datasets.length === 2 || datasets.length === 1),
        display: sales !== -1 && datasets.length === 1,
        grid: {
          drawOnChartArea: sales !== -1 && datasets.length === 2
        },
        min: 0,
        max: maxIncomes,
        ticks: {
          color: datasets.length === 2 && '#0A94F4',
          callback: function (value) {
            return Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value)
          }
        }
      },
      y1: {
        type: 'linear',
        position: konversi !== -1 || datasets.length === 1 ? 'left' : 'right',
        // display: guest !== -1 && (datasets.length === 2 || datasets.length === 1),
        display: guest !== -1 && datasets.length === 1,
        grid: {
          drawOnChartArea: guest !== -1 && datasets.length === 1
        },
        min: 0,
        max: maxVisitors === 0 ? 200 : maxVisitors * 2,
        ticks: {
          color: datasets.length === 2 && '#2DCEAE'
        }
      },
      y2: {
        type: 'linear',
        position: datasets.length === 1 ? 'left' : 'right',
        // display: konversi !== -1 && (datasets.length === 2 || datasets.length === 1),
        display: konversi !== -1 && datasets.length === 1,
        grid: {
          drawOnChartArea: konversi !== -1 && datasets.length === 1
        },
        min: 0,
        max: 200,
        ticks: {
          color: datasets.length === 2 && '#C9DD07'
        }
      }
    },
    chart: {
      canvas: {
        parentNode: {
          style: {
            height: 50
          }
        }
      }
    },
    // layout: {
    //   padding: {
    //     left: 50,
    //     right: 130
    //   }
    // },
    plugins: {
      legend: {
        align: 'start',
        labels: {
          boxWidth: 8,
          boxHeight: 8
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || ''
            const value = context.parsed.y

            switch (label) {
              case 'Pendapatan':
                return `${label} : ` + Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value)
              case 'Pengunjung':
                return `${label} : ` + value
              case 'Konversi':
                return `${label} : ` + value.toFixed(2)
              default:
            }
          }
        }
      }
    }
  }
  const plugins = [{
    beforeInit: (chart) => {
      const fitValue = chart.legend.fit

      chart.legend.fit = function fit () {
        fitValue.bind(chart.legend)()
        // eslint-disable-next-line no-return-assign
        return this.height += 30
      }
    }
  }]

  useEffect(() => {
    if (charts) {
      const rev = charts.sort((a, b) => b.no - a.no)
      // console.log('reversed', reversed)

      const labels = rev.map(v => moment(v.date).format('MM-DD'))
      setLabels(labels)
    } else {
      const arrDate = []
      let i = 10
      let j = 0
      do {
        arrDate.push(moment().subtract(i, 'days').format('MM-DD'))
        i = i - 1
      }
      while (i > 0)
      if (i === 0) {
        do {
          arrDate.push(moment().add(j, 'days').format('MM-DD'))
          j++
        }
        while (j < 10)
      }
      setLabels(arrDate)
    }
  }, [charts])

  return <Line options={options} data={data} plugins={plugins} />
}

ChartLine.propTypes = {
  datasets: PropTypes.array,
  dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  charts: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}

ChartLine.defaultProps = {
  datasets: [{}]
}
