import helper from '../helper'

export const serviceGenerate = {
  get: (token, params) => helper.get('api/v1/qrcodes', token, params),
  generate: (token, { params }) => helper.post('api/v1/qrcodes/generate', params, token),
  regenerate: (token, { generateId, data }) => helper.patch('api/v1/qrcodes/regenerate/' + generateId, data, token),
  detail: (token, { generateId }) => helper.get('api/v1/qrcodes/' + generateId, token),
  download: (token, { generateId }) => helper.get('api/v1/qrcodes/download-pdf/' + generateId, token),
  printRequest: (token, { data }) => helper.post('api/v1/print-requests', data, token),
  update: (token, { generateId, data }) => helper.patch('api/v1/qrcodes/' + generateId, data, token)
}
