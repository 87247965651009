import React, { forwardRef } from 'react'
import { PropTypes } from 'prop-types'
import styles from 'src/pages/generates-qr/styles/percetakan/a4/qr-10x10.module.css'

const PrintPage = forwardRef((props, ref) => {
  const page = Array.from({ length: parseInt(Math.ceil(props?.data?.length / 70)) }, (x, i) => i * 70)
  const mapItem = (page) => {
    return (
      <div className={styles.page}>
        {
          props?.data?.map((item, index) => {
            index++
            return (index > page && index < page + 71) && (
              <div className={item.expiredAt == null && item.limitCheck == null ? styles.label_unique_id : styles.label} key={index}>
                <img src={item.url} alt={item?.uniqueCode} />
                {
                  item.expiredAt && item.limitCheck !== null
                    ? <span>{item?.uniqueCode}</span>
                    : (item.expiredAt == null && item.limitCheck == null)
                        ? <span>{item?.uniqueCode}</span>
                        : null
                }
              </div>
            )
          })
        }
      </div>
    )
  }
  return (
    <div ref={ref} className={styles.paper} id='paper'>
      {
        page?.map((item, index) => {
          return mapItem(item)
        })
      }
    </div>
  )
})

export default PrintPage

PrintPage.propTypes = {
  data: PropTypes.array
}
