/* eslint-disable no-undef */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/products-master/actions'
import { serviceProductMaster } from 'src/services/api/productMaster'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'product_master/set_state',
    payload
  })
}

export function * getProductsMaster ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceProductMaster.get, token, params)
  if (response.status === 200) {
    yield setState({
      productMaster: response.data.data.items,
      meta: response.data.data.meta,
      loading: false
    })
  } yield load(false)
}

export function * createProductMaster ({ payload }) {
  yield load(true)
  const { token, limitationData } = payload

  const newData = new FormData()
  newData.append('name', limitationData.name)
  newData.append('sku', limitationData.sku)
  newData.append('price', limitationData.price)
  newData.append('phone', limitationData.phone)
  newData.append('distributedBy', limitationData.distributedBy)
  newData.append('producedBy', limitationData.producedBy)
  newData.append('category', limitationData.category)
  newData.append('shortDescription', limitationData.name)
  newData.append('description', limitationData.description)
  limitationData?.images?.fileList.forEach(image =>
    newData.append('images', image.originFileObj))
  limitationData?.licenses?.forEach((license, index) => {
    newData.append('licenses[' + index + '][type]', license.type)
    newData.append('licenses[' + index + '][no]', license.no)
  })
  limitationData?.sosmed?.socials?.forEach((social, index) => {
    newData.append('socials[' + index + '][name]', social?.name)
    newData.append('socials[' + index + '][url]', social?.url)
  })
  limitationData?.attributes?.forEach((attribute, index) => {
    newData.append('attributes[' + index + '][key]', attribute?.key)
    newData.append('attributes[' + index + '][value]', attribute?.value)
  })

  const response = yield call(serviceProductMaster.create, token, { params: newData })
  if (response.status === 201) {
    yield setState({
      loading: false,
      popupSuccess: true
    })
  } else {
    yield load(false)
    if (response.status === 400) {
      response.data.message.map(errorData => {
        const messageText = errorData.charAt(0).toUpperCase() + errorData.slice(1)
        message.error(messageText)
        return false
      })
    } else if (response.status === 500) {
      message.error('Terjadi kesalahan. Hubungi admin')
    } else {
      message.error(`${response.data.message}`)
    }
  }
}

export function * deleteProductMaster ({ payload }) {
  yield load(true)
  const { token, productId } = payload

  const response = yield call(serviceProductMaster.delete, token, { productId })
  if (response.status === 202) {
    yield put({
      type: 'product_master/get_product_master',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Product Success!',
      description: 'Product delete for permanent '
    })
  } yield load(false)
}

export function * uploadCertificate ({ payload }) {
  yield load(true)
  const { token, productId, data } = payload

  const newData = new FormData()
  newData.append('file', data?.file)
  newData.append('type', data?.type)
  newData.append('name', data?.name)

  const response = yield call(serviceProductMaster.upload, token, { productId, data: newData })
  if (response.status === 202) {
    yield setState({
      loading: false,
      close: true
    })
    notification.warning({
      message: 'Upload Certificate Product Success!',
      description: 'Please check preview for more details'
    })
  } else {
    yield load(false)
    notification.error({
      message: 'Upload Certificate Product Failed!',
      description: response.data.message
    })
  }
}

export function * updateProductMaster ({ payload }) {
  yield load(true)
  const { token, productId, data } = payload

  const response = yield call(serviceProductMaster.patch, token, productId, data)
  if (response.status === 202) {
    yield setState({
      loading: false,
      data: response.data.data,
      popupSuccess: true
    })
  } else {
    yield load(false)
    if (response.status === 500) {
      message.error(`Error ${response.status}`)
    } else {
      response.data.message.map(errorData => {
        const messageText = errorData.charAt(0).toUpperCase() + errorData.slice(1)
        message.error(messageText)
        return false
      })
    }
  }
}

export function * getOneProductMaster ({ payload }) {
  yield load(true)
  const { token, productId } = payload

  const response = yield call(serviceProductMaster.one, token, productId)
  if (response.status === 200) {
    yield setState({
      type: 'product_master/get_one',
      loading: false,
      data: response.data.data
    })
  }
}

export function * uploadImage ({ payload }) {
  yield load(true)
  const { token, productId, params } = payload
  const response = yield call(serviceProductMaster.uploadImage, token, productId, params)

  if (response.status === 202) {
    yield setState({
      loading: false,
      dataUploadSuccess: response.data.data,
      type: 'product_master/upload_image'
    })
    message.success('Upload success')
  } else {
    message.error(response.data.message)
  }
}

export function * removeImage ({ payload }) {
  yield load(true)
  const { token, productId, params } = payload

  const response = yield call(serviceProductMaster.removeImage, token, productId, params)

  if (response.status === 202) {
    yield setState({
      loading: false,
      // data: response.data.data,
      type: 'product_master/remove_image'
    })

    message.success(response.data.message)
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_product_master, getProductsMaster),
    takeEvery(actions.delete_product_master, deleteProductMaster),
    takeEvery(actions.upload_product_master, uploadCertificate),
    takeEvery(actions.create_product_master, createProductMaster),
    takeEvery(actions.update_product_master, updateProductMaster),
    takeEvery(actions.get_one_product_master, getOneProductMaster),
    takeEvery(actions.upload_image_product_master, uploadImage),
    takeEvery(actions.remove_image_product_master, removeImage)
  ])
}
