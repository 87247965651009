/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

export const PrivateRouter = ({ children }) => {
  const auth = useAuth()
  return auth ? children : <Navigate to='/' />
}

export const ReverseRouter = ({ children }) => {
  const auth = useAuth()
  return auth ? <Navigate to='/dashboard' /> : children
}

const useAuth = () => {
  const isAuthorized = localStorage.getItem('token')

  return isAuthorized
}

PrivateRouter.propTypes = {
  children: PropTypes.object
}

ReverseRouter.propTypes = {
  children: PropTypes.object
}
