import React from 'react'
import PropTypes from 'prop-types'
import {
  DatePicker,
  Menu,
  Row,
  Col
} from 'antd'
import moment from 'moment'
import './styles/style.css'

export const TrafficFilter = ({ onFilterType, onSelectDateF, onSelectDateT, handleMenuClick }) => {
  const [mode, setMode] = React.useState(false)
  const [filter, setFilter] = React.useState('Kemarin')

  const handleChange = (event, type) => {
    setMode(false)
    if (type === 'Kemarin') {
      const start = moment().subtract(1, 'days').format('YYYY-MM-DD')
      const end = moment().subtract(1, 'days').format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    } else if (type === '7 Hari Terakhir') {
      const start = moment().subtract(7, 'days').format('YYYY-MM-DD')
      const end = moment().subtract(1, 'days').format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    } else if (type === '30 Hari Terakhir') {
      const start = moment().subtract(30, 'days').format('YYYY-MM-DD')
      const end = moment().subtract(1, 'days').format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    } else if (type === 'Pilihan') {
      const start = moment(event[0]).format('YYYY-MM-DD')
      const end = moment(event[1]).format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    } else if (type === 'Hari') {
      const start = moment(event).format('YYYY-MM-DD')
      const end = moment(event).format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    } else if (type === 'Minggu') {
      const start = moment(event).startOf('week').format('YYYY-MM-DD')
      const end = moment(event).endOf('week').format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    } else {
      const start = moment(event).startOf('month').format('YYYY-MM-DD')
      const end = moment(event).endOf('month').format('YYYY-MM-DD')

      setFilter(type)
      onFilterType(type)
      onSelectDateF(start)
      onSelectDateT(end)
    }
    handleMenuClick()
  }

  const onChangeMode = (type) => {
    setMode(true)
    setFilter(type)
  }

  const disabledDate = (current) => {
  // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  return (
    <Menu>
      <Row className='date-wrapper' style={mode && { minWidth: '30rem' }}>
        <Col span={mode ? 7 : 24}>
          <div
            className={`date-menu ${filter === 'Kemarin' && 'active'}`}
            onClick={() => handleChange(null, 'Kemarin')}
          >
            Kemarin
            {!mode &&
              <span>
                ({`${moment().subtract(1, 'days').format('YYYY-MM-DD')} ~ ${moment().subtract(1, 'days').format('YYYY-MM-DD')}`})
              </span>}
          </div>
          <div
            className={`date-menu ${filter === '7 Hari Terakhir' && 'active'}`}
            onClick={() => handleChange(null, '7 Hari Terakhir')}
          >
            7 Hari Terakhir
            {!mode &&
              <span>
                ({`${moment().subtract(7, 'days').format('YYYY-MM-DD')} ~ ${moment().subtract(1, 'days').format('YYYY-MM-DD')}`})
              </span>}
          </div>
          <div
            className={`date-menu ${filter === '30 Hari Terakhir' && 'active'}`}
            onClick={() => handleChange(null, '30 Hari Terakhir')}
          >
            30 Hari Terakhir
            {!mode &&
              <span>
                ({`${moment().subtract(30, 'days').format('YYYY-MM-DD')} ~ ${moment().subtract(1, 'days').format('YYYY-MM-DD')}`})
              </span>}
          </div>
          <div
            className={`date-menu ${filter === 'Hari' && 'active'}`}
            onClick={() => onChangeMode('Hari')}
          >
            Hari
          </div>
          <div
            className={`date-menu ${filter === 'Minggu' && 'active'}`}
            onClick={() => onChangeMode('Minggu')}
          >
            Minggu
          </div>
          <div
            className={`date-menu ${filter === 'Bulan' && 'active'}`}
            onClick={() => onChangeMode('Bulan')}
          >
            Bulan
          </div>
          <div
            className={`date-menu ${filter === 'Pilihan' && 'active'}`}
            onClick={() => onChangeMode('Pilihan')}
          >
            Pilihan
          </div>
        </Col>
        {mode &&
          <Col span={17} className='date-expand'>
            {filter === 'Pilihan' && <DatePicker.RangePicker onChange={(event) => handleChange(event, 'Pilihan')} />}
            {filter === 'Hari' && <DatePicker onChange={(event) => handleChange(event, 'Hari')} style={{ width: '100%' }} />}
            {filter === 'Minggu' && <DatePicker onChange={(event) => handleChange(event, 'Minggu')} picker='week' style={{ width: '100%' }} />}
            {filter === 'Bulan' && <DatePicker onChange={(event) => handleChange(event, 'Bulan')} picker='month' disabledDate={disabledDate} style={{ width: '100%' }} />}
          </Col>}

      </Row>
    </Menu>
  )
}

TrafficFilter.propTypes = {
  onFilterType: PropTypes.func,
  onSelectDateF: PropTypes.func,
  onSelectDateT: PropTypes.func,
  handleMenuClick: PropTypes.func
}
