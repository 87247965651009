import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Modal, Row, message } from 'antd'
import {
  UserSwitchOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'
import { rules } from 'src/utils/rules'
import { FormItem } from 'src/components/molecules/FormItem'
import { AButton } from 'src/components/atoms/Button'
import { useLocation } from 'react-router-dom'

const _UpdateWithdraw = ({ token, dispatch, loading, visible, onCancel, items, balance }) => {
  const [form] = Form.useForm()
  const { state } = useLocation()

  useEffect(() => {
    if (items) {
      form.setFieldsValue({
        balance: balance,
        withdraw: Math.abs(items?.nominal),
        note: items?.description
      })
    }
  }, [items, balance])

  const onHandleUpdate = () => {
    form.validateFields().then(data => {
      if (Number(data?.withdraw) < 500000) {
        message.error('Minimal withdraw Rp500.000')
      } else {
        dispatch({
          type: 'admin_affiliate/update_affiliates',
          payload: {
            token,
            id: items?.id,
            body: {
              nominal: Number(data?.withdraw),
              description: data?.note
            },
            affiliateId: state?.id
          }
        })
      }
    })
    onCancel()
  }

  return (
    <Modal
      title='Update Withdraw'
      visible={visible}
      onCancel={onCancel}
      footer={false}
      forceRender
    >
      <Form onFinish={onHandleUpdate} form={form} layout='vertical'>
        <FormItem
          prefix='Rp'
          label='Saldo'
          name='balance'
          disabled
        />
        <FormItem
          rules={[rules.required]}
          prefix='Rp'
          label='Withdraw'
          placeholder='500.000'
          name='withdraw'
        />
        <FormItem
          rules={[rules.required]}
          prefix={<UserSwitchOutlined />}
          label='Note'
          name='note'
          textarea
          placeholder='add your note'
        />
        <Row justify='end'>
          <AButton
            loading={loading}
            icon={<PlusCircleOutlined />}
            title='update'
            type='primary'
          />
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerDistros }) => {
  return { ...reducerDistros }
}

export const UpdateWithdraw = connect(mapStateToProps)(_UpdateWithdraw)

_UpdateWithdraw.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  balance: PropTypes.number
}
