import { DefaultLayout } from 'src/layouts/default'
import React, { useEffect, useState } from 'react'
import { Gap } from 'src/components/atoms/Gap'
import guides from 'src/data/panduan'
import {
  // Button,
  Layout,
  // Popconfirm,
  // Space,
  // Table,
  // Avatar,
  // Modal,
  Card,
  Tabs,
  Row,
  Col
  // Pagination
} from 'antd'

const { TabPane } = Tabs

const _GuideIndex = () => {
  const [guideThumbSize, setGuideThumbSize] = useState({ width: '100%', height: 'auto' })
  const [guidesData, setGuidesData] = useState([])

  // const onHandleTab = (key) => {
  //   console.info(key)
  // }

  const handleSizeGuide = () => {
    const guideEl = document.querySelector('.iframe-video-guide')

    if (guideEl) {
      const widthGuide = guideEl.offsetWidth
      const heightGuide = widthGuide / 2

      setGuideThumbSize({ width: '100%', height: `${heightGuide}px` })
    }
  }

  const getGuidesData = (guidesData) => {
    setGuidesData(guidesData)
  }

  useEffect(() => {
    getGuidesData(guides)
    return () => {
      handleSizeGuide()
    }
  }, [guidesData])

  window.addEventListener('resize', () => {
    handleSizeGuide()
  })

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-guides'>
        <Layout.Content className='container'>
          <Card>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='Videos' key='1'>
                <Row gutter={12}>
                  {
                    guidesData?.map((guide, index) =>
                      <Col key={index} s={12} md={12} lg={8} xl={8}>
                        <Card>
                          <div style={{ margin: '-24px -24px 0 -24px' }}>
                            <iframe className='iframe-video-guide' src={guide.video} style={guideThumbSize} allow='autoplay' />
                          </div>
                          <h5 style={{ padding: '1rem 0px' }}>
                            {guide.title}
                          </h5>
                        </Card>
                        <Gap height='.5rem' />
                      </Col>
                    )
                  }
                </Row>
              </TabPane>
              <TabPane tab='PDF' key='2'>
                <Row gutter={12}>
                  {
                    guidesData?.map((guide, index) =>
                      <Col key={index} s={12} md={12} lg={8} xl={8}>
                        <Card>
                          <div style={{ margin: '-24px -24px 0 -24px' }}>
                            <iframe className='iframe-video-guide' src={guide.pdf} style={guideThumbSize} allow='autoplay' />
                          </div>
                          <h5 style={{ padding: '1rem 0px' }}>
                            {guide.title}
                          </h5>
                        </Card>
                        <Gap height='.5rem' />
                      </Col>
                    )
                  }
                </Row>
              </TabPane>
            </Tabs>
          </Card>
        </Layout.Content>
      </Layout>
    </DefaultLayout>
  )
}

export const GuideIndex = _GuideIndex
