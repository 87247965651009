import React from 'react'
import { connect } from 'react-redux'
import MockupOpim from 'src/assets/images/mockup-opim.png'

const HeroSection = () => {
  return (
    <>
      <section
        className='hero-section pt-10 background-img'
        style={{
          backgroundImage: 'http://appco-react.themetags.com/img/app-hero-bg.jpg',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between hero-content'>
            <div className='col-md-7 col-lg-6'>
              <div className='hero-content-left text-white'>
                <h1 className='text-white'>
                  <span>Relakah</span> Produk Anda Dipalsu Begitu Saja?
                </h1>
                <p className='lead'>
                  Lindungi Produk Anda dari Pemalsuan Menggunakan OPIM
                </p>
                <a href='#promo' className='btn google-play-btn mt-3 page-scroll'>Pelajari Selengkapnya</a>

              </div>
            </div>
            <div className='col-md-5 col-lg-5'>
              <div className='hero-animation-img'>
                <img
                  src={MockupOpim}
                  alt='app'
                  className='img-fluid mockup-image'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-img-absolute'>
          <img
            src='img/hero-bg-shape-1.svg'
            alt='wave shape'
            className='img-fluid'
          />
        </div>
      </section>
    </>
  )
}

export default connect(state => ({
  state
}))(HeroSection)
