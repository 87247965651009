import helper from '../helper'

export const serviceCredentials = {
  get: (token, params) => helper.get('api/auth/login', token, params),
  verify: (token, params) => helper.get('api/auth/verify-email', null, params),
  post: (params, token) => helper.post('api/auth/login', params),
  register: (params, token) => helper.post('api/auth/register', params),
  forgotPassword: (params, token) => helper.post('api/auth/forgot-password', params),
  resetPassword: (params, token) => helper.post('api/auth/reset-password', params)
}
