import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceUsers = {
  get: (token, params) => helper.get('api/v1/users', token, params),
  create: (token, { data }) => helper.post('api/v1/users', data, token),
  credit: (token, { amount, userId, price }) => helper.post('api/v1/transactions-admin', { userId, credit: amount, price }, token),
  update: (token, { data, userId }) => helper.patch(`api/v1/users/${userId}`, data, token),
  delete: (token, { userId }) =>
    axios.delete(apiBaseUrl + `api/v1/users/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
