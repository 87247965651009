import React from 'react'

const Download = () => {
  return (
    <>
      <section
        className='download-section pt-100 background-img'
        style={{
          backgroundImage: "url('img/app-hero-bg.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-md-7'>
              <div className='download-content text-white pb-100'>
                <h2 className='text-white'>
                  Lindungi produk Anda dari pemalsuan menggunakan OPIM sekarang!
                  <br />
                  #OPIMBeraniMelindungi
                </h2>
                <p className='lead'>
                  GARANSI 100% uang Kembali jika kode QR tidak bekerja!
                </p>

                <div className='download-btn'>
                  <a href='/#' className='btn google-play-btn mr-3'>
                    Hubungi kami
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='download-img d-flex align-bottom'>
                {/* <img
                  src='img/app-hand-top.png'
                  alt='download'
                  className='img-fluid'
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Download
