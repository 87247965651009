module.exports = {
  hero: {
    title: 'Brainstorming for desired perfect Usability',
    description:
      'Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!',
    bgImage: 'img/app-hero-bg.jpg'
  },
  promo: {
    title: 'Apa itu OPIM?',
    description:
      'OPIM adalah sebuah platform yang dapat memproduksi jutaan kode QR unik tanpa ada satupun yang sama. OPIM menawarkan sebuah solusi untuk melindungi produk Anda dari pemalsuan dilengkapi dengan fitur analisis yang lengkap.',
    items: [
      {
        title: 'Generate Unique QR Code',
        description:
          'Kode QR canggih yang bisa memproduksi jutaan kode QR unik tanpa ada satupun yang sama',
        icon: 'ti-vector text-white'
      },
      {
        title: 'QR Manage System',
        description:
          'Anda bisa mengatur alur kode QR untuk distributor, agen, maupun reseller Anda',
        icon: 'ti-lock text-white'
      },
      {
        title: 'Memodifikasi Tautan',
        description:
          'Anda dengan bebas memodifikasi desain kode QR, halaman landing QR, menautkan website, marketplace, sosial media, dan tautan lainnya. ',
        icon: 'ti-eye text-white'
      },
      {
        title: 'Memasukan Logo',
        description:
          'Anda dapat memasukkan logo Asosiasi, perusahaan, merek ke kode QR yang diterbitkan.',
        icon: 'ti-eye text-white'
      },
      {
        title: 'Managemen Warehouse yang terintegrasi langsung dengan kode QR',
        description:
          'Analytics System, fitur analisis yang dapat membantu Anda untuk mengukur dan mengoptimasi produk melalui kode QR secara menyeluruh dengan fitur scan tracking, facebook offline conversion, email report, data downloading.',
        icon: 'ti-eye text-white'
      }
    ]
  },
  aboutUs: {
    title: {
      bold: '',
      normal: 'Produk apa yang bisa OPIM lindungi dari pemalsuan?'
    },
    description:
      'Ratusan jenis produk bisa OPIM lindungi dari pemalsuan, beberapa di antaranya adalah : ',
    items: [
      {
        title: 'Obat-obatan'
      },
      {
        title: 'Herbal dan Jamu'
      },
      {
        title: 'Kosmetik'
      },
      {
        title: 'Skin Care'
      },
      {
        title: 'Fashion'
      },
      {
        title: 'Buku'
      },
      {
        title: 'Perhiasan'
      },
      {
        title: 'Perkakas'
      },
      {
        title: 'Dan ratusan lainnya'
      }
    ]
  },
  price: {
    title: 'Pricing Packages',
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: '0',
        license: 'Free',
        isFeatured: false,
        type: 'free_master',
        path: '/register',
        features: [
          '50 Credit',
          'Top Up Credit',
          '1 Account Master',
          'Product / Log',
          'Generate QR'
        ]
      },
      {
        price: '100000',
        license: 'Basic',
        type: 'silver_master',
        isFeatured: false,
        path: '/register',
        features: [
          '250 Credit',
          'Top Up Credit',
          '1 Account Master',
          'Agent System',
          'Free Watermark',
          'Setting QR',
          'Product / Log',
          'Generate QR'
        ]
      },
      {
        price: '1000000',
        license: 'Standart',
        isFeatured: false,
        type: 'gold_master',
        path: '/register',
        features: [
          '4000 Credit',
          'Top Up Credit',
          '1 Account Master',
          'Agent System',
          '2 Sub Account',
          'Free Watermark',
          'Setting QR',
          'Product / Log',
          'Generate QR'
        ]
      },
      {
        price: '15000000',
        license: 'Corporate',
        isFeatured: false,
        type: 'platinum_master',
        path: '/register',
        features: [
          '100,000 Credit',
          'Top Up Credit',
          '1 Account Master',
          'Agent System',
          '4 Sub Account',
          'Free Watermark',
          'Setting QR',
          'Product / Log',
          'Generate QR'
        ]
      },
      {
        price: 'Special Price',
        license: 'Special',
        isFeatured: false,
        type: 'special_master',
        path: 'https://api.whatsapp.com/send?phone=6287874231127',
        features: [
          '> 200,000 Credit',
          'Top Up Credit',
          '1 Account Master',
          'Agent System',
          '4 Sub Account',
          'Free Watermark',
          'Setting QR',
          'Product / Log',
          'Generate QR'
        ]
      }
    ]
  },
  testimonial: {
    title: 'What customer say about us',
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: 'Arabella Ora',
        company: 'AgencyCo',
        image: 'img/client-2.jpg',
        comment:
          'Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively.'
      },
      {
        name: 'John Charles',
        company: 'ThemeTags',
        image: 'img/client-1.jpg',
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },
      {
        name: 'Jeremy Jere',
        company: 'WorldCheck',
        image: 'img/client-3.jpg',
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: 'Ciaran Kierce',
        company: 'AppCo',
        image: 'img/client-4.jpg',
        comment:
          'I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all.'
      }
    ]
  },
  contact: {
    title: 'Contact with us',
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",

    office: 'Head Office',
    address: '121 King St, Melbourne VIC 3000, Australia',
    phone: '+61 2 8376 6284',
    email: 'hello@yourdomain.com'
  },
  trustedCompany: [
    'img/client-1-color.png',
    'img/client-6-color.png',
    'img/client-3-color.png',
    'img/client-4-color.png',
    'img/client-5-color.png'
  ],
  trustedCompanyGray: [
    'img/client-1-gray.png',
    'img/client-2-gray.png',
    'img/client-3-gray.png',
    'img/client-4-gray.png',
    'img/client-5-gray.png'
  ],
  trustedCompanyAboutUs: {
    title: 'Trusted by companies',
    description: 'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    companies: [
      'img/company-1.png',
      'img/company-2.png',
      'img/company-3.4.svg'
    ]
  },
  teamMember: {
    members: [
      {
        name: 'Edna Mason',
        designation: 'Senior Designer',
        image: 'img/team-4.jpg'
      },
      {
        name: 'Arabella Ora',
        designation: 'Senior Designer',
        image: 'img/team-1.jpg'
      },
      {
        name: 'John Charles',
        designation: 'Senior Designer',
        image: 'img/team-2.jpg'
      },
      {
        name: 'Jeremy Jere',
        designation: 'Senior Designer',
        image: 'img/team-3.jpg'
      }
    ]
  },
  video: {
    link: 'https://www.youtube.com/watch?v=9No-FiEInLA'
  }
}
