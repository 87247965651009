import React from 'react'

import HeroSection from 'src/components/organizes/HeroSection/HeroSection404'

export const NotFound = () => {
  return (
    <>
      <div className='main'>
        <HeroSection />
      </div>
    </>
  )
}
