import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import moment from 'moment'
import {
  Button,
  Layout,
  Popconfirm,
  Space,
  Table,
  Avatar,
  Modal,
  Card,
  Row,
  Col,
  Pagination
} from 'antd'
import {
  // ExperimentTwoTone,
  DeleteOutlined,
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  EditOutlined,
  StarTwoTone,
  EyeOutlined,
  BugTwoTone
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { UploadCertificates } from './UploadCertificates'
import iconHerbal from 'src/assets/images/icon-herbal.png'

const _Product = ({ dispatch, token, loading, dataProducts, meta }) => {
  const navigation = useNavigate()
  const [value, setValue] = useState('')
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10
  })

  const text = 'Are you sure to delete this task?'
  const [visibleUpload, setVisibleUpload] = useState(false)
  const [visibleProduct, setVisibleProduct] = useState(false)
  const customVal = value?.length <= 0 ? '' : search

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      width: 200
    },
    {
      title: 'SKU',
      width: 150,
      align: 'center',
      dataIndex: 'sku'
    },
    {
      title: 'Price',
      align: 'center',
      width: 110,
      dataIndex: 'price'
    },
    {
      title: 'Production Date',
      width: 130,
      align: 'center',
      dataIndex: 'productionDate',
      render: (date, obj) => moment(date).format('L')
    },
    {
      title: 'Expired Date',
      width: 150,
      dataIndex: 'expiredAt',
      render: (date, obj) => moment(date).format('L')
    },
    {
      title: 'Distributed by',
      width: 150,
      align: 'center',
      dataIndex: 'distributedBy'
    },
    {
      title: 'Status',
      width: 150,
      align: 'center',
      dataIndex: 'pending',
      render: (item, obj) => !item ? <CheckCircleTwoTone style={{ fontSize: 24 }} /> : <ClockCircleTwoTone style={{ fontSize: 24 }} />
    },
    {
      title: 'Images Product',
      width: 150,
      align: 'center',
      dataIndex: 'images',
      render: () => <Avatar shape='square' />
    },
    {
      title: 'Produced by',
      width: 150,
      dataIndex: 'producedBy'
    },
    {
      title: 'Category',
      width: 150,
      dataIndex: 'category'
    },
    {
      title: 'Licenses',
      width: 150,
      align: 'center',
      render: () => <Button type='primary'>Detail</Button>
    },
    {
      title: 'Short Description',
      width: 250,
      dataIndex: 'shortDescription',
      render: (text, obj) => text.length > 50 ? text.substr(0, 50) + '...' : text
    },
    {
      title: 'Action',
      fixed: 'right',
      dataIndex: 'action',
      width: 200,
      align: 'center',
      render: (txt, obj) => (
        <Space>
          <Button
            size='small'
            className='button-detail'
            onClick={() => onHandlePreview(obj)}
          >
            <EyeOutlined />
          </Button>
          <Button
            disabled
            size='small'
            onClick={() => onHandleUpdate(obj)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement='topLeft'
            title={text}
            okText='Yes'
            cancelText='No'
            onConfirm={() => onHandleDeleteProduct(obj)}
          >
            <Button size='small' className='button-delete'>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'product/get_product',
      payload: {
        token,
        params: {
          name: customVal,
          page: pagination.page
        }
      }
    })
  }, [pagination, search, customVal])

  const onHandleCreate = () => {
    navigation('/dashboard/product/create')
  }

  const onHandleUpdate = ({ id }) => {
    navigation(`/dashboard/product/${id}/update`)
  }

  const onHandlePreview = (items) => {
    navigation(`/dashboard/product/${items.id}/detail`, { state: items })
  }

  const onHandleDeleteProduct = (items) => {
    dispatch({
      type: 'product/delete_product',
      payload: { token, productId: items.id }
    })
  }

  const onHandlePagination = (page, perPage) => {
    setPagination({
      ...pagination,
      page,
      perPage
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-products'>
        <Layout.Content className='container'>
          <Header
            simple
            type='primary'
            placeholder='search by type or name product'
            onClickSearch={() => setSearch(value)}
            onChange={({ target }) =>
              setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            columns={columns}
            loading={loading}
            dataSource={dataProducts}
            scroll={{ x: 1500 }}
            pagination={false}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
          <Row justify='end'>
            <Pagination
              defaultCurrent={pagination.page}
              total={meta?.totalItems}
              pageSize={pagination.perPage}
              onChange={(page, pageSize) =>
                onHandlePagination(page, pageSize)}
            />
          </Row>
        </Layout.Content>
      </Layout>

      <Modal
        footer={false}
        visible={visibleProduct}
        title='Product Type'
        onCancel={() => setVisibleProduct(!visibleProduct)}
      >
        <Row gutter={16} justify='center'>
          <Col md={8}>
            <Card
              hoverable
              onClick={onHandleCreate}
              style={{ textAlign: 'center' }}
            >
              {/* <ExperimentTwoTone style={{ fontSize: 26 }} Herbal/> */}
              <img src={iconHerbal} width={26} />
              <br />
              Herbals
            </Card>
          </Col>
          <Col md={8}>
            <Card
              hoverable
              onClick={onHandleCreate}
              style={{ textAlign: 'center' }}
            >
              <BugTwoTone style={{ fontSize: 26 }} />
              <br />
              Animals
            </Card>
          </Col>
          <Col md={8}>
            <Card
              hoverable
              onClick={onHandleCreate}
              style={{ textAlign: 'center' }}
            >
              <StarTwoTone style={{ fontSize: 26 }} />
              <br />
              Material
            </Card>
          </Col>
        </Row>
      </Modal>
      <UploadCertificates
        onCancel={() => setVisibleUpload(!visibleUpload)}
        visible={visibleUpload}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerProducts }) => {
  return { ...reducerProducts }
}

export const Product = connect(mapStateToProps)(_Product)

_Product.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  dataProducts: PropTypes.array,
  meta: PropTypes.object
}
