/* eslint-disable react/react-in-jsx-scope */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { serviceSubAccount } from 'src/services/api/subAccounts'
import { actions } from './actions'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'sub_account/set_state',
    payload
  })
}

export function * getSubAccount ({ payload }) {
  yield load(true)
  const { token, params } = payload
  const response = yield call(serviceSubAccount.get, token, params)

  if (response.status === 200) {
    yield setState({
      loading: false,
      data: response.data.data.items,
      meta: response.data.data.meta
    })
  } else yield load(false)
}

export function * createSubAccount ({ payload }) {
  yield load(true)
  const { token, data } = payload

  const response = yield call(serviceSubAccount.create, token, { data })
  if (response.status === 201) {
    yield setState({
      popupSuccess: true,
      loading: false
    })
    notification.success({
      message: 'Create Sub Account Success!',
      description: 'Please set access permission for this account'
    })
  } else {
    yield load(false)
    notification.error({
      message: 'Failed to Create Sub Account!',
      description: (
        <ul key='alert'>
          {response?.data?.message?.map((item, index) =>
            <li key={index}>{item}</li>
          )}
        </ul>
      )
    })
  }
}

export function * updateSubAccount ({ payload }) {
  yield load(true)
  const { token, data } = payload

  const response = yield call(serviceSubAccount.update, token, { data })
  if (response.status === 201) {
    message.success('created sub-account success')
    yield setState({
      popupSuccess: true,
      loading: false
    })
  } else {
    yield load(false)
    message.error([
      <ul key='alert'>
        {response?.data?.message?.map((item, index) =>
          <li key={index}>{item}</li>
        )}
      </ul>
    ])
  }
}

export function * setAccess ({ payload }) {
  yield load(true)
  const { token, data, value, checked, subAccountId } = payload
  const check = value ? 'On' : 'Off'
  const descChecked = checked.replace('access', ' ')
  const response = yield call(serviceSubAccount.access, token, { data, subAccountId })
  if (response.status === 200) {
    yield put({
      type: 'sub_account/get_subaccount',
      payload: { token }
    })
    notification.success({
      message: `${descChecked} ${check}`,
      description: 'Please check your sub-account for detail permission'
    })
  } else yield load(false)
}

export function * deleteSubAccount ({ payload }) {
  yield load(true)
  const { token, subAccountId } = payload
  const response = yield call(serviceSubAccount.delete, token, { subAccountId })
  if (response.status === 200) {
    yield put({
      type: 'sub_account/get_subaccount',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Sub Account Success!',
      description: 'Sub account delete for permanent '
    })
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_subaccount, getSubAccount),
    takeEvery(actions.access_subaccount, setAccess),
    takeEvery(actions.create_subaccount, createSubAccount),
    takeEvery(actions.delete_subaccount, deleteSubAccount)
  ])
}
