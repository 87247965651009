import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  Popconfirm,
  Button,
  Layout,
  Avatar,
  Form,
  Image,
  Space,
  Table,
  Row,
  Pagination,
  Modal
} from 'antd'
import {
  PlusCircleOutlined,
  TagsOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  DollarOutlined
} from '@ant-design/icons'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { FormItem } from 'src/components/molecules/FormItem'
import { AButton } from 'src/components/atoms/Button'
import { rules } from 'src/utils/rules'

const { Content } = Layout

export const _Dashboard = ({ dispatch, token, items, loading, meta }) => {
  const [addCredit, setAddCredit] = useState({
    visible: false,
    userId: null,
    amount: null,
    price: 0
  })
  const [value, setValue] = useState('')
  const [search, setSearch] = useState('')
  const [role, setRole] = useState('')
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10
  })
  const navigation = useNavigate()
  const text = 'Are you sure to delete this task?'
  const customVal = value?.length <= 0 ? '' : search

  useEffect(() => {
    setDispatch({
      token,
      params: {
        role,
        fullName: customVal,
        page: pagination.page,
        limit: pagination.perPage
      }
    })
  }, [pagination, search, customVal])

  useEffect(() => {
    setDispatch({
      token,
      params: { role }
    })
  }, [role])

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Name',
      dataIndex: 'username'
    },
    {
      title: 'Credit',
      align: 'center',
      width: 150,
      dataIndex: ['account'],
      render: (item, obj) => obj.account.credit
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      render: (file, obj) => (
        <Avatar
          src={
            <Image
              preview={false}
              src='https://joeschmoe.io/api/v1/random'
              style={{ width: 32 }}
            />
          }
        />
      )
    },
    {
      title: 'Role',
      dataIndex: 'role',
      align: 'center',
      render: (text, obj) => (
        <p
          className={
            text === 'user'
              ? 'text-user'
              : text === 'free_tenant'
                ? 'text-free_tenant'
                : text === 'gold_tenant'
                  ? 'text-gold_tenant'
                  : 'text-admin'
              }
        >
          {text}
        </p>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 300,
      align: 'center',
      render: (file, obj) => (
        <Space>
          <Button
            size='small'
            className='button-detail'
            onClick={() => onHandleAddCredit(obj)}
          >
            <TagsOutlined /> Add Credit
          </Button>
          <Button
            size='small'
            className='button-detail'
          >
            <EyeOutlined />
          </Button>
          <Button
            size='small'
            className='button-update'
            onClick={() => onHandleUpdate(obj)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement='topLeft'
            title={text}
            onConfirm={() => onHandleDelete(obj?.id)}
            okText='Yes'
            cancelText='No'
          >
            <Button
              size='small'
              className='button-delete'
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  const onHandleSearch = () => {
    setSearch(value)
  }

  const setDispatch = (payload) => {
    return dispatch({
      type: 'users/get_users',
      payload
    })
  }

  const onHandlePagination = (page, perPage) => {
    setPagination({
      ...pagination,
      page,
      perPage
    })
  }

  const onHandleCreate = () => {
    navigation('/create')
  }

  const onHandleUpdate = (items) => {
    navigation(`/dashboard/${items.id}/update`, { state: items })
  }

  const onFilterSelect = (value) => {
    setRole(value)
  }

  const onClearFilter = (value) => {
    if (value === undefined) setRole('')
  }

  const onHandleDelete = (userId) => {
    dispatch({
      type: 'users/delete_users',
      payload: {
        token, userId
      }
    })
  }

  const onHandleAddCredit = ({ id, account: { credit } }) => {
    setAddCredit({
      visible: true,
      userId: id,
      amount: credit,
      price: 0
    })
  }

  const onHandleSubmitCredit = () => {
    dispatch({
      type: 'users/add_credit',
      payload: {
        token,
        amount: Number(addCredit.amount),
        userId: addCredit.userId,
        price: Number(addCredit.price)
      }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Header
            simple
            type='primary'
            title='Create User'
            placeholder='search by username or fullname'
            icon={<PlusCircleOutlined />}
            onClick={onHandleCreate}
            onFilterSelect={onFilterSelect}
            onClickSearch={onHandleSearch}
            onClearFilter={onClearFilter}
            onChange={({ target }) =>
              setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            rowSelection={{
              type: 'checkbox'
            }}
            loading={loading}
            columns={columns}
            dataSource={items}
            pagination={false}
          />
          <Gap height='2rem' />
          <Row justify='end'>
            <Pagination
              defaultCurrent={pagination.page}
              total={meta?.totalItems}
              pageSize={pagination.perPage}
              onChange={(page, pageSize) =>
                onHandlePagination(page, pageSize)}
            />
          </Row>
          <Gap height='2rem' />
        </Content>
      </Layout>
      <Modal
        title='Add Credit'
        footer={false}
        visible={addCredit.visible}
        centered
        onCancel={() => setAddCredit({ visible: false })}
      >
        <Form layout='vertical'>
          <FormItem
            rules={[rules.required]}
            prefix={<TagsOutlined style={{ paddingRight: '.5rem' }} />}
            placeholder='add your credit'
            label='Amount'
            value={addCredit?.amount}
            onChange={({ target }) => setAddCredit({ ...addCredit, amount: target.value })}
            extra='must be a number'
          />
          <FormItem
            rules={[rules.required]}
            prefix={<DollarOutlined />}
            placeholder='add price of amount'
            label='Price'
            onChange={({ target }) => setAddCredit({ ...addCredit, price: target.value })}
            extra='must be a number'
          />
          <Row justify='end'>
            <AButton
              loading={loading}
              onClick={onHandleSubmitCredit}
              title='Add Credit'
              type='primary'
            />
          </Row>
        </Form>
      </Modal>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerUsers }) => {
  return { ...reducerUsers }
}

export const Dashboard = connect(mapStateToProps)(_Dashboard)

_Dashboard.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  meta: PropTypes.object
}
