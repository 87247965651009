import React from 'react'
import PropTypes from 'prop-types'
import { Button, Row, Form } from 'antd'
import {
  PlusCircleOutlined,
  MailOutlined,
  KeyOutlined,
  UserOutlined
} from '@ant-design/icons'

import { Forms } from 'src/components/organizes/Forms'
import { DefaultLayout } from 'src/layouts/default'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { PopUpFinished } from 'src/components/molecules/PopUpFinished'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

const _CreateSubAccount = ({ dispatch, loading, popupSuccess }) => {
  const [form] = Form.useForm()
  const { state } = useLocation()
  const navigation = useNavigate()

  const onHandleSubmit = () => {
    form.validateFields().then(data =>
      dispatch({
        type: 'sub_account/create_subaccount',
        payload: {
          token: state,
          data
        }
      })
    )
  }

  const onHandleNavigation = () => {
    navigation('/dashboard/sub-account')
  }

  const onHandleCreateNew = () => {
    form.resetFields()
    dispatch({
      type: 'sub_account/set_state',
      payload: { popupSuccess: false }
    })
  }

  return (
    <DefaultLayout>
      <Forms
        form={form}
        onFinish={onHandleSubmit}
        titleBar='Create Sub Account'
      >
        <Row gutter={16}>
          <FormItem
            col={12}
            name='username'
            label='Username'
            placeholder='your username'
            rules={[rules.required]}
            prefix={<UserOutlined />}
          />
          <FormItem
            col={12}
            name='fullName'
            label='Full Name'
            placeholder='your fullname'
            rules={[rules.required]}
            prefix={<UserOutlined />}
          />
        </Row>
        <Row gutter={16}>
          <FormItem
            name='email'
            label='Email'
            placeholder='your email'
            rules={[rules.required]}
            prefix={<MailOutlined />}
          />
        </Row>
        <Row gutter={16}>
          <FormItem
            password
            col={12}
            hasFeedback
            label='Password'
            name='password'
            placeholder='your password'
            extra='required combining with lowercase, uppercase and number'
            prefix={<KeyOutlined />}
            rules={[rules.required]}
          />
          <FormItem
            col={12}
            password
            hasFeedback
            name='confirmPassword'
            label='Confirm Password'
            placeholder='your password'
            dependencies={['password']}
            extra='required combining with lowercase, uppercase and number'
            prefix={<KeyOutlined />}
            rules={[
              rules.required,
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                }
              })
            ]}
          />
        </Row>
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Submit
          </Button>
        </Row>
      </Forms>
      <PopUpFinished
        popupSuccess={popupSuccess}
        onClickCreateNew={() => onHandleCreateNew()}
        onClickCheckList={() => onHandleNavigation()}
        buttonRight='Check Sub Account'
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerSubAccount }) => {
  return { ...reducerSubAccount }
}

export const CreateSubAccount = connect(mapStateToProps)(_CreateSubAccount)

_CreateSubAccount.propTypes = {
  loading: PropTypes.bool,
  popupSuccess: PropTypes.bool,
  dispatch: PropTypes.func
}
