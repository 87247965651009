/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/credentials/actions'
import { serviceCredentials } from 'src/services/api/credentials'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'credentials/set_state',
    payload
  })
}

export function * credentialSignIn ({ payload }) {
  yield load(true)
  const { params } = payload

  const response = yield call(serviceCredentials.post, params)
  if (response && response.status === 202) {
    yield load(false)
    localStorage.setItem('token', response.data.data.token)
    localStorage.setItem('role', response.data.data.user.role)
    localStorage.setItem('subaccount', JSON.stringify(response.data.data.user.subAccountSetting))
    localStorage.setItem('credit', response.data.data.user.account.credit)
    localStorage.setItem('username', response.data.data.user.username)

    yield setState({
      auth: {
        token: response.data.data.token,
        fullname: response.data.data.user.fullName,
        credit: response.data.data.user.account.credit
      }
    })
    notification.success({
      duration: 2,
      message: 'Login Success!',
      description: 'Welcome to Dashboard ' + response?.data?.data?.user?.fullName
    })
  } else {
    if (response.data.message.toLowerCase() === 'invalid credentials') {
      notification.error({
        message: 'Login Failed!',
        description: 'Please check your username or password'
      })
    } else {
      notification.error({
        message: 'Login Failed!',
        description: response.data.message
      })
    }
    yield load(false)
  }
}

export function * credentialRegister ({ payload }) {
  yield load(true)
  const { params } = payload
  const response = yield call(serviceCredentials.register, params)

  if (response.status === 202) {
    message.success('Register Success')
    yield setState({
      register: 'success',
      loading: false
    })
  } else {
    const description = response.data.error === 'Conflict'
      ? response.data.message
      : response.data.message.length === 1
        ? response.data.message
        : 'please check your fields'

    message.error(description)
    yield load(false)
  }
}

export function * credentialVerifyEmail ({ payload }) {
  yield load(true)
  const { token, email } = payload

  const response = yield call(serviceCredentials.verify, null, { token, email })
  if (response.status === 202) {
    yield setState({
      loading: false,
      message: response.data.data.message
    })
  } else {
    yield setState({
      loading: false,
      message: response.data.data.message
    })
  }
}

export function * credentialForgotPassword ({ payload }) {
  // console.log('as')
  yield load(true)
  const { params } = payload
  const response = yield call(serviceCredentials.forgotPassword, params)

  if (response.status === 202) {
    notification.success({
      message: response.data.message
    })
    yield setState({
      loading: false,
      message: response.data.message
    })
  } else {
    notification.error({
      message: response.data.message
    })
    yield setState({
      loading: false,
      message: response.data.message
    })
  }
}

export function * credentialResetPassword ({ payload }) {
  yield load(true)
  const { params } = payload
  const response = yield call(serviceCredentials.resetPassword, params)

  if (response.status === 202) {
    notification.success({
      message: response.data.message + '. Please login...'
    })
    yield setState({
      loading: false,
      message: response.data.message
    })
  } else {
    notification.error({
      message: response.data.message
    })
    yield setState({
      loading: false,
      message: response.data.message
    })
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.credential_sign_in, credentialSignIn),
    takeEvery(actions.credential_register, credentialRegister),
    takeEvery(actions.verify_email, credentialVerifyEmail),
    takeEvery(actions.credential_forgot_password, credentialForgotPassword),
    takeEvery(actions.credential_reset_password, credentialResetPassword)
  ])
}
