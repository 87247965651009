import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { rules } from 'src/utils/rules'
import { Form } from 'antd'
import { FormItem } from 'src/components/molecules/FormItem'
import { AButton } from 'src/components/atoms/Button'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'

export const _ForgotPassword = ({ dispatch, loading }) => {
  const [form] = Form.useForm()
  const navigation = useNavigate()

  useEffect(() => {

  }, [])

  const onHandleForgotPassword = () => {
    form.validateFields()
      .then(data =>
        dispatch({
          type: 'credentials/credential_forgot_password',
          payload: { params: data }
        })
      )
  }

  return (
    <section className='hero-section full-screen gray-light-bg'>
      <div className='container-fluid'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block'>
            <div
              className='bg-cover vh-100 ml-n3 background-img'
              style={{
                backgroundImage: 'url(img/hero-bg-3.jpg)'
              }}
            >
              <div className='position-absolute login-signup-content'>
                <div className='position-relative text-white col-md-12 col-lg-7'>
                  <h2 className='text-white'>
                    Forgot Password ? <br /> Don't Worry You Can Reset
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-4'>
            <div className='login-signup-wrap px-4 px-lg-5 my-5'>
              <h1 className='text-center mb-1'>Password Reset</h1>
              <p className='text-muted text-center mb-5'>
                Enter your username to get a password reset link.
              </p>

              <Form
                layout='vertical'
                form={form}
                onFinish={onHandleForgotPassword}
              >
                <FormItem
                  className='wrap-input'
                  placeholder='@example: admin_091'
                  name='username'
                  rules={[rules.required]}
                  prefix={<UserOutlined />}
                />
                <AButton
                  loading={loading}
                  block
                  title='Send'
                  htmlType='submit'
                />
                <p className='text-center'>
                  <small className='text-muted text-center'>
                    Remember your password? <a href='' onClick={() => navigation('/login')}>Log in</a>.{' '}
                  </small>
                </p>
              </Form>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ reducerCredentials }) => {
  return { ...reducerCredentials }
}

export const ForgotPassword = connect(mapStateToProps)(_ForgotPassword)

_ForgotPassword.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool
}
