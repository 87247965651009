/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/products/actions'
import { serviceProduct } from 'src/services/api/products'
import { serviceConfirmProduct } from 'src/services/api/confirmProduct'
import { message } from 'antd'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'product/set_state',
    payload
  })
}

export function * getProducts ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceProduct.get, token, params)

  if (response.status === 200) {
    yield setState({
      dataProducts: response?.data?.data?.items,
      meta: response?.data?.data?.meta,
      loading: false
    })
  } else yield load(false)
}

export function * deleteProduct ({ payload }) {
  yield load(true)
  const { token, productId, confirm } = payload
  const response = yield call(serviceProduct.delete, token, { productId })
  if (response.status === 202) {
    yield put({
      type: 'product/get_product',
      payload: { token }
    })
    message.success('delete product success!')
  } else yield load(false)
}

export function * getLogActivity ({ payload }) {
  yield load(true)
  const { token } = payload

  const response = yield call(serviceProduct.log, token)
  if (response.status === 200) {
    yield setState({
      loading: false,
      log: response.data.data.items,
      meta: response.data.data.meta
    })
  } else yield load(false)
}

export function * getConfirmProduct ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceConfirmProduct.get, token, params)
  if (response.status === 200) {
    yield setState({
      dataConfirms: response?.data?.data?.items,
      metaConfirm: response?.data?.data?.meta,
      loading: false
    })
  } else yield load(false)
}

export function * accpetedProduct ({ payload }) {
  yield load(true)
  const { token, pending, productId } = payload

  const response = yield call(serviceConfirmProduct.accepted, token, { pending, productId })

  if (response.status === 202) {
    yield put({
      type: 'product/get_confirm_product',
      payload: {
        token
      }
    })
    message.success('product accepted')
  } else yield load(false)
}

export function * rejectProduct ({ payload }) {
  yield load(true)
  const { token, productId } = payload
  const response = yield call(serviceProduct.delete, token, { productId })
  if (response.status === 202) {
    yield put({
      type: 'product/get_confirm_product',
      payload: { token }
    })
    message.success('rejected product success!')
  } else yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_product, getProducts),
    takeEvery(actions.delete_product, deleteProduct),
    takeEvery(actions.get_activity, getLogActivity),
    takeEvery(actions.accepted_product, accpetedProduct),
    takeEvery(actions.rejected_product, rejectProduct),
    takeEvery(actions.get_confirm_product, getConfirmProduct)
  ])
}
