import React, { useEffect, useState } from 'react'
import { Button, Form, Image, Input, Modal, Row, Space } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  LockOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import ILSuccess from 'src/assets/images/il-success.png'

const _ResetPassword = ({ dispatch, register, loading }) => {
  const { state } = useLocation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [searchParams] = useSearchParams()

  const navigation = useNavigate()

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (register === 'success') {
      setVisible(true)
      form.resetFields()
    }
  }, [register])

  const onHandleResetPassword = () => {
    form.validateFields().then(response => {
      const forms = { ...response }
      forms.registerAs = state

      dispatch({
        type: 'credentials/credential_reset_password',
        payload: {
          params: forms
        }
      })
    })
  }

  const onHandleRedirect = async (params) => {
    navigation(params)
    await dispatch({
      type: 'credentials/set_state',
      payload: {
        register: false
      }
    })
  }

  return (
    <div className='main'>
      <section
        className='hero-section ptb-100 background-img full-screen'
        style={{
          backgroundImage: "url('img/hero-bg-1.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0'>
            <div className='col-md-7 col-lg-6'>
              <div className='hero-content-left text-white'>
                <h1 className='text-white'>Reset Password</h1>
                <p className='lead'>
                  Reset your password
                </p>
              </div>
            </div>
            <div className='col-md-5 col-lg-5'>
              <div className='card login-signup-card shadow-lg mb-0'>
                <div className='card-body px-md-5 py-5'>
                  <div className='mb-5'>
                    <h6 className='h3'>Reset Password</h6>
                    <p className='text-muted mb-0' />
                  </div>
                  <Form
                    className='login-signup-form'
                    form={form}
                    onFinish={onHandleResetPassword}
                    layout='vertical'
                  >
                    <div className='d-none'>
                      <Form.Item name='token' initialValue={searchParams.get('token')}>
                        <Input className='form-control' disabled />
                      </Form.Item>
                      <Form.Item name='username' initialValue={searchParams.get('username')}>
                        <Input className='form-control' disabled />
                      </Form.Item>
                    </div>
                    <Form.Item
                      name='password'
                      label='Password'
                      extra='password must have lower, upper & number.'
                      hasFeedback
                      rules={[{ required: true }]}
                    >
                      <Input.Password
                        className='form-control'
                        placeholder='Enter your password'
                        prefix={<LockOutlined className='mr-2' />}
                      />
                    </Form.Item>
                    <Form.Item
                      name='confirmPassword'
                      label='Confirm Password'
                      hasFeedback
                      extra='password must have lower, upper & number.'
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!'
                        },
                        ({ getFieldValue }) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }

                            return Promise.reject(new Error('The two passwords that you entered do not match!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password
                        className='form-control'
                        placeholder='Enter your confirm password'
                        prefix={<LockOutlined className='mr-2' />}
                      />
                    </Form.Item>
                    <button
                      type='submit'
                      className='btn btn-lg btn-block solid-btn border-radius mt-4 mb-3'
                      id='signIn'
                      disabled={loading}
                    >
                      {loading ? <LoadingOutlined /> : 'Reset Now'}
                    </button>
                    <medium className='text-muted text-center'>
                      Remember your password? <a href='' onClick={() => navigation('/login')}>Log in</a>.{' '}
                    </medium>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-img-absolute'>
          <img src='img/wave-shap.svg' alt='wave shape' className='img-fluid' />
        </div>
      </section>
      <Modal
        centered
        visible={visible}
        title={false}
        closable={false}
        footer={false}
      >
        <div className='text-center'>
          <Image
            src={ILSuccess}
            preview={false}
            style={{ width: 200 }}
          />
          <div className='text-center mb-5'>
            <p className='mt-4 h3'>
              Registration Success!
            </p>
            <p className='mt-3'>
              Verify your email address to sign in,
              <br /> lorem ipsum dolar set amet anuesi karisema
              <br /> ipsum same nanie
            </p>
          </div>
          <Row justify='center'>
            <Space>
              <Button onClick={() => onHandleRedirect('/login')} type='primary'>
                Sign In
              </Button>
              <Button onClick={() => setVisible(!visible)}>
                Register Again
              </Button>
            </Space>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ reducerCredentials }) => {
  return { ...reducerCredentials }
}

export const ResetPassword = connect(mapStateToProps)(_ResetPassword)

_ResetPassword.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  register: PropTypes.string
}
