import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Row,
  Select
} from 'antd'
import {
  PlusCircleOutlined,
  MailOutlined,
  KeyOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Forms } from 'src/components/organizes/Forms'

import { DefaultLayout } from 'src/layouts/default'
import { FormItem } from 'src/components/molecules/FormItem'
import { rules } from 'src/utils/rules'
import { PopUpFinished } from 'src/components/molecules/PopUpFinished'

const _CreateUser = ({ dispatch, token, loading, popupSuccess, items }) => {
  const [form] = Form.useForm()
  const navigation = useNavigate()

  useEffect(() => {
    dispatch({
      type: 'roles/get_roles',
      payload: { token }
    })
  }, [])

  const setDispatch = async (payload) => {
    await dispatch({
      type: 'users/set_state',
      payload
    })
  }

  const onHandleCreateUser = () => {
    form.validateFields().then(data =>
      dispatch({
        type: 'users/create_users',
        payload: {
          token,
          data
        }
      })
    )
  }

  const onHandleCreateNew = () => {
    form.resetFields()
    setDispatch({ popupSuccess: false })
  }

  const onHandleCheckingUser = async () => {
    navigation('/')
    form.resetFields()
    setDispatch({ popupSuccess: false })
  }

  return (
    <DefaultLayout>
      <Forms
        titleBar='Update User'
        form={form}
        onFinish={onHandleCreateUser}
      >
        <Row gutter={16}>
          <FormItem
            rules={[rules.required]}
            prefix={<UserOutlined />}
            col={12}
            label='Username'
            name='username'
            placeholder='your username'
          />
          <FormItem
            rules={[rules.required]}
            prefix={<UserOutlined />}
            col={12}
            label='Full Name'
            name='fullName'
            placeholder='your fullname'
          />
        </Row>
        <Row gutter={16}>
          <FormItem
            rules={[rules.required]}
            prefix={<MailOutlined />}
            name='email'
            col={12}
            label='Email'
            placeholder='your email'
          />
          <FormItem
            label='Choose Role'
            placeholder='Silver'
            name='role'
            select
            col={12}
            rules={[rules.required]}
          >
            {items?.map((item, index) =>
              <Select.Option key={index} value={item?.name}>
                {item?.name}
              </Select.Option>
            )}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            password
            prefix={<KeyOutlined />}
            rules={[rules.required]}
            col={12}
            label='Password'
            name='password'
            hasFeedback
            extra='required combining with lowercase, uppercase and number'
            placeholder='your password'
          />
          <FormItem
            col={12}
            password
            hasFeedback
            prefix={<KeyOutlined />}
            dependencies={['password']}
            name='confirmPassword'
            label='Confirm Password'
            placeholder='your password'
            extra='required combining with lowercase, uppercase and number'
            rules={[
              rules.required,
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                }
              })
            ]}
          />
        </Row>
        <Row justify='end'>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<PlusCircleOutlined />}
          >
            Create User
          </Button>
        </Row>
      </Forms>
      <PopUpFinished
        onClickCheckList={onHandleCheckingUser}
        onClickCreateNew={onHandleCreateNew}
        popupSuccess={popupSuccess}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerUsers, reducerRoles }) => {
  return { ...reducerUsers, ...reducerRoles }
}

export const CreateUser = connect(mapStateToProps)(_CreateUser)

_CreateUser.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  items: PropTypes.array,
  token: PropTypes.string,
  popupSuccess: PropTypes.bool
}
