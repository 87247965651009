/* eslint-disable no-unused-vars */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/distros/actions'
import { serviceDistro } from 'src/services/api/distros'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'distros/set_state',
    payload
  })
}

export function * getDistros ({ payload }) {
  yield load(true)
  const { token, reset, params } = payload
  const response = yield call(serviceDistro.get, token, params)

  if (response.status === 200) {
    yield setState({
      loading: false,
      data: response.data.data.items,
      meta: response.data.data.meta
    })
  } else yield load(false)
}

export function * searchDistros ({ payload }) {
  yield load(true)
  const { token, params } = payload

  const response = yield call(serviceDistro.search, token, params)
  if (response.status === 200) {
    yield setState({
      loading: false,
      data: response.data.data.items,
      meta: response.data.data.meta
    })
  } else yield load(false)
}

export function * createDistro ({ payload }) {
  yield load(false)
  const { token, params } = payload
  const response = yield call(serviceDistro.post, token, params)

  if (response.status === 201) {
    yield put({
      type: 'distros/get_distros',
      payload: { token }
    })
    notification.success({
      message: 'Create Distro Success!',
      description: 'Please check your table list for distro'
    })
  } else {
    yield load(false)
    response.data.message.map(msg => {
      return (
        message.error(msg))
    })
  }
}

export function * deleteDistro ({ payload }) {
  yield load(true)
  const { token, distroID } = payload

  const response = yield call(serviceDistro.delete, token, { distroID })

  if (response.status === 202) {
    yield put({
      type: 'distros/get_distros',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Distro Success!',
      description: 'Distro remove for permanent'
    })
  } else yield load(false)
}

export function * updateDistro ({ payload }) {
  yield load(false)
  const { token, distroId, data } = payload
  const response = yield call(serviceDistro.update, token, { distroId, data })
  if (response.status === 202) {
    yield put({
      type: 'distros/get_distros',
      payload: { token }
    })
    notification.success({
      message: 'Update Distro Success!',
      description: 'Please check your table list for distro'
    })
  } else {
    yield load(false)
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_distros, getDistros),
    takeEvery(actions.delete_distros, deleteDistro),
    takeEvery(actions.update_distros, updateDistro),
    takeEvery(actions.create_distros, createDistro),
    takeEvery(actions.search_distros, searchDistros)
  ])
}
