import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceCoupon = {
  get: (token, params) => helper.get('api/v1/coupons', token, params),
  download: (couponId, token, params) => helper.get('api/v1/coupons/' + couponId + '/download-csv', token, params),
  create: (token, { data }) => helper.post('api/v1/coupons', data, token),
  check: ({ data }) => helper.post('api/v1/coupons/check', data),
  one: (token, couponId) => helper.get('api/v1/coupons/' + couponId, token),
  delete: (token, { couponId }) =>
    axios.delete(apiBaseUrl + `api/v1/coupons/${couponId}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
