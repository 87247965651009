import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Layout, Space, Table, Card, Select, DatePicker, Tag, Col, Input } from 'antd'
import { EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Content } from 'antd/lib/layout/layout'
import { connect } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { AButton } from 'src/components/atoms/Button'

export const _PrintByOpim = ({
  dataPrint,
  loading,
  token,
  dispatch,
  meta
}) => {
  const navigation = useNavigate()
  const [value, setValue] = useState(null)
  const [val, setVal] = useState('')
  const [search, setSearch] = useState('')
  const customVal = val?.length <= 0 ? '' : search

  const columns = [
    {
      title: 'ID',
      dataIndex: 'generateQrcode',
      render: (item) => <p>{item?.customId ?? '-'}</p>
    },
    {
      title: 'Receipt',
      dataIndex: 'noReceipt',
      render: (item) => <p>{item ?? '-'}</p>
    },
    {
      title: 'Expedition',
      dataIndex: 'expedition',
      render: (item) => <p>{item ?? '-'}</p>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (item) => <p>{moment(item).format('YYYY-MM-DD, HH:mm')} WIB</p>
    },
    {
      title: 'Update',
      dataIndex: 'date',
      render: (item) => <p>{moment(item).format('YYYY-MM-DD, HH:mm')} WIB</p>
    },
    {
      title: 'Print Status',
      dataIndex: 'status',
      render: (item) =>
        <p>
          {item === 'NOT_PRINTED' && <Tag color='red'>Not Printed</Tag>}
          {item === 'PRINTED' && <Tag color='green'>Printed</Tag>}
          {item === 'IN_QUEUE' && <Tag color='warning'>In Queue</Tag>}
          {item === 'DELIVERING' && <Tag color='processing'>Delivering</Tag>}
          {item === 'RECEIVED' && <Tag color='green'>Received</Tag>}
          {/* {item ? <Tag color='green'>{obj.size === 'SMALL' ? 'Printed (A4)' : 'Printed (A3)'}</Tag> : <Tag color='red'>Not Printed</Tag>} */}
        </p>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (txt, obj) => (
        <Space>
          <Button
            size='small'
            className='button-detail'
            onClick={() => onHandlePreview(obj)}
          >
            <EyeOutlined />
          </Button>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'print/get_print',
      payload: {
        token,
        params: {
          generateQrcodeCustomId: customVal
        }
      }
    })
  }, [search, customVal])

  // useEffect(() => {
  //   dispatch({
  //     type: 'generate/get_generate_qr',
  //     payload: {
  //       token,
  //       params: {
  //         customId: customVal
  //       }
  //     }
  //   })
  // }, [search, customVal])

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'print/get_print',
      payload: { token, params: { page: page } }
    })
  }

  const onFilterSelect = (val) => {
    setValue(val)
    dispatch({
      type: 'print/get_print',
      payload: { token, params: { status: val } }
    })
  }

  const onClearFilter = (page) => {
    dispatch({
      type: 'print/get_print',
      payload: { token, params: { page: page } }
    })
  }

  const onHandlePreview = ({ id }) => {
    navigation('/dashboard/print-by-opim/' + id + '/detail', { state: id })
  }

  const onHandleDate = (event) => {
    if (event) {
      const start = moment(event[0]).format('YYYY-MM-DD')
      const end = moment(event[1]).format('YYYY-MM-DD')

      dispatch({
        type: 'print/get_print',
        payload: {
          token,
          params: {
            status: value,
            dateFrom: start,
            dateTo: end
          }
        }
      })
    } else {
      dispatch({
        type: 'print/get_print',
        payload: {
          token,
          params: {
            status: value
          }
        }
      })
    }
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Card>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <Col className='mr-2' style={{ width: '100%' }}>
                  <Input
                    allowClear
                    onChange={({ target }) =>
                      setVal(target.value)}
                    placeholder='search request by id'
                    prefix={<SearchOutlined />}
                  />
                </Col>
                <AButton
                  type='primary'
                  title='Search'
                  icon={<SearchOutlined />}
                  onClick={() => setSearch(val)}
                  className='dashboard-btn'
                />
              </div>
              <span className='mr-2 ml-auto' style={{ color: '#828282' }}>Filter by</span>
              <Select
                className='mr-2'
                showSearch
                style={{ width: 120 }}
                placeholder='Print Status'
                optionFilterProp='children'
                onSelect={onFilterSelect}
                onClear={onClearFilter}
                allowClear
              >
                <Select.Option value='PRINTED'>
                  Printed
                </Select.Option>
                <Select.Option value='NOT_PRINTED'>
                  Not Printed
                </Select.Option>
                <Select.Option value='IN_QUEUE'>
                  In Queue
                </Select.Option>
                <Select.Option value='DELIVERING'>
                  Delivering
                </Select.Option>
                <Select.Option value='RECEIVED'>
                  Received
                </Select.Option>
              </Select>
              <DatePicker.RangePicker
                onChange={onHandleDate}
                style={{ width: 150, borderRadius: 7 }}
              />
            </div>
          </Card>
          <Gap height='1rem' />
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={dataPrint ?? []}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
        </Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({
  reducerPrintRequest
}) => {
  return {
    ...reducerPrintRequest
  }
}

export const PrintByOpim = connect(mapStateToProps)(_PrintByOpim)

_PrintByOpim.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  dataPrint: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  meta: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
