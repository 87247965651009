/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { ArrowLeftOutlined, WhatsAppOutlined, ReadOutlined, LogoutOutlined, NotificationOutlined } from '@ant-design/icons'
// import { Header } from 'src/components/molecules/Header'
import { Sidebar } from 'src/components/molecules/Sider'
import { Footer } from 'antd/lib/layout/layout'
import { Gap } from '../components/atoms/Gap'
import { connect } from 'react-redux'
import { AButton } from 'src/components/atoms/Button'
import { useNavigate } from 'react-router-dom'

const { Content } = Layout

export const _DefaultLayout = ({ children, dispatch, credit, profile }) => {
  const role = localStorage.getItem('role')
  const token = localStorage.getItem('token')

  const onHandleLogout = () => {
    localStorage.clear()
    localStorage.removeItem('applicationState')
    window.location.replace('/login')
  }

  const navigate = useNavigate()

  const redirectToGuides = () => {
    const path = '/dashboard/guides'
    navigate(path)
  }

  const redirectToAffiliate = () => {
    const path = '/dashboard/my-profile?menu=affiliate'
    navigate(path)
  }

  const handleBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    dispatch({
      type: 'profile/get_profile',
      payload: { token }
    })
  }, [])

  return (
    <Layout className='container-layout' style={{ overflowX: 'hidden' }}>
      <Sidebar role={role} credit={credit} />
      <Layout className='site-layout'>
        {/* <Header icon={<ExportOutlined />} onClick={onHandleLogout} /> */}
        <div style={{ background: '#fff', padding: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a style={{ padding: '5px', margin: '0 5px' }} onClick={handleBack}>
              <ArrowLeftOutlined />
            </a>
            {/* <h5 style={{ marginBottom: '0px' }}>Dashboard</h5> */}
          </div>
          <div>
            {/* <Button type='primary' icon={<ReadOutlined />}>
              Guides
            </Button> */}
            {/* <Button type='primary' icon={<ReadOutlined />} style={{ marginLeft: '10px' }}>
              Guides
            </Button> */}
            <AButton
              icon={<NotificationOutlined />}
              title='Affiliate'
              type='primary'
              className='dashboard-btn'
              onClick={redirectToAffiliate}
            />
            <AButton
              icon={<ReadOutlined />}
              title='Tutorial'
              type='primary'
              className='dashboard-btn ml-devide'
              onClick={redirectToGuides}
            />
            <AButton
              icon={<LogoutOutlined />}
              type='primary'
              className='dashboard-btn ml-devide'
              onClick={onHandleLogout}
            />
          </div>
        </div>
        <Content style={{ overflow: 'initial' }}>
          <div className='site-layout-background'>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', fontSize: 12 }}>
          OPIM - ORI PREMIUM INDONESIA MARKET © 2021 Created with <span style={{ color: 'red' }}>♥</span>. v1.3.7
        </Footer>
        <Gap height='2rem' />
      </Layout>
      <a href='https://api.whatsapp.com/send?phone=087705115217&text=selamat%20siang,%20mohon%20bantuannya%20dalam%20penggunaan%20dashboard%20opim' style={{ display: 'inline-block', position: 'fixed', bottom: '2rem', right: '2rem', zIndex: 100000 }} target='_blank' rel='noreferrer'>
        <WhatsAppOutlined style={{ color: 'white', fontSize: 24, background: 'green', borderRadius: '50%', padding: '1rem' }} className='shadow-lg' />
      </a>
    </Layout>
  )
}

const mapStateToProps = ({ reducerCredentials, reducerProfiles }) => {
  return { ...reducerCredentials, ...reducerProfiles }
}

export const DefaultLayout = connect(mapStateToProps)(_DefaultLayout)

_DefaultLayout.propTypes = {
  dispatch: PropTypes.func,
  credit: PropTypes.number,
  profile: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ])
}
