import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Layout, Table } from 'antd'

import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
// import { Header } from 'src/components/molecules/Header'
import moment from 'moment'

const _ActivityLog = ({ dispatch, token, loading, log, meta }) => {
  const columnsLog = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('DD/MM/YYYY hh:mm:ss')
    },
    {
      title: 'Description Activity',
      dataIndex: 'description'
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'log_activity/get_activity',
      payload: { token }
    })
  }, [])

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'log_activity/get_activity',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-products'>
        <Layout.Content className='container'>
          {/* <Header
            simple
            placeholder='search by type or name product'
            type='primary'
          /> */}
          <Gap height='1rem' />
          <Table
            loading={loading}
            pagination={log?.meta && { defaultPageSize: log?.meta.itemsPerPage, total: log?.meta.totalItems, pageSize: log?.meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            columns={columnsLog}
            dataSource={log?.items ?? []}
            rowKey='createdAt'
          />
        </Layout.Content>
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerLogActivity }) => {
  return { ...reducerLogActivity }
}

export const ActivityLog = connect(mapStateToProps)(_ActivityLog)

_ActivityLog.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  log: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  meta: PropTypes.object
}
