import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

export const AButton = ({
  icon,
  className,
  htmlType,
  loading,
  onClick,
  disabled,
  block,
  type,
  title
}) => {
  return (
    <Button
      key='button'
      type={type}
      disabled={disabled}
      block={block}
      icon={icon}
      loading={loading}
      htmlType={htmlType || 'submit'}
      onClick={onClick}
      className={className || ''}
    >
      {title}
    </Button>
  )
}

AButton.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  className: PropTypes.string,
  onClick: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  htmlType: PropTypes.string,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  type: PropTypes.string,
  disabled: PropTypes.bool
}
