/* eslint-disable no-unused-vars */
import React from 'react'
import { Image, Card, Descriptions, Layout, Space, Row, Col } from 'antd'
import { useLocation } from 'react-router-dom'
import { DefaultLayout } from 'src/layouts/default'

export const PreviewProduct = () => {
  const { state } = useLocation()
  return (
    <DefaultLayout>
      <Layout.Content className='container-detail-product'>
        <Card>
          <Descriptions title='Detail Product :'>
            <Descriptions.Item label='Name'>{state.name}</Descriptions.Item>
            <Descriptions.Item label='SKU'>{state.sku}</Descriptions.Item>
            <Descriptions.Item label='Category'>{state.category}</Descriptions.Item>
            <Descriptions.Item label='Production By'>{state.producedBy}</Descriptions.Item>
            <Descriptions.Item label='Production Code'>{state.productionCode}</Descriptions.Item>
            <Descriptions.Item label='Distrubted by'>{state.distributedBy}</Descriptions.Item>
            <Descriptions.Item label='Price'>{`Rp. ${state.price}`}</Descriptions.Item>
            <Descriptions.Item label='Production Date'>{state.productionDate}</Descriptions.Item>
            <Descriptions.Item label='Product Expired'>{state.expiredAt}</Descriptions.Item>
            <Descriptions.Item label='QR Status'>{state.qrCodeStatus}</Descriptions.Item>
          </Descriptions>
          <Row>
            <Col md={12}>
              <p>Certificates :</p>
              {
              state.certificates.map((item, index) =>
                <Image
                  key={index}
                  src={item.url}
                  style={{ marginRight: '1rem', maxHeight: 150 }}
                />
              )
            }
            </Col>
            <Col md={12}>
              <p>Images :</p>
              {
              state.images.map((item, index) =>
                <Image
                  key={index}
                  src={item.url}
                  style={{ marginRight: '1rem', maxHeight: 150 }}
                />
              )
            }
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p style={{
                fontWeight: 700
              }}
              >
                Attribute
              </p>
            </Col>
          </Row>
          <Descriptions>
            {state.attributes && state.attributes.map((val, index) =>
              <Descriptions.Item
                key={index} label={val.key} style={{
                  textTransform: 'capitalize'
                }}
              >{val.value}
              </Descriptions.Item>
            )}
          </Descriptions>
          <Col>
            <p>Licenses :</p>
            {
            state.licenses.map((item, index) =>
              <p key={index} style={{ marginLeft: '2rem' }}>
                {item.type.toUpperCase()}: {item.no}
              </p>
            )
          }
          </Col>
          <Col>
            <p>Short Description :</p>
            <p style={{ marginLeft: '2rem' }}>{state.shortDescription}</p>
          </Col>
          <Col>
            <p>Short Description :</p>
            <p style={{ marginLeft: '2rem' }}>{state.description}</p>
          </Col>
        </Card>
      </Layout.Content>
    </DefaultLayout>
  )
}
