/* eslint-disable no-undef */
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { actions } from 'src/redux/google-tag-manager/product/actions'
import { serviceProductGtm } from 'src/services/api/productGtm'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'gtm_product/set_state',
    payload
  })
}

export function * getProductGtm ({ payload }) {
  yield load(true)
  const { token, productId } = payload

  const response = yield call(serviceProductGtm.get, token, productId)
  if (response.status === 200) {
    yield setState({
      gtmProduct: response.data.data,
      meta: response.data.data.meta,
      loading: false
    })
  } yield load(false)
}

export function * getProductGtmQR ({ payload }) {
  yield load(true)
  const { qrcodeId } = payload

  const response = yield call(serviceProductGtm.getQR, qrcodeId)
  if (response.status === 200) {
    yield setState({
      gtmProduct: response.data.data,
      meta: response.data.data.meta,
      loading: false
    })
  } yield load(false)
}

export function * createProductGtm ({ payload }) {
  yield load(true)
  const { token, data, productId } = payload

  const response = yield call(serviceProductGtm.create, token, data, productId)
  if (response.status === 202) {
    yield setState({
      loading: false,
      gtmProduct: response.data.data,
      popupSuccess: true
    })
    message.success('Created success')
  } else {
    yield load(false)
    message.error('created failed! check your field')
  }
}

export function * deleteProductGtm ({ payload }) {
  yield load(true)
  const { token, productId } = payload

  const response = yield call(serviceProductGtm.delete, token, productId)
  if (response.status === 202) {
    yield put({
      type: 'gtm_product/get',
      payload: { token }
    })
    notification.warning({
      message: 'Delete Success!',
      description: 'Data delete for permanent '
    })
  } yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_gtm_product, getProductGtm),
    takeEvery(actions.get_gtm_product_qr, getProductGtmQR),
    takeEvery(actions.delete_gtm_product, deleteProductGtm),
    takeEvery(actions.create_gtm_product, createProductGtm)
  ])
}
