import helper from '../helper'

export const serviceScanQR = {
  // get: (qrid, uniqueId, latitude, longititude) =>
  //   uniqueId !== null
  //     ? helper.get('api/q/' + qrid + '?unique-code=' + uniqueId + '&latitude=' + latitude + '&longititude=' + longititude)
  //     : helper.get('api/q/' + qrid + '?latitude=' + latitude + '&longititude=' + longititude)
  get: (qrid, data) => helper.post('api/q/' + qrid, data),
  getProduct: (qrid, data) => helper.post('api/v2/q/' + qrid, data),
  checkId: (data) => helper.post('api/v1/qrcodes/check-uniqueid', data),
  getBySku: (sku) => helper.get('api/v1/qrcodes/sku/' + sku)
}
