import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceProductGtm = {
  get: (token, productId) => helper.get('api/v2/google-tag-manager/' + productId, token),
  getQR: (qrcodeId) => helper.get('api/v2/google-tag-manager/qrcode/' + qrcodeId),
  create: (token, data, productId) => helper.post('api/v2/google-tag-manager/' + productId, data, token),
  delete: (token, productId) =>
    axios.delete(apiBaseUrl + 'api/v2/google-tag-manager/' + productId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
