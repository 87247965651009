/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { message, notification } from 'antd'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { serviceCoupon } from 'src/services/api/coupons'

import { actions } from './actions'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'coupon/set_state',
    payload
  })
}

export function * getCoupons ({ payload }) {
  const { token, params } = payload
  const response = yield call(serviceCoupon.get, token, params)

  if (response.status === 200) {
    yield setState({
      // credit: response.data.data.account.credit,
      data: response.data.data.items,
      meta: response.data.data.meta,
      loading: false
    })
    // eslint-disable-next-line no-undef
    // localStorage.setItem('credit', response.data.data.account.credit)
  } else message.warning('get coupon failed')
}

export function * downloadCoupons ({ payload }) {
  const { couponId, token, params } = payload
  const response = yield call(serviceCoupon.download, couponId, token, params)

  if (response.status === 200) {
    yield setState({
      dataDownload: response.data,
      loading: false
    })
  } else message.warning('download failed')
}

export function * CreateCoupon ({ payload }) {
  yield load(true)
  const { token, data } = payload
  const response = yield call(serviceCoupon.create, token, { data })

  if (response.status === 201) {
    yield setState({
      loading: false,
      popupSuccess: true
    })
  } else {
    yield load(false)
    const description = response.data.error === 'Conflict'
      ? response.data.message
      : response.data.message.length === 1
        ? response.data.message
        : 'please check your fields'

    message.error(description)
  }
}

export function * checkCoupon ({ payload }) {
  yield load(true)
  const { data } = payload
  const response = yield call(serviceCoupon.check, { data })

  if (response.status === 200) {
    yield setState({
      loading: false,
      dataCoupon: response?.data.data,
      popupSuccess: true
    })
  } else {
    yield load(false)
    yield setState({
      dataCoupon: null
    })

    message.error(response?.data.message)
  }
}

export function * getOneCoupon ({ payload }) {
  yield load(true)
  const { token, couponId } = payload

  const response = yield call(serviceCoupon.one, token, couponId)
  if (response.status === 200) {
    yield setState({
      type: 'coupon/get_one',
      loading: false,
      data: response.data.data
    })
  }
}

export function * deleteCoupon ({ payload }) {
  yield load(true)
  const { token, couponId } = payload

  const response = yield call(serviceCoupon.delete, token, { couponId })
  if (response.status === 202) {
    yield put({
      type: 'coupon/get_coupon',
      payload: { token }
    })
    notification.success({
      message: 'Delete Coupon Success!',
      description: 'Coupon delete for permanent '
    })
  } yield load(false)
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_coupon, getCoupons),
    takeEvery(actions.create_coupon, CreateCoupon),
    takeEvery(actions.check_coupon, checkCoupon),
    takeEvery(actions.get_one, getOneCoupon),
    takeEvery(actions.delete_coupon, deleteCoupon),
    takeEvery(actions.download_coupon, downloadCoupons)
  ])
}
