export const actions = {
  set_state: 'product_master/set_state',
  get_product_master: 'product_master/get_product_master',
  delete_product_master: 'product_master/delete_product_master',
  create_product_master: 'product_master/create_product_master',
  upload_product_master: 'product_master/upload_product_master',
  get_activity: 'product_master/get_activity',
  update_product_master: 'product_master/update_product_master',
  get_one_product_master: 'product_master/get_one',
  upload_image_product_master: 'product_master/upload_image',
  remove_image_product_master: 'product_master/remove_image'
}
