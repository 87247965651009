import { all } from 'redux-saga/effects'
import sagaCredential from 'src/redux/credentials/sagas'
import sagaUsers from 'src/redux/users/sagas'
import sagaRoles from 'src/redux/roles/sagas'
import sagaProducts from 'src/redux/products/sagas'
import sagaGenerateQR from 'src/redux/generate-qr/sagas'
import sagaDistros from 'src/redux/distros/sagas'
import sagaSettingQR from 'src/redux/setting-qr/sagas'
import sagaSubAccount from 'src/redux/sub-account/sagas'
import sagaProductMaster from 'src/redux/products-master/sagas'
import sagaProfile from 'src/redux/profiles/sagas'
import sagaScanQR from 'src/redux/scan-qr/sagas'
import sagaTopUpAdmin from 'src/redux/topup-admin/sagas'
import sagaTopUp from 'src/redux/topup/sagas'
import sagaLogActivity from 'src/redux/log-activity/sagas'
import sagaTraffics from 'src/redux/traffics/sagas'
import sagaFacebookPixel from 'src/redux/facebook-pixel/sagas'
import sagaCoupon from 'src/redux/coupons/sagas'
import sagaCouponClaimers from 'src/redux/coupon-claimers/sagas'
import sagaAffiliates from 'src/redux/transaction-affiliates/sagas'
import sagaAdminAffiliates from 'src/redux/affiliate-admin/sagas'
import sagaGoogleTagManager from 'src/redux/google-tag-manager/sagas'
import sagaPrintRequest from 'src/redux/print-admin/sagas'
import sagaProductPixel from 'src/redux/facebook-pixel/product/sagas'
import sagaProductGtm from 'src/redux/google-tag-manager/product/sagas'

export default function * rootSaga () {
  yield all([
    sagaUsers(),
    sagaProfile(),
    sagaProductMaster(),
    sagaSettingQR(),
    sagaCredential(),
    sagaRoles(),
    sagaDistros(),
    sagaGenerateQR(),
    sagaProducts(),
    sagaSubAccount(),
    sagaScanQR(),
    sagaTopUpAdmin(),
    sagaTopUp(),
    sagaLogActivity(),
    sagaTraffics(),
    sagaFacebookPixel(),
    sagaCoupon(),
    sagaCouponClaimers(),
    sagaAffiliates(),
    sagaAdminAffiliates(),
    sagaGoogleTagManager(),
    sagaPrintRequest(),
    sagaProductPixel(),
    sagaProductGtm()
  ])
}
