import React from 'react'
import PropTypes from 'prop-types'
import { ClusterOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Result } from 'antd'

export const PopUpFinished = ({
  popupSuccess,
  onClickCheckList,
  buttonRight,
  onClickCreateNew
}) => {
  return (
    <Modal
      footer={false}
      visible={popupSuccess}
      centered
      maskClosable={false}
      closable={false}
      wrapClassName='create-products'
    >
      <Result
        status='success'
        title='Success Created'
        subTitle={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}
        extra={[
          <Button
            type='primary'
            key='craete'
            icon={<PlusCircleOutlined />}
            onClick={onClickCreateNew}
          >
            Create New
          </Button>,
          <Button
            key='buy'
            icon={<ClusterOutlined />}
            onClick={onClickCheckList}
          >
            {buttonRight || 'Check List Roles'}
          </Button>
        ]}
      />
    </Modal>
  )
}

PopUpFinished.propTypes = {
  onClickCheckList: PropTypes.func,
  onClickCreateNew: PropTypes.func,
  buttonRight: PropTypes.string,
  popupSuccess: PropTypes.bool
}
