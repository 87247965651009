import axios from 'axios'
import helper from '../helper'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const serviceRoles = {
  get: (token, params) => helper.get('api/v1/roles', token, params),
  create: (token, { data }) => helper.post('api/v1/roles', data, token),
  update: (token, { data, roleName }) => helper.patch('api/v1/roles/' + roleName, data, token),
  delete: (token, { roleName }) =>
    axios.delete(apiBaseUrl + `api/v1/roles/${roleName}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).catch(err => err.response)
}
