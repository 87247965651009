import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Layout, Popconfirm, Space, Table } from 'antd'
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import { Content } from 'antd/lib/layout/layout'
import { CreateAgent } from './CreateDistro'
import { connect } from 'react-redux'
import { UpdateDistro } from './UpdateDistro'

export const _Distro = ({ meta, reset, loading, data, token, dispatch }) => {
  const [value, setValue] = useState('')
  // const [search, setSearch] = useState('')
  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   perPage: 10
  // })
  const [visible, setVisible] = useState(false)
  const [updateDistro, setUpdateDistro] = useState({
    visible: false,
    items: null
  })
  const [form] = Form.useForm()
  const text = 'Are you sure to delete distro?'
  // const customVal = value?.length <= 0 ? '' : search

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Distro Code',
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: 'Distro Owner',
      dataIndex: 'owner'
    },
    {
      title: 'Address',
      dataIndex: 'address'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (txt, obj) => (
        <Space>
          <Button
            size='small'
            onClick={() => onHandleVisibleUpdate(obj)}
            className='button-update'
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement='topLeft'
            title={text}
            okText='Yes'
            onConfirm={() => onHandleDelete(obj)}
            cancelText='No'
          >
            <Button size='small' className='button-delete'>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'distros/get_distros',
      payload: {
        token
      }
    })
  }, [])

  const onHandleSearch = () => {
    dispatch({
      type: 'distros/search_distros',
      payload: { token, params: { name: value } }
    })
  }

  const onHandleCreateDistro = () => {
    form.validateFields().then(data =>
      dispatch({
        type: 'distros/create_distros',
        payload: {
          token,
          params: data
        }
      })
    )
  }

  const onHandleDelete = ({ id }) => {
    dispatch({
      type: 'distros/delete_distros',
      payload: {
        token,
        distroID: id
      }
    })
  }

  const onHandleVisibleUpdate = (items) => {
    setUpdateDistro({
      visible: true,
      items
    })
  }

  const onHandlePaginate = (page, pageSize) => {
    dispatch({
      type: 'distros/get_distros',
      payload: { token, params: { page: page } }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          <Header
            simple
            type='primary'
            placeholder='search by name or owner'
            onClickSearch={onHandleSearch}
            title='Create Distro'
            icon={<PlusCircleOutlined />}
            onClick={() => setVisible(!visible)}
            onChange={({ target }) => setValue(target.value)}
          />
          <Gap height='1rem' />
          <Table
            rowKey='id'
            loading={loading}
            columns={columns}
            dataSource={data ?? []}
            pagination={meta && { defaultPageSize: meta.itemsPerPage, total: meta.totalItems, pageSize: meta.itemsPerPage, onChange: (page, pageSize) => onHandlePaginate(page, pageSize) }}
            rowSelection={{
              type: 'checkbox'
            }}
          />
          <Gap height='2rem' />
        </Content>
      </Layout>
      <CreateAgent
        visible={visible}
        form={loading ? form.resetFields() : form}
        loading={loading}
        onFinsih={onHandleCreateDistro}
        onCancel={() => setVisible(!visible)}
      />
      <UpdateDistro
        visible={updateDistro.visible}
        token={token}
        onCancel={() => setUpdateDistro({ ...updateDistro, visible: false })}
        itemsDistro={updateDistro.items}
      />
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerDistros }) => {
  return { ...reducerDistros }
}

export const Distro = connect(mapStateToProps)(_Distro)

_Distro.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  meta: PropTypes.object,
  reset: PropTypes.bool
}
