import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Select, Button, Row } from 'antd'
import { DefaultLayout } from 'src/layouts/default'
import {
  KeyOutlined,
  UserOutlined,
  MailOutlined,
  EditOutlined
} from '@ant-design/icons'

import { rules } from 'src/utils/rules'
import { Gap } from 'src/components/atoms/Gap'
import { FormItem } from 'src/components/molecules/FormItem'
import { Forms } from 'src/components/organizes/Forms'
import { useLocation } from 'react-router'
import { connect } from 'react-redux'

export const _UpdateUser = ({ items, loading, dispatch, token }) => {
  const { state } = useLocation()
  const [forms, setForms] = useState({})

  useEffect(() => {
    dispatch({
      type: 'roles/get_roles',
      payload: { token }
    })
  }, [])

  useEffect(() => {
    setForms({ ...state })
  }, [])

  const onHandleUpdate = () => {
    dispatch({
      type: 'users/update_users',
      payload: {
        token,
        userId: state.id,
        data: forms
      }
    })
  }

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Forms titleBar='Update User' onFinish={onHandleUpdate}>
        <Row gutter={16}>
          <FormItem
            label='Username'
            placeholder='your username'
            prefix={<UserOutlined />}
            rules={[rules.required]}
            value={forms?.username}
            onChange={({ target }) =>
              setForms({ ...forms, username: target.value })}
            col={12}
          />
          <FormItem
            rules={[rules.required]}
            value={forms?.fullName}
            prefix={<UserOutlined />}
            col={12}
            onChange={({ target }) =>
              setForms({ ...forms, fullName: target.value })}
            label='Full Name'
            placeholder='your fullname'
          />
        </Row>
        <Row gutter={16}>
          <FormItem
            rules={[rules.required]}
            prefix={<MailOutlined />}
            value={forms?.email}
            onChange={({ target }) =>
              setForms({ ...forms, email: target.value })}
            col={12}
            label='Email'
            placeholder='your email'
          />
          <FormItem
            label='Choose Role'
            placeholder='Silver'
            col={12}
            select
            value={forms?.role}
            onChange={(value) => setForms({ ...forms, role: value })}
            rules={[rules.required]}
          >
            {items?.map((item, index) => (
              <Select.Option key={index} value={item?.name}>
                {item?.name}
              </Select.Option>
            ))}
          </FormItem>
        </Row>
        <Row gutter={16}>
          <FormItem
            password
            name='password'
            label='Password'
            extra='required password must be string, alphabet'
            placeholder='your password'
            prefix={<KeyOutlined />}
            col={12}
            onChange={({ target }) =>
              setForms({ ...forms, password: target.value })}
            hasFeedback
          />
          <FormItem
            password
            hasFeedback
            col={12}
            prefix={<KeyOutlined />}
            name='confirmPassword'
            label='Confirm Password'
            dependencies={['password']}
            placeholder='your password'
            onChange={({ target }) =>
              setForms({ ...forms, confirmPassword: target.value })}
            extra='required password must be string, alphabet'
            rules={[
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  )
                }
              })
            ]}
          />
        </Row>
        <Row justify='end'>
          <Button
            icon={<EditOutlined />}
            htmlType='submit'
            type='primary'
            loading={loading}
          >
            Update User
          </Button>
        </Row>
      </Forms>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerRoles }) => {
  return { ...reducerRoles }
}

export const UpdateUser = connect(mapStateToProps)(_UpdateUser)

_UpdateUser.propTypes = {
  items: PropTypes.array,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool
}
