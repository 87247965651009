import React from 'react'
import { connect } from 'react-redux'

const Contact = () => {
  return (
    <>
      <section id='contact' className='contact-us ptb-10 bg-white py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='section-heading'>
                <h3>Contact</h3>
                {/* <p>
                  lorem
                </p> */}
              </div>
              <div className='footer-address'>
                <h6>
                  <strong>ORI PREMIUM INDONESI MARKET</strong>
                </h6>
                <p>Sukorame, Mangunan, Dlingo, Bantul Regency,<br /> Special Region of Yogyakarta 55783</p>
                <ul>
                  <li>
                    <span>Phone: 08112345678</span>
                  </li>
                  <li>
                    <span>
                      Email :{' '} legalitas.opim@gmail.com
                      <a href='mailto:hello@yourdomain.com' />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-7'>
              <form
                method='POST'
                id='contactForm1'
                className='contact-us-form'
              >
                {/* <h5>Reach us quickly</h5> */}
                <div className='row'>
                  <div className='col-sm-6 col-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        name='name'
                        placeholder='Enter name'
                        required='required'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 col-12'>
                    <div className='form-group'>
                      <input
                        type='email'
                        className='form-control'
                        name='email'
                        placeholder='Enter email'
                        required='required'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6 col-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        name='phone'
                        className='form-control'
                        id='phone'
                        placeholder='Your Phone'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 col-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        name='company'
                        size='40'
                        className='form-control'
                        id='company'
                        placeholder='Your Company'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='form-group'>
                      <textarea
                        name='message'
                        id='message'
                        className='form-control'
                        rows='7'
                        cols='25'
                        placeholder='Message'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 mt-3'>
                    <button
                      type='submit'
                      className='btn solid-btn'
                      id='btnContactUs'
                    >
                      Send
                    </button>
                  </div>
                </div>
              </form>
              <p className='form-message' />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default connect(state => ({
  state
}))(Contact)
