import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  ShoppingCartOutlined,
  FileProtectOutlined,
  CameraOutlined,
  LinkOutlined,
  PlusOutlined,
  PhoneOutlined,
  InboxOutlined,
  MailOutlined,
  UserOutlined
} from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { Form, Button, Col, Row, Upload, Space, Input, message, Result, Card, Select, Divider } from 'antd'

import { DefaultLayout } from 'src/layouts/default'
import { Forms } from 'src/components/organizes/Forms'
import { FormItem } from 'src/components/molecules/FormItem'
import { Gap } from 'src/components/atoms/Gap'
import { AButton } from 'src/components/atoms/Button'
import { rules } from 'src/utils/rules'
import Modal from 'antd/lib/modal/Modal'
import { useForm } from 'antd/lib/form/Form'
import { connect } from 'react-redux'

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const _UpdateProduct = ({ loading, token, popupSuccess, dispatch, data, dataUploadSuccess }) => {
  const [form] = useForm()
  const [forms, setForms] = useState({})
  const { state } = useLocation()
  const [fileList, setFileList] = useState()
  const [visible, setVisible] = useState(false)
  const [formSosmed] = Form.useForm()
  const [sosmed, setSosmed] = useState([])
  const [showSuccess, setShowSuccess] = useState(false)
  const category = useLocation().state
  const [other, setOther] = useState('')
  const [sosmedOption, setSosmedOption] = useState(['Facebook', 'Twitter', 'Tiktok', 'Instagram', 'Youtube'])
  const inputRef = useRef(null)
  let index = 0

  const onChangeOther = (event) => {
    setOther(event.target.value)
  }

  const addItem = (e) => {
    e.preventDefault()
    setSosmedOption([...sosmedOption, other || `New item ${index++}`])

    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  useEffect(() => {
    dispatch({
      type: 'product_master/get_one',
      payload: {
        token,
        productId: state.id
      }
    })
  }, [])

  useEffect(() => {
    data && setForms(data)
    data && setShowSuccess(popupSuccess)
    data && setFileList(data.images)
    const attributeModels = data?.attributes && data?.attributes.filter(f => f.key === 'model')
    const attributeSizes = data?.attributes && data?.attributes.filter(f => f.key === 'size')
    const attributeColors = data?.attributes && data?.attributes.filter(f => f.key === 'color')
    const attributeMaterials = data?.attributes && data?.attributes.filter(f => f.key === 'material')
    const code = state.sku.split('-')
    const newData = {
      ...forms,
      code1: code[0],
      code2: code[1],
      description: state.description !== 'undefined' ? state.description : '',
      models: attributeModels?.length > 0 ? attributeModels[0].value : '',
      sizes: attributeSizes?.length > 0 ? attributeSizes[0].value : '',
      colors: attributeColors?.length > 0 ? attributeColors[0].value : '',
      materials: attributeMaterials?.length > 0 ? attributeMaterials[0].value : ''
    }

    if (forms && forms.socials?.length > 0) {
      forms.socials.forEach(item => {
        if (!sosmedOption.includes(item.name)) {
          setSosmedOption([...sosmedOption, item.name])
        }
      })
    }

    form.setFieldsValue(newData)
  }, [forms, data])

  useEffect(() => {
    dataUploadSuccess && setFileList(dataUploadSuccess.images)
  }, [dataUploadSuccess])

  const onHandleSubmitSosmed = async () => {
    await formSosmed.validateFields()
      .then(data =>
        setSosmed(data)
      )
    setVisible(!visible)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

    !isJpgOrPng && message.error('You can only upload JPG/PNG file!')

    const isLt2M = file.size / 1024 / 1024 < 2

    !isLt2M && message.error('Image must smaller than 2MB!')

    // return isJpgOrPng && isLt2M
  }

  const licensesToLower = (data) => {
    return data.licenses.map((item, index) => {
      item.type = item.type.toLowerCase()
      return item
    })
  }

  const onHandleUpdateProduct = async () => {
    form.validateFields().then(data => {
      const attributes = []

      if (category === 'fashion') {
        attributes.push({
          key: 'model',
          value: data.models ? attributesToCapitalize(data.models.split(/, |,/)) : '-'
        })

        attributes.push({
          key: 'size',
          value: data.sizes ? attributesToCapitalize(data.sizes.split(/, |,/)) : '-'
        })

        attributes.push({
          key: 'material',
          value: data.materials ? attributesToCapitalize(data.materials.split(/, |,/)) : '-'
        })

        attributes.push({
          key: 'color',
          value: data.colors ? attributesToCapitalize(data.colors.split(/, |,/)) : '-'
        })
      }

      const sku = data.code1 + '-' + data.code2
      const licenses = data.licenses && licensesToLower(data)

      delete data.code1
      delete data.code2
      delete data.licenses

      const limitationData = {
        ...data,
        ...sosmed,
        sku: sku,
        licenses: licenses,
        category: state?.category
      }

      if (attributes.length > 0) {
        dispatch({
          type: 'product_master/update_product_master',
          payload: {
            token,
            productId: state.id,
            data: {
              ...limitationData,
              attributes
            }
          }
        })
      } else {
        dispatch({
          type: 'product_master/update_product_master',
          payload: {
            token,
            productId: state.id,
            data: limitationData
          }
        })
      }
    })
  }

  const onHandleUpdate = ({ fileList }) => {
    // setFileList(fileList)
  }

  const onHandleAction = (fileList) => {
    message.warning('Image will be upload please wait!')
    const formData = new FormData()
    formData.append('file', fileList, fileList.name)
    formData.append('type', 'IMAGE')
    dispatch({
      type: 'product_master/upload_image',
      payload: {
        token,
        productId: state.id,
        params: {
          formData
        }
      }
    })
  }

  const onHandleRemove = (file) => {
    if (fileList.length > 1) {
      dispatch({
        type: 'product_master/remove_image',
        payload: {
          token,
          productId: state.id,
          params: {
            id: file.id
          }
        }
      })
      const newList = fileList.filter((item) => item.id !== file.id)
      setFileList(newList)
    } else {
      message.error('delete image failed, must be at least 2 images to be able to delete one image!')
    }
  }

  const attributesToCapitalize = (data) => {
    return data.map((item) => {
      const firstLetter = item.charAt(0)
      return firstLetter.toUpperCase() + item.slice(1)
    })
  }

  return (
    <DefaultLayout>
      <Forms titleBar='Update Product' onFinish={onHandleUpdateProduct} form={form} initialValues={forms}>
        <Card>
          <Row gutter={16} className='align-items-center'>
            <FormItem
              prefix={<UserOutlined />}
              col={12}
              label='Product Name'
              name='name'
              placeholder='type your product name'
            />
            <FormItem
              prefix={<UserOutlined />}
              col={4}
              label='SKU'
              name='code1'
              placeholder='type your SKU'
              value='test'
            />
            <FormItem
              prefix={<UserOutlined style={{ display: 'none' }} />}
              col={4}
              label=' '
              disabled
            />
            <FormItem
              prefix={<UserOutlined />}
              col={4}
              label=' '
              name='code2'
              placeholder='type your SKU'
            />
          </Row>
          <Row gutter={16}>
            <FormItem
              prefix={<FileProtectOutlined />}
              col={24}
              label='Distributed by'
              name='distributedBy'
              placeholder='type your distrubted by'
            />
          </Row>
          <Row gutter={16}>
            <FormItem
              prefix={<FileProtectOutlined />}
              col={12}
              label='Production by'
              name='producedBy'
              placeholder='type your production by'
            />
            <FormItem
              prefix={<ShoppingCartOutlined />}
              col={12}
              label='Price'
              name='price'
              placeholder='type your price'
            />
          </Row>
          <Row gutter={16} align='middle'>
            <FormItem
              prefix={<PhoneOutlined />}
              label='Number Phone'
              name='phone'
              placeholder='type your number'
            />
          </Row>
          <Row gutter={16}>
            <FormItem
              prefix={<InboxOutlined />}
              col={12}
              textarea
              label='Short Description'
              name='shortDescription'
              placeholder='type your short description'
            />
            <FormItem
              prefix={<MailOutlined />}
              col={12}
              textarea
              label='Full Description'
              name='description'
              placeholder='type your full description'
            />
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.List name='licenses'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                        <Form.Item
                          {...restField}
                          rules={[{ required: true, message: 'Missing type' }]}
                          name={[name, 'type']}
                        >
                          {
                          name < forms?.licenses.length
                            ? (
                              <Input
                                value={forms?.licenses[key].type}
                                prefix={<LinkOutlined />}
                                placeholder='type legalitas'
                              />
                              )
                            : (
                              <Input
                                prefix={<LinkOutlined />}
                                placeholder='type legalitas'
                              />
                              )
                              }
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          rules={[{ required: true, message: 'Missing type' }]}
                          name={[name, 'no']}
                        >
                          {
                          name < forms?.licenses.length
                            ? (
                              <Input
                                value={forms?.licenses[key].no}
                                prefix={<LinkOutlined />}
                                placeholder='type legalitas'
                              />
                              )
                            : (
                              <Input
                                prefix={<LinkOutlined />}
                                placeholder='type legalitas'
                              />
                              )
                              }
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Col justify='end'>
                      <Button block type='primary' onClick={() => add()} icon={<PlusOutlined />}>
                        Add Legalitas
                      </Button>
                    </Col>
                  </>
                )}
              </Form.List>
            </Col>
            <Col md={12}>
              <Button
                block
                type='primary'
                onClick={() => setVisible(!visible)}
                icon={<PlusOutlined />}
              >
                Add Social Media
              </Button>
            </Col>
          </Row>
          <Gap height='1.6rem' />

          {state?.category === 'fashion' &&
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                name='models'
                label='Models (Separate with commas)'
              />
              <FormItem
                col={12}
                name='sizes'
                label='Sizes (Separate with commas)'
              />
            </Row>}
          {state?.category === 'fashion' &&
            <Row gutter={16} className='align-items-center'>
              <FormItem
                col={12}
                name='materials'
                label='Materials (Separate with commas)'
              />
              <FormItem
                col={12}
                name='colors'
                label='Colors (Separate with commas)'
              />
            </Row>}
          <Row>
            <Form.Item name='images' label='Upload Images Product'>
              <Upload
                customRequest={dummyRequest}
                listType='picture-card'
                fileList={fileList}
                beforeUpload={beforeUpload}
                action={onHandleAction}
                onRemove={onHandleRemove}
                onChange={onHandleUpdate}
              >
                <CameraOutlined />
              </Upload>
            </Form.Item>
          </Row>
          <Row justify='end'>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              icon={<PlusCircleOutlined />}
            >
              Submit
            </Button>
          </Row>
        </Card>
      </Forms>
      <Modal
        onCancel={() => setVisible(!visible)}
        title='add social media'
        visible={visible}
        footer={false}
      >
        <Form
          layout='vertical'
          onFinish={onHandleSubmitSosmed}
          form={formSosmed}
        >
          <Form.List name='socials' rules={[rules.required]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                    <Form.Item
                      {...restField}
                      label={name < 1 && 'Name Social Media'}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}
                    >
                      {/* {
                        name < forms?.socials?.length
                          ? <Input prefix={<LinkOutlined />} placeholder='social media name' />
                          : <Input prefix={<LinkOutlined />} placeholder='social media name' />
                      } */}
                      {
                        name < forms?.socials?.length
                          ? <Select
                              style={{
                                width: 250
                              }}
                              placeholder='social media'
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: '8px 0'
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: '0 8px 4px'
                                    }}
                                  >
                                    <Input
                                      placeholder='Other'
                                      ref={inputRef}
                                      value={other}
                                      onChange={onChangeOther}
                                    />
                                    <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
                                      Add
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={sosmedOption.map((item) => ({
                                label: item,
                                value: item
                              }))}
                            />
                          : <Select
                              style={{
                                width: 250
                              }}
                              placeholder='social media'
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: '8px 0'
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: '0 8px 4px'
                                    }}
                                  >
                                    <Input
                                      placeholder='Other'
                                      ref={inputRef}
                                      value={other}
                                      onChange={onChangeOther}
                                    />
                                    <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
                                      Add
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={sosmedOption.map((item) => ({
                                label: item,
                                value: item
                              }))}
                            />
                      }
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={name < 1 && 'URL'}
                      name={[name, 'url']}
                      rules={[{ required: true, message: 'Missing url' }]}
                    >
                      {
                        name < forms?.socials?.length
                          ? <Input prefix={<LinkOutlined />} placeholder='url or link social media' />
                          : <Input prefix={<LinkOutlined />} placeholder='url or link social media' />
                      }

                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item style={{ padding: '0 .6rem' }}>
                  <Button
                    block
                    type='dashed'
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row justify='end'>
            <AButton type='primary' htmlType='submit' title='Create' />
          </Row>
        </Form>
      </Modal>

      {/* popup success update */}
      <Modal
        footer={false}
        onCancel={() => setShowSuccess(!showSuccess)}
        visible={showSuccess}
        centered
        wrapClassName='create-products'
      >
        <Result
          status='success'
          title='Update Product Successed'
          subTitle='The product update has been successful, you can edit it any time'
        />
      </Modal>
    </DefaultLayout>
  )
}
const mapStateToProps = ({ reducerProductMaster }) => {
  return { ...reducerProductMaster }
}

export const UpdateProduct = connect(mapStateToProps)(_UpdateProduct)

_UpdateProduct.propTypes = {
  loading: PropTypes.bool,
  popupSuccess: PropTypes.bool,
  token: PropTypes.string,
  dispatch: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  dataUploadSuccess: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
