import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout, Empty } from 'antd'
import { DefaultLayout } from 'src/layouts/default'
import { Gap } from 'src/components/atoms/Gap'
import { Content } from 'antd/lib/layout/layout'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './style/index.css'

export const _Notification = ({ profile, loading, dispatch, token }) => {
  useEffect(() => {
    dispatch({
      type: 'profile/get_profile',
      payload: { token }
    })
  }, [])

  return (
    <DefaultLayout>
      <Gap height='1rem' />
      <Layout className='container-dashboard'>
        <Content className='container'>
          {profile?.account?.credit === 0 &&
            <div className='notif'>
              Halo Fulan, Kredit kamu 0. Segera <Link to='/dashboard/topup'>Top UP</Link> kredit kamu agar bisa mulai megenerate QRCode Produkmu. Jika anda sudah membayar top up anda tapi kredit tidak bertambah, segera konfirmasi <a target='_blank' href={`https://api.whatsapp.com/send?phone=6287705115217&text=*Konfirmasi%20Transfer*%0a%0aNama%20:%20${profile.fullName}%0aUsername%20:%20${profile.username}%0aEmail%20:%20${profile.email}%0aNominal%20:%20%0aTanggal%20:%20%0aAtas%20Nama%20:%0aBukti%20Transfer%20:%20`} rel='noreferrer'>admin</a> yak
            </div>}
        </Content>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Layout>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerProfiles }) => {
  return { ...reducerProfiles }
}

export const Notification = connect(mapStateToProps)(_Notification)

_Notification.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  profile: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array])
}
